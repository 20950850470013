import { getTextAndTagsFromEditor } from 'utils/tags'
import { Comment, InReplyTo } from 'Post/Comment/types'
import { getCurrentUser } from 'User/currentUser'
import dayjs from 'dayjs'
import api from 'api'
import { Post } from 'Post/types'
import { UploadingImage } from 'utils/imageUploadState'

type Props = {
    comment: Comment
    post: Post
    editorRef: { current: HTMLDivElement | null }
    postOwnerId: number
    values: {
        image?: UploadingImage
        parentCommentId?: number
        inReplyTo?: InReplyTo
        loopingAudio: string | undefined
    }
}

export default async function submit({
    comment,
    post,
    editorRef,
    postOwnerId,
    values: { image, parentCommentId, inReplyTo, loopingAudio },
}: Props): Promise<Comment> {
    const { text, containsRepliedUserName } = getTextAndTagsFromEditor({
        editor: editorRef.current as HTMLDivElement,
    })

    const newComment: Comment = {
        id: comment.id,
        postId: post.id,
        isMine: true,
        isUploading: true,
        user: getCurrentUser(),
        date: dayjs(),
        text,
        liked: comment.liked,
        likesCount: comment.likesCount,
        isLike: false,
        image: comment.image || image?.url,
        parentCommentId,
        inReplyTo: !containsRepliedUserName ? undefined : inReplyTo,
        loopingAudio,
    }

    if (image?.isNew) {
        const urls = await api.upload.getUploadingUrls({ photoCount: 1 })
        const upload = urls.photos[0]

        await fetch(upload.url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            body: image.file,
        }).then(() => (comment.image = upload.cdnUrl))
    }

    await api.comment.update({
        comment: newComment,
        postOwnerId,
    })

    return newComment
}
