import React from 'react'

export default function PrivacyPolicyPage() {
    return (
        <iframe
            src="https://www.schoool.me/privacy.html"
            className="w-full"
            style={{ height: '4900px' }}
        />
    )
}
