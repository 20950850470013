import React from 'react'
import { StudyFlow, StudyFlowType } from 'Studyflow/types'

type Props = {
    items: StudyFlow[]
    editForm(id: number, type: StudyFlowType): void
}

export default function List({ editForm, items }: Props) {
    return (
        <div className="flex-1 overflow-scroll overscroll-contain">
            {items.map((item) => (
                <div
                    key={item.id}
                    onClick={() => editForm(item.id, item.type)}
                    className="p-3 border-b border-gray-c5 cursor-pointer hover:bg-gray-f5"
                >
                    <span
                        className="mr-2 relative text-17"
                        style={{ top: '-1px' }}
                    >
                        •
                    </span>
                    {item.title}
                </div>
            ))}
        </div>
    )
}
