import React from 'react'
import Modal from 'Shared/Modal'
import { ArrowLeft } from '@styled-icons/fa-solid/ArrowLeft'
import { Post } from 'Post/types'
import { assignmentCopied } from 'Post/api'
import { queryClient } from 'utils/queryClient'

type Props = {
    post: Post
    onClose: () => void
}

export default function Check({ post, onClose }: Props) {
    const onCopyClick = React.useCallback(async () => {
        await navigator.clipboard.writeText(post.assignmentText)
        try {
            await assignmentCopied({ shareId: post.id })
        } catch (err) {
            console.log(err)
        }
        await queryClient.invalidateQueries(`mission${post.id}`)
        onClose()
        alert('Texts are copied')
    }, [post.id, post.assignmentText])

    return (
        <Modal onClose={onClose} size="medium">
            <div className="flex flex-col h-full overflow-scroll">
                <div className="w-full my-6 flex flex-row items-center justify-center">
                    <button type="button" onClick={onClose} className="ml-8">
                        <ArrowLeft size={26} />
                    </button>
                    <span className="text-2xl text-black font-semibold w-full text-center">
                        Assignment Mission
                    </span>
                    <button type="button" className="mr-8 invisible">
                        <ArrowLeft size={26} />
                    </button>
                </div>
                <span className="mx-5 mb-6 whitespace-pre-wrap">
                    {post.assignmentText}
                </span>
                <div className="flex flex-col items-center mb-6">
                    <span
                        className="w-32 h-6 text-center bg-blue-primary rounded-full text-white font-semibold cursor-pointer"
                        onClick={onCopyClick}
                    >
                        Copy
                    </span>
                </div>
            </div>
        </Modal>
    )
}
