import React from 'react'
import { PostedCandidate } from 'Class/Management/types'
import cn from 'classnames'

type Props = {
    candidate: PostedCandidate
}

export default function PostedRow({
    candidate,
    className,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div
            className={cn(
                'h-10 flex-shrink-0 flex flex-row items-center px-5 text-base',
                className,
            )}
        >
            <span className="flex-1">{candidate.title}</span>
            <span>
                {candidate.postedAt.local().format('YYYY-MM-DD, hh:mm A')}
            </span>
            <button
                className={cn(
                    'h-6 border rounded-full px-2 flex items-center ml-3 bg-gray-fc border-gray-97 text-gray-6b text-base opacity-25 cursor-default',
                )}
            >
                Cancel
            </button>
        </div>
    )
}
