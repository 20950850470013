import React from 'react'
import check from 'assets/images/check.svg'
import { Post } from 'Post/types'
import { MissionUser } from 'Post/api'
import Check from 'Post/Modal/AssignmentMission/Check'

export type Props = {
    post: Post
    users: MissionUser[]
}

export default function AssignmentMission({ post, users }: Props) {
    const [showCheckAssignment, setCheckAssignment] = React.useState(false)

    const onCheckClick = () => {
        setCheckAssignment(true)
    }
    const onCheckCompleted = () => {
        setCheckAssignment(false)
    }

    return (
        <div className="flex flex-col" onClick={onCheckClick}>
            <div className="bg-gray-dc h-px" />
            <div className="flex flex-col py-6 px-8">
                <span className="font-semibold text-lg">
                    ASSIGNMENT MISSION
                </span>
                <span className="mt-1 font-normal text-base text-black">
                    This is a list of members who have checked their
                    assignments. Press the 'Check' button to confirm.
                </span>
                <div className="flex flex-row mt-4 space-x-2.5 w-full overflow-x-scroll flex-grow">
                    <img
                        className="w-10 h-10"
                        src={check}
                        alt="Check assignment"
                    />
                    {users.map((user) => (
                        <img
                            key={user.id}
                            className="w-10 h-10 rounded-3xl"
                            alt={`${user.name} Profile Image`}
                            src={user.profileImage}
                        />
                    ))}
                </div>
            </div>
            {showCheckAssignment && (
                <Check post={post} onClose={onCheckCompleted} />
            )}
        </div>
    )
}
