import React, { useState } from 'react'
import { useMutation } from 'react-query'
import ActionButton from './ActionButton'

import api from 'api'

type Props = {
    note: string
    setOpenMenu(arg: boolean): void
    classId: number
}

const SendToStudyNotes = ({ note, setOpenMenu, classId }: Props) => {
    const [success, setSuccess] = useState(true)

    const { mutate, isLoading } = useMutation(api.classes.postStudyNote, {
        onSettled(flow: any, error) {
            if (error) {
                // setError(error as Error)
                setSuccess(false)
            } else if (flow) {
                setSuccess(true)
                setOpenMenu(false)

                // setUpdateStudyFlow(flow)
                // updateCache(flow)
                // filterCache(({ id }) => id !== updatedStudyFlow.id)
            }
        },
    })

    const sendNotes = () => {
        mutate({
            classId,
            note,
        })
    }

    return (
        <ActionButton onClick={sendNotes} loading={isLoading}>
            Send to Study Notes
        </ActionButton>
    )
}

export default SendToStudyNotes
