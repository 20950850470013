import React, { useEffect } from 'react'
import { Plus } from '@styled-icons/fa-solid/Plus'
import { Edit } from '@styled-icons/boxicons-regular/Edit'
import { ArrowLeft } from '@styled-icons/fa-solid/ArrowLeft'
import { TrashAlt } from '@styled-icons/fa-regular/TrashAlt'
import useToggle from 'utils/useToggle'
import CircleCheckbox from 'Shared/CircleCheckbox'
import cn from 'classnames'
import Placeholder from 'Notebook/Placeholder'
import { observer } from 'mobx-react-lite'
import SentencesDelete from 'Notebook/Delete'
import SentenceItem from 'Notebook/Item'
import AddSentence from 'Notebook/AddSentence'
import Spin from 'assets/images/icons/Spin'
import useRecords from 'utils/useRecords'
import api from 'api'
import { useQueries, useQuery } from 'react-query'
import AudioPlayer from './AudioPlayer'
import { Play } from '@styled-icons/boxicons-regular/Play'
import QRCode from 'assets/images/ar_qrcode.png'

export default observer(function Notebook() {
    const ref = React.useRef<HTMLDivElement>(null)
    const { data, isFetching } = useRecords({
        key: ['notebookSentences'],
        load: api.notebook.list,
        loadOnScroll: {
            ref,
            threshold: 300,
        },
    })
    const [selecting, toggleSelecting] = useToggle()
    const [allChecked, setCheckAll] = React.useState(false)
    const [checkedIds, setCheckedIds] = React.useState<Record<number, boolean>>(
        {},
    )
    const [deleteModal, toggleDeleteModal] = useToggle()
    const [addModal, toggleAddModal] = useToggle()

    const [audioSrcs, setAudioSrcs] = React.useState<string[]>([])
    const [audioTexts, setAudioTexts] = React.useState<string[]>([])
    const [isPlaying, setPlaying] = React.useState(false)
    const [fetched, setIsFetched] = React.useState(false)

    const toggleItem = (id: number) => {
        if (checkedIds[id]) {
            const checked = { ...checkedIds }
            delete checked[id]
            setCheckedIds(checked)
        } else {
            setCheckedIds({ ...checkedIds, [id]: true })
        }
    }

    const toggleCheckAll = () => {
        if (allChecked) setCheckedIds({})
        else {
            const all: Record<number, boolean> = {}
            data?.pages.forEach((page) =>
                page.forEach(({ id }) => (all[id] = true)),
            )
            setCheckedIds(all)
        }
        setCheckAll(!allChecked)
    }

    const selectedIds = Object.keys(checkedIds)

    const { data: countsAndIsPremium } = useQuery(
        ['countsAndIsPremium'],
        api.app.getCountsAndIsPremium,
    )
    const results = useQueries(
        audioTexts.map((text) => {
            return {
                queryKey: ['audio', text],
                queryFn: () => api.app.speech({ text }),
            }
        }),
    )

    useEffect(() => {
        if (results.length === audioTexts.length) {
            const srcs = results.map((item) => item.data)
            if (srcs.length > 0) {
                const check = srcs.some((item) => item === undefined)
                setIsFetched(!check)
                setPlaying(true)
                if (!check) setAudioSrcs(srcs as string[])
            }
        }
    }, [results])

    const total = countsAndIsPremium?.notebookCount || 0

    const startAudio = () => {
        if (data && data.pages.length !== 0) {
            data.pages.forEach((page) => {
                setAudioTexts(
                    page.map((sentence: { text: string }) => sentence.text),
                )
            })
        }
    }

    return (
        <>
            {deleteModal && (
                <SentencesDelete
                    checkedIds={checkedIds}
                    onClose={toggleDeleteModal}
                />
            )}
            {addModal && (
                <AddSentence
                    onClose={toggleAddModal}
                    title="Add Expressions"
                    buttonText="Add"
                    contentClass="pt-4 px-5 pb-6"
                    buttonWrapClass="flex-center mt-5"
                />
            )}
            <div
                className={cn(
                    'flex flex-shrink-0 justify-between items-center',
                    selecting ? 'p-4' : 'p-3',
                )}
            >
                {!selecting && (
                    <>
                        <div className="flex flex-row items-center">
                            <img
                                src={QRCode}
                                alt="QRCode for AR Notebook"
                                className="w-34 mr-2"
                            />
                            <span className="text-blue-primary uppercase">
                                Total {total}
                            </span>
                        </div>
                        <div className="text-gray-45">
                            {!fetched && (
                                <button
                                    type="button"
                                    disabled={results.length !== 0}
                                    onClick={() => startAudio()}
                                >
                                    <Play
                                        className={
                                            results.length === 0
                                                ? 'text-gray-2a'
                                                : 'text-gray-ef'
                                        }
                                        size={28}
                                    />
                                </button>
                            )}

                            {fetched && (
                                <AudioPlayer
                                    isPlaying={isPlaying}
                                    setIsPlaying={setPlaying}
                                    tracks={audioSrcs}
                                    startAudio={startAudio}
                                />
                            )}

                            <button type="button" onClick={toggleAddModal}>
                                <Plus size={17} className="ml-2" />
                            </button>
                            {data && data.pages.length > 0 && (
                                <button type="button" onClick={toggleSelecting}>
                                    <Edit className="ml-3" size={20} />
                                </button>
                            )}
                        </div>
                    </>
                )}
                {selecting && (
                    <>
                        <div className="flex-center">
                            <button type="button" onClick={toggleSelecting}>
                                <ArrowLeft size={18} />
                            </button>
                            <button
                                className={cn(
                                    'ml-3 transition duration-200',
                                    selectedIds.length === 0 && 'opacity-25',
                                )}
                                type="button"
                                onClick={toggleDeleteModal}
                                disabled={selectedIds.length === 0}
                            >
                                <TrashAlt
                                    size={18}
                                    className="text-blue-primary"
                                />
                            </button>
                        </div>
                        <div className="flex-center">
                            <div className="text-17 uppercase">Select all</div>
                            <CircleCheckbox
                                className="ml-3"
                                checked={allChecked}
                                onChange={toggleCheckAll}
                            />
                        </div>
                    </>
                )}
            </div>
            <div className="flex-grow flex flex-1 min-h-0">
                {data && data.pages[0].length === 0 && <Placeholder />}

                {data && data.pages.length !== 0 && (
                    <div
                        className="border-t border-gray-c5 flex-1 overflow-scroll overscroll-contain"
                        ref={ref}
                    >
                        {data.pages.map((page, i) => (
                            <React.Fragment key={i}>
                                {page.map((item) => (
                                    <SentenceItem
                                        key={item.id}
                                        sentence={item}
                                        selecting={selecting}
                                        checkedIds={checkedIds}
                                        toggleItem={toggleItem}
                                    />
                                ))}
                            </React.Fragment>
                        ))}

                        {isFetching && (
                            <div className="flex-center my-5">
                                <Spin className="w-7 h-7 text-blue-primary animate-spin" />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    )
})
