import React from 'react'
import { Comment } from 'Post/Comment/types'
import ActionButton from './ActionButton'

type Props = {
    comment: Comment
    setOpenMenu(arg: boolean): void
    toggleEdit(arg: boolean): void
}

const Edit = ({ setOpenMenu, toggleEdit }: Props) => {
    const editComment = () => {
        toggleEdit(true) // Edit form
        setOpenMenu(false)
    }

    return (
        <ActionButton onClick={editComment} loading={false}>
            Edit
        </ActionButton>
    )
}

export default Edit
