import React, {useRef} from 'react'
import { observer } from 'mobx-react-lite'
import useToggle from 'utils/useToggle'
import PostForm from 'Post/Form'
import { useCurrentUser } from '../User/currentUser'
import Sidebar from 'Home/Sidebar'
import Posts from 'Post/List'

export default observer(function Home() {
    const [showPostForm, togglePostForm] = useToggle(false)
    const scrollRef = useRef<HTMLDivElement>(null)
    const [{ avatar }] = useCurrentUser()

    return (
        <>
            {showPostForm && <PostForm onClose={togglePostForm} />}
            <div className="h-full flex relative">
                <div className="pt-8 w-full h-full absolute right-0 top-0">
                    <div
                        ref={scrollRef}
                        style={{ maxWidth: '640px' }}
                        id="main_page"
                        className="w-full flex-grow overflow-scroll h-full"
                    >
                        <div
                            className="bg-white p-5 flex-center mb-5 shadow"
                            onClick={togglePostForm}
                        >
                            <img
                                src={avatar}
                                alt="avatar"
                                className="rounded-full"
                                style={{ width: '60px', height: '60px' }}
                            />
                            <input
                                type="text"
                                className="ml-3 w-full rounded-full border border-gray-bc bg-gray-f7 px-6 placeholder-gray-6b pointer-events-none"
                                style={{ height: '56px' }}
                                disabled
                                placeholder="Create your post here."
                            />
                        </div>
                        <Posts usefulExpressions scrollRef={scrollRef} />
                    </div>
                </div>
                <Sidebar />
            </div>
        </>
    )
})
