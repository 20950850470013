import React from 'react'
import Loader from 'Shared/Loader'

type Props = {
    children: any
    onClick(): void
    loading?: boolean
    success?: boolean
    isRed?: boolean
}
const actionButton = ({
    children,
    onClick,
    loading,
    success,
    isRed,
}: Props) => {
    return (
        <div
            className={`flex-center hover:bg-gray-f7 p-2 px-6 ${
                loading && 'bg-gray-f7'
            } ${success && 'text-green'} ${isRed && 'text-red-5a'}`}
            onClick={onClick}
        >
            <button disabled={loading}>{children}</button>
            {loading && (
                <Loader className="w-5 h-5 rounded text-blue-primary blue-primary bg-blue-primary ml-4" />
            )}
        </div>
    )
}

export default actionButton
