import React from 'react'
import dayjs from 'dayjs'
import Audio from 'Post/Modal/RecordMission/Completed/Audio'
import { MissionRecordUser } from 'Post/api'
import api from 'api'
import { Heart } from '@styled-icons/boxicons-solid/Heart'
import cn from 'classnames'
import { getCurrentUserId } from 'User/currentUser'
import { Post } from 'Post/types'
import Modal from 'Shared/Modal'
import { queryClient } from 'utils/queryClient'

type Props = {
    post: Post
    user: MissionRecordUser
}

export default function Row({ post, user }: Props) {
    const [showDeleteAlert, setDeleteAlert] = React.useState(false)
    const userId = getCurrentUserId()

    const onLikeClick = async () => {
        if (user.liked) {
            await api.post.unlikeRecordMission({ missionId: user.missionId })
        } else {
            await api.post.likeRecordMission({ missionId: user.missionId })
        }
        await queryClient.invalidateQueries(`mission${post.id}`)
    }

    const onDeleteClick = async () => {
        await api.post.deleteRecordMission({ missionId: user.missionId })
        await queryClient.invalidateQueries(`mission${post.id}`)
        setDeleteAlert(false)
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-row mt-4 ml-6 items-center">
                <span className="text-lg font-semibold">{user.name}</span>
                <span className="ml-2">
                    {dayjs(user.updatedAt).format('hh:mm A, MMM DD, YYYY')}
                </span>
            </div>
            <Audio src={user.missionDir} className="mx-5 mt-3" />
            <div className="flex flex-row ml-8 mt-3 mb-2 items-center">
                <div
                    className={cn(
                        'p-1 cursor-pointer',
                        user.liked ? 'text-blue-primary' : 'text-gray-5f',
                    )}
                    onClick={onLikeClick}
                >
                    <Heart size={15} />
                </div>
                <span className="ml-2 text-gray-5b">
                    Liked {user.likeCount}
                </span>
                {userId === user.id && (
                    <span
                        className="ml-2 text-gray-5b cursor-pointer"
                        onClick={() => setDeleteAlert(true)}
                    >
                        Delete
                    </span>
                )}
            </div>
            {showDeleteAlert && (
                <Modal className="z-50" size="small">
                    <div className="flex flex-col items-center">
                        <span className="my-5">Are you sure to delete?</span>
                        <div className="flex flex-row w-full h-10">
                            <span
                                className="flex-1 flex justify-center items-center text-blue-008bc2 cursor-pointer"
                                onClick={() => setDeleteAlert(false)}
                            >
                                Cancel
                            </span>
                            <span
                                className="flex-1 flex justify-center items-center text-red-31 cursor-pointer"
                                onClick={onDeleteClick}
                            >
                                Yes, Delete
                            </span>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    )
}
