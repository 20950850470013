import React, { ChangeEvent } from 'react'
import Modal from 'Shared/Modal'
import dayjs, { Dayjs } from 'dayjs'

function minimumDate(): Date {
    const now = dayjs(new Date())

    const min = now.minute()
    const minDiff = 60 + 30 - (min % 30)
    let target = now.add(minDiff, 'minute')
    target = target.second(0)
    target = target.millisecond(0)

    return target.toDate()
}

function maximumDate(): Date {
    const now = dayjs(Date())

    let target = now.add(30, 'day')
    target = target.hour(23)
    target = target.minute(59)
    target = target.second(0)
    target = target.millisecond(0)

    return target.toDate()
}

function defaultDate(): Date {
    const now = dayjs(Date())

    let target = now.add(1, 'day')
    target = target.hour(11)
    target = target.minute(0)
    target = target.second(0)
    target = target.millisecond(0)

    return target.toDate()
}

function availableDates(): string[] {
    const min = dayjs(minimumDate())
    const max = dayjs(maximumDate())

    let target = min
    const dates: string[] = []
    while (target < max) {
        dates.push(target.format('MMM DD, YYYY'))
        target = target.add(1, 'day')
    }

    return dates
}

function allHours(): string[] {
    const hours: string[] = []
    for (const amPm of ['AM', 'PM']) {
        for (const hour of [
            '12',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
        ]) {
            for (const minute of ['00', '30']) {
                hours.push(`${hour}:${minute} ${amPm}`)
            }
        }
    }
    return hours
}

type Props = {
    initialDate?: Dayjs
    onTimeSelect: (arg0: Dayjs) => void
    onCancel: () => void
}

export default function TimeSelector({
    initialDate,
    onTimeSelect,
    onCancel,
}: Props) {
    const [targetDate, setTargetDate] = React.useState(
        initialDate ?? defaultDate(),
    )
    const dates = availableDates()
    let hours = allHours()
    if (dayjs(targetDate).isSame(minimumDate(), 'day')) {
        const minimumAvailableHour = dayjs(minimumDate())
            .local()
            .format('h:mm A')
        const index = hours.indexOf(minimumAvailableHour)
        hours = hours.splice(index)
    }

    const onDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const date = dayjs(e.target.value)
        const currentDate = dayjs(targetDate)
        const target = currentDate
            .date(date.date())
            .month(date.month())
            .year(date.year())
            .toDate()
        if (target < minimumDate()) {
            setTargetDate(minimumDate())
        } else {
            setTargetDate(target)
        }
    }
    const onHourChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const date = dayjs(e.target.value, 'h:mm A')
        const currentDate = dayjs(targetDate)
        setTargetDate(
            currentDate.hour(date.hour()).minute(date.minute()).toDate(),
        )
    }

    const selectedDate = dayjs(targetDate).format('MMM DD, YYYY')
    const selectedHour = dayjs(targetDate).format('h:mm A')

    const onSelect = () => {
        onTimeSelect(dayjs(targetDate))
    }

    return (
        <Modal className="p-5">
            <div className="flex flex-col items-center">
                <span className="text-xl font-bold">
                    Choose date and time to post
                </span>
                <div className="flex flex-row mt-4 mb-4">
                    <select
                        onChange={onDateChange}
                        value={selectedDate}
                        className="h-10 appearance-none bg-black text-white pl-2 pr-2"
                    >
                        {dates.map((d) => (
                            <option key={d}>{d}</option>
                        ))}
                    </select>
                    <select
                        onChange={onHourChange}
                        value={selectedHour}
                        className="ml-3 h-10 appearance-none bg-black text-white pl-2 pr-2"
                    >
                        {hours.map((d) => (
                            <option key={d}>{d}</option>
                        ))}
                    </select>
                </div>
                <div className="flex flex-row space-x-7">
                    <button
                        className="text-gray-5b font-semibold text-lg"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                    <button
                        className="bg-blue-primary rounded-full text-white font-semibold text-lg px-5 h-7"
                        onClick={onSelect}
                    >
                        Select
                    </button>
                </div>
            </div>
        </Modal>
    )
}
