import React, { useState } from 'react'
import { X } from '@styled-icons/boxicons-regular/X'
import cn from 'classnames'
import { CaretDown } from '@styled-icons/boxicons-regular/CaretDown'
import submit from 'Post/Form/submit'
import ContentEditable from 'Shared/Form/ContentEditable'
import Audio from 'Post/Card/Audio'
import Sentence from 'Home/Sentence'
import VideoPreview from 'Post/Form/VideoPreview'
import ImagePreviews from 'Post/Form/ImagePreviews'
import AddMediaPanel from 'Post/Form/MainScreen/AddMediaPanel'
import { State } from 'Post/Form/State'
import { State as ImageUploadState } from 'utils/imageUploadState'
import { State as VideoUploadState } from 'utils/videoUploadState'
import { State as YouTubeState } from 'utils/youTubeState'
import { observer } from 'mobx-react-lite'
import ZoomLink from 'Post/Attachments/Link/ZoomLink'
import SharedPostLink from 'Post/Attachments/Link/SharedPostLink'
import BannerDynamic from 'Shared/BannerDynamic'
import { useLearningLanguage } from 'utils/learningLanguage'
import dayjs from 'dayjs'

type Props = {
    state: State
    imageUploadState: ImageUploadState
    videoUploadState: VideoUploadState
    youTubeState: YouTubeState
    onClose(): void
    tryToClose(): void
}

export default observer(function PostFormMainScreen({
    state,
    imageUploadState,
    videoUploadState,
    youTubeState,
    onClose,
    tryToClose,
}: Props) {
    const classesCount = state.values.classes.length
    const [newBannerValue, setNewBannerValue] = useState('')
    const [correction, setCorrection] = useState(
        state.values.allow_correction === 0 ? false : true,
    )
    const language = useLearningLanguage()

    return (
        <div className="flex flex-col h-full">
            {imageUploadState.warningModal}
            {imageUploadState.dragArea}
            <button
                type="button"
                className="absolute top-0 right-0 text-gray-5f mt-8 mr-6"
                onClick={tryToClose}
            >
                <X size={32} />
            </button>
            <div className="text-2xl uppercase text-center pt-8 pb-6 border-b border-gray-c5">
                {state.values.id ? 'Edit Post' : 'Create'}
            </div>
            {/* THIS */}
            <div
                className={cn(
                    'p-6 flex flex-col flex-1 h-full overflow-hidden mb-90px',
                    imageUploadState.hasPreviews ||
                        youTubeState.video ||
                        videoUploadState.video
                        ? ''
                        : 'border-b border-gray-c5',
                )}
            >
                <div className="flex">
                    <button
                        type="button"
                        className="w-1/2 bg-gray-f7 flex items-center justify-between px-5 text-xl h-10 rounded-full border border-gray-8b block relative z-30"
                        onClick={() => state.setCurrentScreen('selectTarget')}
                    >
                        <div className="truncate">
                            {classesCount > 0
                                ? `${state.values.classes
                                      .map((c) => c.name)
                                      .join(', ')}`
                                : state.values.isPublic
                                ? 'Public'
                                : 'Only for me'}
                        </div>
                        <CaretDown size={24} />
                    </button>
                    <button
                        type="button"
                        className={cn(
                            'w-1/2 ml-7 bg-blue-primary text-white h-10 rounded-full font-bold flex-center',
                            !state.canPost && 'opacity-25',
                        )}
                        disabled={!state.canPost}
                        onClick={() => {
                            onClose()
                            submit({
                                state,
                                allow_correction: correction ? 1 : 0,
                            })
                        }}
                    >
                        Post
                    </button>
                    {state.values.schedule && (
                        <span className="absolute top-0 right-0 mt-40 mr-12 text-xs text-gray-5b">
                            Scheduled:{' '}
                            {dayjs(state.values.schedule).format(
                                'MMM DD, YYYY, h:mmA',
                            )}
                        </span>
                    )}
                </div>

                <div className="mt-7 mb-4 text-lg block h-full overflow-scroll">
                    <ContentEditable
                        placeholder={`Post anything about ${language} learning.`}
                        correction={correction}
                        editorRef={state.editorRef}
                        autoFocus
                        newBannerValue={newBannerValue}
                        getValue={() => state.values.html}
                        setValue={(html) => state.setHTML(html)}
                        openTag={() => state.setCurrentScreen('tag')}
                    />
                    <BannerDynamic
                        setNewBannerValue={setNewBannerValue}
                        html={state.values.html}
                        setValue={(html) => state.setHTML(html)}
                        setMeta={(link, title, image) =>
                            state.setMeta(link, title, image)
                        }
                        preview_image={state.values.preview_image || ''}
                        preview_title={state.values.preview_title || ''}
                        link={state.values.link || ''}
                    />

                    {state.values.audio && (
                        <Audio
                            src={state.values.audio.url}
                            className="mt-4"
                            onEdit={() => state.setCurrentScreen('audio')}
                            onDelete={() => state.setAudio()}
                        />
                    )}
                    {state.values.loopingAudio && (
                        <Audio
                            src={state.values.loopingAudio}
                            loop
                            className="mt-4"
                            onDelete={() => state.removeLoopingAudio()}
                        />
                    )}
                    {state.values.notebookSentence && (
                        <Sentence state={state} className="mt-4" />
                    )}
                    {state.values.sharedPost && (
                        <SharedPostLink
                            sharedPost={state.values.sharedPost}
                            className="mt-4"
                            onDelete={() => state.setSharedPost()}
                        />
                    )}
                    {state.values.zoomLink && (
                        <ZoomLink
                            className="mt-4"
                            zoomLink={state.values.zoomLink}
                            onDelete={() => state.setZoomLink()}
                        />
                    )}
                    {youTubeState.video}

                    {videoUploadState.video && (
                        <VideoPreview
                            video={videoUploadState.video.url}
                            removeVideo={() =>
                                videoUploadState.setFile(undefined)
                            }
                            className="mt-4"
                        />
                    )}

                    <ImagePreviews
                        images={imageUploadState.images}
                        removeImage={(image) =>
                            imageUploadState.removeImage(image)
                        }
                    />

                    <AddMediaPanel
                        state={state}
                        correction={correction}
                        setCorrection={setCorrection}
                        imageUploadState={imageUploadState}
                        videoUploadState={videoUploadState}
                    />
                </div>
            </div>
        </div>
    )
})
