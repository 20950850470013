import React from 'react'
import api from 'api'
import { addOrganization } from './cacheActions'

type Props = {
    onAddCompleted: () => void
    onCancel: () => void
}

export default function AddOrganizationForm({
    onAddCompleted,
    onCancel,
}: Props) {
    const [name, setName] = React.useState('')

    const onAddClick = React.useCallback(async () => {
        const organization = await api.admin.createOrganization(name)
        addOrganization(organization)
        onAddCompleted()
    }, [onAddCompleted, name])

    return (
        <div className="flex flex-col items-center px-4">
            <span className="font-bold text-2xl mt-5">Add Organization</span>
            <input
                className="w-full border border-gray-97 rounded mt-5 h-10 flex justify-center px-2"
                placeholder="Name of Organization"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <div className="flex flex-row space-x-7">
                <button className="text-gray-5b text-lg" onClick={onCancel}>
                    Cancel
                </button>
                <button
                    className="my-4 rounded bg-blue-primary text-white p-2 text-lg"
                    onClick={onAddClick}
                >
                    Add Organization
                </button>
            </div>
        </div>
    )
}
