import React, { useEffect } from 'react'
import logo from 'assets/images/logo.svg'
import { Link, NavLink, useLocation } from 'react-router-dom'
import routes from 'routes'
import Search from 'App/Search/Search'
import { observer } from 'mobx-react-lite'
import { getCurrentUserId, useCurrentUser } from 'User/currentUser'
import Notifications from 'App/Notifications/List'
import cn from 'classnames'
import style from './style.module.css'
import Item from './LanguageSelector/Item'
import showMore from 'assets/images/icons/arrow-down.svg'
import LanguageSelector from './LanguageSelector/LanguageSelector'
import { useLearningLanguage, useSelectLanguage } from 'utils/learningLanguage'
import useToggle from 'utils/useToggle'
import useRecords from 'utils/useRecords'
import { Page, UsefulExpression } from 'Post/types'
import api from 'api'
import { useQuery } from 'react-query'
import { getToken, onMessageListener } from './firebase'
import { registerToken } from './api'

type Props = {
    children: React.ReactNode
}

export default observer(function Layout({ children }: Props) {
    const [{ avatar }] = useCurrentUser()
    const location = useLocation()

    const language = useLearningLanguage()
    const aboutFile = useSelectLanguage({
        english: '/about/en/',
        korean: '/about/ko/',
    })
    const [languageOpened, toggleLanguage] = useToggle()

    // Whiteboard post reloading
    const { refetch: postRefetch } = useRecords<Page>({
        key: ['posts', {}],
        load: ({ limit, offset }) => api.post.list({ limit, offset }),
    })

    // User profile reloading
    const currentUserId = getCurrentUserId()
    const { refetch: userRefetch } = useQuery(['user', currentUserId], () =>
        api.user.getUser({ id: currentUserId }),
    )
    const { refetch: userContentRefetch } = useRecords<Page>({
        key: ['posts', { userId: currentUserId, search: '' }],
        load: ({ limit, offset }) =>
            api.post
                .userPosts({ userId: currentUserId, limit, offset })
                .then((posts) => ({
                    posts,
                    usefulExpressions: [] as UsefulExpression[],
                })),
    })

    // Admin access check
    const { data: adminAccess } = useQuery(
        ['adminAccess'],
        () => api.user.adminAccess(),
        {
            enabled: [1, 6, 8].indexOf(currentUserId) > -1,
        },
    )

    const [showNotificationBadge, setNotificationBadge] = React.useState(false)
    useEffect(() => {
        async function updateToken() {
            try {
                const token = await getToken()
                onMessageListener()
                    .then((payload) => {
                        console.log('NOTIFICATION RECEIVED')
                        console.log(payload)
                        setNotificationBadge(true)
                    })
                    .catch((err) => console.error('failed: ', err))
                navigator.serviceWorker.addEventListener('message', (m) => {
                    console.log('Notification banner clicked: ', m)
                    setNotificationBadge(true)
                })
                const registeredToken =
                    localStorage.getItem('FIREBASE_TOKEN') ?? ''
                if (registeredToken != token) {
                    localStorage.setItem('FIREBASE_TOKEN', token)
                    await registerToken(token)
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }
        updateToken().then()
    }, [setNotificationBadge])

    const closeLanguageSelector = () => {
        if (languageOpened) {
            toggleLanguage()
        }
    }
    const hardReloadHome = () => {
        closeLanguageSelector()
        if (location.pathname === routes.home()) {
            postRefetch()
        }
    }

    const hardReloadClass = () => {
        closeLanguageSelector()
        if (location.pathname === routes.class.classes()) {
            postRefetch()
        }
    }

    const hardReloadSettings = () => {
        closeLanguageSelector()
        if (location.pathname === routes.settings.profile()) {
            window.location.reload()
        }
    }

    const hardReloadUserProfile = () => {
        closeLanguageSelector()
        if (location.pathname == routes.user('me')) {
            userRefetch()
            userContentRefetch()
        }
    }

    return (
        <div className="w-full h-full flex flex-col">
            <div
                className="bg-white shadow flex flex-shrink-0 items-center relative"
                style={{ height: '80px' }}
            >
                <div className="absolute top-0 left-0 bottom-0 ml-8 flex-center">
                    <Link to={routes.home()} onClick={hardReloadHome}>
                        <img style={{ width: '50px' }} src={logo} alt="logo" />
                    </Link>
                </div>
                <div
                    className={cn(
                        'w-full mx-auto font-bold min-h-0 h-full flex flex-row',
                        style.centerSection,
                    )}
                >
                    <div
                        className="w-full flex items-center h-full relative"
                        style={{ maxWidth: '640px' }}
                    >
                        <div className="flex">
                            <NavLink
                                to={routes.home()}
                                onClick={hardReloadHome}
                                className="text-lg"
                                exact
                                activeClassName="text-blue-primary"
                            >
                                Home
                            </NavLink>
                            <NavLink
                                to={routes.class.classes()}
                                onClick={hardReloadClass}
                                className="text-lg ml-4"
                                activeClassName="text-blue-primary"
                            >
                                Class
                            </NavLink>
                            <div>
                                {showNotificationBadge && (
                                    <div
                                        className="absolute rounded-full"
                                        style={{
                                            width: '13px',
                                            height: '13px',
                                            background: '#FF0000',
                                            marginLeft: '7.25rem',
                                            top: '1.25rem',
                                        }}
                                    />
                                )}
                                <Notifications
                                    onClick={() => setNotificationBadge(false)}
                                />
                            </div>
                            <NavLink
                                to={routes.settings.profile()}
                                onClick={hardReloadSettings}
                                className="text-lg ml-4 flex-center"
                                activeClassName="text-blue-primary"
                            >
                                Settings
                            </NavLink>
                        </div>
                        <div className="hidden md:flex ml-4 items-center h-full">
                            <Search />
                            <Link
                                to={routes.user('me')}
                                onClick={hardReloadUserProfile}
                            >
                                <img
                                    style={{ width: '30px', height: '30px' }}
                                    className="ml-4 rounded-full"
                                    src={avatar}
                                    alt="avatar"
                                />
                            </Link>
                        </div>
                    </div>
                    <button
                        className="flex-center ml-2 hidden md:flex"
                        onClick={toggleLanguage}
                    >
                        <Item language={language} />
                        <img src={showMore} alt="select learning language" />
                    </button>
                </div>
                <div
                    className={cn(
                        'absolute top-0 right-0 bottom-0 flex-center mr-8 text-gray-6e text-16 uppercase',
                        style.extraLinks,
                    )}
                >
                    {adminAccess && adminAccess.classManagement && (
                        <NavLink
                            to={routes.admin.classManagement()}
                            className="text-lg ml-4 flex-center"
                            activeClassName="text-blue-primary"
                        >
                            Admin
                        </NavLink>
                    )}
                    <a
                        href={aboutFile}
                        target="_blank"
                        className="ml-5 transition duration-200 hover:text-black"
                    >
                        About Schoool
                    </a>
                </div>
            </div>
            <div
                style={{ maxWidth: '1100px' }}
                className="w-full mx-auto flex-grow"
            >
                {children}
            </div>
            {languageOpened && (
                <div className="absolute mt-20" style={{ marginLeft: '46rem' }}>
                    <LanguageSelector />
                </div>
            )}
        </div>
    )
})
