import React from 'react'

export default function BannerStatic({
    url,
    previewTitle,
    previewImage,
}: {
    url: string
    previewTitle: string
    previewImage: string
}) {
    return (
        <a target="_blank" href={url}>
            <div className="flex justify-start bg-gray-f7 text-white p-4">
                <div>
                    <img
                        className="w-16 h-16 mr-4 object-cover rounded"
                        src={previewImage}
                    />
                </div>
                <div className="flex flex-col flex-1 justify-start overflow-hidden">
                    <div className="text-black">{previewTitle}</div>
                    <div className="text-gray-87 text-xs mt-1">
                        <span>{url}</span>
                    </div>
                </div>
            </div>
        </a>
    )
}
