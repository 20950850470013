import React from 'react'
import ActionButton from './ActionButton'

type Props = {
    setOpenMenu(arg: boolean): void
    setAddSentence(): void
}

const SendToNotebook = ({ setAddSentence, setOpenMenu }: Props) => {
    const sendNotes = () => {
        setOpenMenu(false)
        setAddSentence()
    }

    return (
        <>
            <ActionButton onClick={sendNotes}>Send to Notebook</ActionButton>
        </>
    )
}

export default SendToNotebook
