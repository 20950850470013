import React from 'react'
import { LearningLanguage } from 'utils/learningLanguage'

type Props = {
    language: LearningLanguage
}

function getFlagForLanguage(language: LearningLanguage): string {
    if (language === 'English') {
        return '🇺🇸'
    } else {
        return '🇰🇷'
    }
}

export default function Item({ language }: Props) {
    return (
        <div className="flex flex-row">
            <span>{`${getFlagForLanguage(language)} ${language}`}</span>
        </div>
    )
}
