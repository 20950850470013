import React from 'react'
import { Post } from 'Post/types'
import { Comment } from 'Post/Comment/types'
import useToggle from '../../../utils/useToggle'
import useOnClickOutside from './useOnClickOutside'
import { updateCache } from '../cacheActions'
import AddSentence from '../../../Notebook/AddSentence'
import { Link } from 'react-router-dom'
import routes from 'routes'
import Text from '../../Text'
import { DotsHorizontalRounded } from '@styled-icons/boxicons-regular/DotsHorizontalRounded'
import SendToStudyNotes from './actions/SendToStudyNotes'
import SendToNotebook from './actions/SendToNotebook'
import PinToTop from './actions/PinToTop'
import Copy from './actions/Copy'
import Edit from './actions/Edit'
import Delete from './actions/Delete'
import Attachments from '../../Attachments'
import CommentLike from './Like'
import EditForm from '../Form/EditForm'
import CommentForm from '../Form'
import cn from 'classnames'

type Props = {
    post: Post
    comment: Comment
    indentBorder: boolean
    scrollingElementRef?: { current: HTMLDivElement | null }
    highlightedCommentId?: number
    setHighlightedCommentId(id?: number): void
}

export default function Item({
    post,
    comment,
    indentBorder,
    scrollingElementRef,
    highlightedCommentId,
    setHighlightedCommentId,
}: Props) {
    const commentRef = React.useRef<HTMLDivElement>(null)
    const ref = React.useRef<HTMLDivElement>(null)

    const [openMenu, setOpenMenu] = React.useState(false)
    const [addSentence, setAddSentence] = useToggle()
    const [showReply, toggleReply] = useToggle()
    const [showEdit, toggleEdit] = useToggle()

    // State for our modal
    // Call hook passing in the ref and a function to call on outside click
    useOnClickOutside(ref, () => setOpenMenu(false))

    React.useEffect(() => {
        const scrollingElement = scrollingElementRef?.current
        const commentElement = commentRef.current
        if (highlightedCommentId !== comment.id) return
        if (post.user.id === 0) return

        if (commentElement && scrollingElement) {
            scrollingElement.scrollTo({
                top: commentElement.offsetTop - 100,
                behavior: 'auto',
            })
            setTimeout(() => {
                setHighlightedCommentId()
            }, 1500)
        }
    }, [highlightedCommentId, post.user.id])

    const currentUser = localStorage.getItem('currentUser')
    const currentUserObject = currentUser ? JSON.parse(currentUser) : {}
    const isCommentUser = currentUserObject.id === comment.user.id

    const openSubMenu = () => {
        setOpenMenu(!openMenu)
    }
    const onSuccessEdit = (updatedComment: Comment) => {
        updateCache(updatedComment, post.id)
        toggleEdit()
    }

    return (
        <>
            <hr
                className="text-gray-dc bg-white"
                style={{ marginLeft: `${80 * (indentBorder ? 1 : 0)}px` }}
            />
            {addSentence && (
                <AddSentence
                    onClose={setAddSentence}
                    title="Add Expressions"
                    sentence={{ text: comment.text, translation: '' }}
                    buttonText="Add"
                    contentClass="pt-4 px-5 pb-6"
                    buttonWrapClass="flex-center mt-5"
                />
            )}
            {!showEdit ? (
                <div
                    className={cn(
                        'pt-6 pb-3',
                        highlightedCommentId === comment.id &&
                            'bg-red-highlightBackground',
                    )}
                    ref={commentRef}
                >
                    <div className="flex px-8">
                        <Link
                            to={routes.user(comment.user.id)}
                            className="flex-shrink-0 mr-2"
                        >
                            <img
                                src={comment.user.avatar}
                                alt="avatar"
                                className="w-10 h-10 rounded-full flex-shrink-0"
                            />
                        </Link>
                        <div className="flex-grow w-93% break-all	">
                            <div className="flex justify-between relative align-start">
                                <Text
                                    showFullText
                                    className="text-lg text-gray-6b mb-2"
                                    data={comment}
                                >
                                    <Link
                                        to={routes.user(comment.user.id)}
                                        className="font-bold"
                                    >
                                        {comment.user.name}
                                    </Link>{' '}
                                </Text>
                                <div className="cursor-pointer flex flex-col items-end">
                                    <DotsHorizontalRounded
                                        onClick={openSubMenu}
                                        size={20}
                                        id="menu-button"
                                    />
                                    {openMenu && (
                                        <div
                                            ref={ref}
                                            id="sub-menu"
                                            style={{
                                                top: '20px',
                                                minWidth: '200px',
                                            }} // can't set tailwind top property in class, if you can please do so
                                            className="absolute z-10 py-2 text-blue-primary text-center position-absolute bg-white border border-gray-e2 rounded shadow-md"
                                        >
                                            {post.classes.length === 1 &&
                                                comment.text.length !== 0 && (
                                                    <SendToStudyNotes
                                                        classId={
                                                            post.classes[0].id
                                                        }
                                                        note={comment.text}
                                                        setOpenMenu={
                                                            setOpenMenu
                                                        }
                                                    />
                                                )}

                                            <SendToNotebook
                                                setOpenMenu={setOpenMenu}
                                                setAddSentence={setAddSentence}
                                            />
                                            {post.classes.length === 1 &&
                                                (post.isClassOwner ||
                                                    post.isClassAdmin) && (
                                                    <PinToTop
                                                        comment={comment}
                                                        share_id={post.id}
                                                        review_id={comment.id}
                                                        pinned={comment.pinned}
                                                        setOpenMenu={
                                                            setOpenMenu
                                                        }
                                                        postId={post.id}
                                                    />
                                                )}

                                            <Copy
                                                text={comment.text}
                                                setOpenMenu={setOpenMenu}
                                            />
                                            {isCommentUser && (
                                                <Edit
                                                    toggleEdit={toggleEdit}
                                                    comment={comment}
                                                    setOpenMenu={setOpenMenu}
                                                />
                                            )}
                                            {(isCommentUser ||
                                                (post.classes.length === 1 &&
                                                    (post.isClassOwner ||
                                                        post.isClassAdmin))) && (
                                                <Delete
                                                    postId={post.id}
                                                    commentId={comment.id}
                                                    setOpenMenu={setOpenMenu}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Attachments
                                audioClass="mt-1 mb-4"
                                linkClass="mt-1 mb-4"
                                imageClass="w-full mt-1 mb-4"
                                videoClass="mt-1 mb-4"
                                fileClass="mt-1 mb-4"
                                attachments={{
                                    audio: comment.audio,
                                    loopingAudio: comment.loopingAudio,
                                    images: [comment.image].filter(
                                        (url) => url,
                                    ) as string[],
                                    video: comment.video,
                                    file: comment.file,
                                    date: comment.date,
                                    user: comment.user,
                                }}
                            />
                            <div className="flex items-center justify-between">
                                <div className="text-gray-97">
                                    {comment.date
                                        .local()
                                        .format('MMM DD, YYYY')}
                                </div>
                                <div className="text-gray-6e flex items-center text-xs">
                                    <CommentLike
                                        post={post}
                                        comment={comment}
                                    />
                                    <button
                                        className="ml-4 font-bold"
                                        onClick={toggleReply}
                                    >
                                        Reply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    className="shadow-lg m-4 border border-gray-c5"
                    style={{ borderRadius: '10px' }}
                >
                    <EditForm
                        comment={comment}
                        defaultValue={comment.text}
                        post={post}
                        className="pt-6 pb-3 pl-5 pr-8 flex-center"
                        minHeight={36}
                        autoFocus
                        onSuccess={onSuccessEdit}
                    />
                </div>
            )}
            {showReply && (
                <div
                    className="shadow-lg pt-4 mx-5 mb-5 border border-gray-c5"
                    style={{ borderRadius: '10px' }}
                >
                    <CommentForm
                        comment={{
                            parentCommentId:
                                comment.parentCommentId || comment.id,
                            inReplyTo: {
                                ...comment,
                                id: comment.parentCommentId ? comment.id : -1,
                            },
                        }}
                        post={post}
                        className="pt-6 pb-3 pl-5 pr-8 flex-center"
                        minHeight={36}
                        autoFocus
                        onSuccess={toggleReply}
                    />
                </div>
            )}
        </>
    )
}
