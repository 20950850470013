import React from 'react'
import { ArrowLeft } from '@styled-icons/fa-solid/ArrowLeft'
import { getCurrentUser } from 'User/currentUser'
import publicIcon from 'assets/images/icons/public.png'
import useToggle from 'utils/useToggle'
import Alert from 'Shared/Modal/Alert'
import { State } from 'Post/Form/State'
import { observer } from 'mobx-react-lite'
import { useQuery } from 'react-query'
import SelectTargetOption from 'Post/Form/SelectTarget/Option'
import api from 'api'
import Spin from 'assets/images/icons/Spin'
import { Class } from 'Class/types'
import cn from 'classnames'
import { formatDateTime } from 'utils/time'
import SelectSchedule from 'Post/Form/SelectTarget/SelectSchedule'
import Modal from 'Shared/Modal'

type Props = {
    state: State
}

export default observer(function SelectTarget({ state }: Props) {
    const currentUser = getCurrentUser()
    const [showNotice, toggleNotice] = useToggle()
    const [showSchedule, toggleSchedule] = useToggle()
    const { data, isLoading } = useQuery('classes', () => api.classes.list())

    const joined = data?.joined || []
    const owning = data?.owning || []
    const classes = [...joined, ...owning]
    const selectedClasses = state.values.classes

    // Sort the classes based on last uploaded
    const lastUploadedClasses = JSON.parse(
        localStorage.getItem('top_three_selected_classes') || '[]',
    )
    const lastUploadedClassesIds = lastUploadedClasses.map(
        (c: { id: number }) => c.id,
    )
    classes.sort((first, second) => {
        if (
            lastUploadedClassesIds.indexOf(first.id) > -1 &&
            lastUploadedClassesIds.indexOf(second.id) > -1
        ) {
            return (
                lastUploadedClassesIds.indexOf(first.id) -
                lastUploadedClassesIds.indexOf(second.id)
            )
        } else if (lastUploadedClassesIds.indexOf(first.id) > -1) {
            return -1
        } else if (lastUploadedClassesIds.indexOf(second.id) > -1) {
            return 1
        } else if (first.isOwn && !second.isOwn) {
            return -1
        } else if (!first.isOwn && second.isOwn) {
            return 1
        } else {
            return classes.indexOf(first) - classes.indexOf(second)
        }
    })

    const scheduledDate = state.values.schedule

    const onClose = () => {
        localStorage.setItem(
            'selected_classes',
            JSON.stringify(selectedClasses),
        )

        let topThree = selectedClasses
        if (topThree.length < 3) {
            const lastUploadedClasses = JSON.parse(
                localStorage.getItem('top_three_selected_classes') || '[]',
            )
            topThree = [...selectedClasses, ...lastUploadedClasses]
            if (topThree.length > 3) {
                topThree = topThree.slice(0, 3)
            }
        }
        localStorage.setItem(
            'top_three_selected_classes',
            JSON.stringify(topThree),
        )

        state.backToForm()
    }

    const toggleClass = (item: Class) => {
        // Setting in localstorage

        state.setIsPublic(false)
        state.setClasses([item])
    }

    const clearSchedule = () => {
        state.setSchedule()
    }

    return (
        <>
            {showNotice && (
                <Alert
                    title="Notice"
                    text="Private class cannot be selected with other public classes."
                    onClose={toggleNotice}
                />
            )}
            {showSchedule && (
                <Modal
                    width={500}
                    className="relative create-modal h-full overflow-scroll create-modal"
                    onClose={toggleSchedule}
                >
                    <SelectSchedule
                        state={state}
                        close={toggleSchedule}
                        onSchedule={() => {}}
                    />
                </Modal>
            )}
            <div className="pb-8 h-full">
                <div className="text-2xl uppercase text-center pt-8 pb-6 border-b border-gray-c5 relative">
                    <div className="absolute top-0 left-0 bottom-0 flex-center pl-6 text-gray-5f">
                        <button type="button" onClick={onClose}>
                            <ArrowLeft size={26} />
                        </button>
                    </div>
                    Select Target
                </div>
                {(!state.values.id || state.values.schedule) && (
                    <div className="flex flex-row h-15">
                        <div
                            className={cn(
                                'inline-flex items-center h-full w-1/2 font-semibold text-lg',
                                scheduledDate
                                    ? 'bg-gray-f5'
                                    : 'bg-blue-scheduled',
                            )}
                            onClick={clearSchedule}
                        >
                            <span className="w-full text-center">Post Now</span>
                        </div>
                        <div
                            className={cn(
                                'inline-flex items-center h-full w-1/2 font-semibold text-lg',
                                scheduledDate
                                    ? 'bg-blue-scheduled'
                                    : 'bg-gray-f5',
                            )}
                            onClick={toggleSchedule}
                        >
                            <span className="text-center w-full">
                                {scheduledDate && 'Scheduled'}
                                {scheduledDate && (
                                    <span className="font-normal">
                                        <br />
                                        {formatDateTime(scheduledDate)}
                                    </span>
                                )}
                                {!scheduledDate && 'Schedule'}
                            </span>
                        </div>
                    </div>
                )}
                {currentUser.isInstructor && (
                    <SelectTargetOption
                        image={publicIcon}
                        title="Public"
                        text="Anyone can see this post."
                        checked={state.values.isPublic}
                        onChange={() => {
                            state.setIsPublic(!state.values.isPublic)
                        }}
                    />
                )}
                <div className="h-full overflow-scroll">
                    <SelectTargetOption
                        image={currentUser.avatar}
                        title="Only for me"
                        text="Only you can see this post."
                        checked={
                            !state.values.isPublic &&
                            selectedClasses.length === 0 &&
                            localStorage.getItem('selected_class_id') === null
                        }
                        onChange={() => {
                            localStorage.removeItem('selected_class_id')
                            localStorage.removeItem('selected_class_name')
                            state.setIsPublic(false)
                            state.setClasses([])
                        }}
                    />
                    {isLoading && (
                        <div className="flex-center my-5">
                            <Spin className="w-10 h-10 text-blue-primary animate-spin" />
                        </div>
                    )}
                    {classes.map((item) => {
                        let checked = false
                        if (
                            localStorage.getItem('selected_class_id') ===
                            item.id.toString()
                        ) {
                            checked = true
                        } else {
                            checked = selectedClasses.some(
                                ({ id }) => id === item.id,
                            )
                        }

                        return (
                            <SelectTargetOption
                                key={item.id}
                                image={item.image}
                                title={item.name}
                                text="Only this class memebers can see this post."
                                checked={checked}
                                onChange={() => toggleClass(item)}
                            />
                        )
                    })}
                </div>
                {!isLoading &&
                    data?.joined.length === 0 &&
                    data?.owning.length === 0 && (
                        <div
                            className="pt-8 pb-4 text-gray-6b text-center w-full mx-auto"
                            style={{ width: '400px' }}
                        >
                            You have no target class as you haven’t created or
                            joined any class yet.
                        </div>
                    )}
            </div>
        </>
    )
})
