import React from 'react'
import { Organization, OrganizationPostGroup } from 'Class/Management/types'
import cn from 'classnames'
import TwoButtonAlert from 'Shared/Modal/TwoButtonAlert'
import api from 'api'
import Loader from 'Shared/Loader'
import { removeAccess } from 'Admin/cacheActions'

type Props = {
    organization: Organization
    group: OrganizationPostGroup
}

export default function AccessiblePostGroupRow({
    organization,
    group,
    className,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
    const [
        isShowingRemoveAccessAlert,
        setShowingRemoveAccessAlert,
    ] = React.useState(false)
    const [isRemoveInProcess, setIsRemoveInProcess] = React.useState(false)

    const onRemoveClick = React.useCallback(() => {
        if (isRemoveInProcess) {
            return
        }
        setShowingRemoveAccessAlert(true)
    }, [isRemoveInProcess, setShowingRemoveAccessAlert])
    const dismissRemoveAlert = React.useCallback(() => {
        setShowingRemoveAccessAlert(false)
    }, [setShowingRemoveAccessAlert])
    const onRemoveAccessConfirmed = React.useCallback(async () => {
        if (isRemoveInProcess) {
            return
        }
        setIsRemoveInProcess(true)
        setShowingRemoveAccessAlert(false)
        await api.admin.removeAccess(organization.id, group.id)
        removeAccess(organization.id, group.id)
        setIsRemoveInProcess(false)
    }, [
        isRemoveInProcess,
        setShowingRemoveAccessAlert,
        setIsRemoveInProcess,
        organization,
        group,
    ])

    return (
        <div className={cn('flex flex-row items-center p-2', className)}>
            <span className="font-normal text-base flex-grow">
                • {group.name}
            </span>
            <span
                className="rounded bg-blue-primary text-white p-1 cursor-pointer"
                onClick={onRemoveClick}
            >
                {!isRemoveInProcess && 'Remove Access'}
                {isRemoveInProcess && <Loader className="w-3 h-3 mx-12" />}
            </span>
            {isShowingRemoveAccessAlert && (
                <TwoButtonAlert
                    title="Are you sure?"
                    content={`Clicking yes will remove post group '${group.name}' access from '${organization.name}'`}
                    leftTitle="Cancel"
                    leftClassName=""
                    rightTitle="Remove Access"
                    rightClassName="text-red-700"
                    onLeftClick={dismissRemoveAlert}
                    onRightClick={onRemoveAccessConfirmed}
                />
            )}
        </div>
    )
}
