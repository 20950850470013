import React from 'react'

const HighlightCorrection = ({
    correctionRanges,
    text,
}: {
    correctionRanges: string[]
    text: string
}) => {
    const renderText = () => {
        return correctionRanges.map((range: string, i: number) => {
            const rangeStart = parseInt(range.split(',')[0])
            const rangeLength = parseInt(range.split(',')[1])

            if (i !== 0) {
                const previousRangeStart = parseInt(
                    correctionRanges[i - 1].split(',')[0],
                )
                const previousRangeLength = parseInt(
                    correctionRanges[i - 1].split(',')[1],
                )
                // qwerASDmiN
                // 12345678910
                return (
                    <React.Fragment key={rangeStart}>
                        {text.substr(
                            previousRangeStart + previousRangeLength,
                            rangeStart -
                                previousRangeStart -
                                previousRangeLength,
                        )}
                        <span className="text-red-5a">
                            {text.substr(rangeStart, rangeLength)}
                        </span>
                        {i === correctionRanges.length - 1 &&
                            text.substr(
                                rangeStart + rangeLength,
                                text.length - rangeStart - rangeLength,
                            )}
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment key={rangeStart}>
                        {text.substr(0, rangeStart)}
                        <span className="text-red-5a">
                            {text.substr(rangeStart, rangeLength)}
                        </span>
                        {i === correctionRanges.length - 1 &&
                            text.substr(
                                rangeStart + rangeLength,
                                text.length - rangeStart - rangeLength,
                            )}
                    </React.Fragment>
                )
            }
        })
    }

    return <React.Fragment>{renderText()}</React.Fragment>
}
export default HighlightCorrection
