import React from 'react'
import ActionButton from './ActionButton'

type Props = {
    text: string
    setOpenMenu(arg: boolean): void
}

const Delete = ({ text, setOpenMenu }: Props) => {
    const copyCommentToClipboard = () => {
        navigator.clipboard.writeText(text)
        setOpenMenu(false)
    }
    return <ActionButton onClick={copyCommentToClipboard}>Copy</ActionButton>
}

export default Delete
