import React from 'react'
import { Organization } from 'Class/Management/types'
import { OrganizationMember } from 'Admin/types'
import cn from 'classnames'
import TwoButtonAlert from 'Shared/Modal/TwoButtonAlert'
import api from 'api'
import Loader from 'Shared/Loader'
import { addUserToOrganization } from 'Admin/cacheActions'

type Props = {
    organization: Organization
    member: OrganizationMember
}

export default function OrganizationNonMemberRow({
    className,
    organization,
    member,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
    const [isShowingAddAlert, setShowAddAlert] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)

    const onAddClick = React.useCallback(() => {
        setShowAddAlert(true)
    }, [setShowAddAlert])
    const onDismissClick = React.useCallback(() => {
        setShowAddAlert(false)
    }, [setShowAddAlert])
    const onAddConfirmed = React.useCallback(async () => {
        if (isLoading) {
            return
        }
        setIsLoading(true)
        setShowAddAlert(false)
        await api.admin.addUserToOrganization(organization.id, member.id)
        addUserToOrganization(organization.id, member)
        setIsLoading(false)
    }, [setShowAddAlert, isLoading, setIsLoading, organization, member])

    return (
        <div
            className={cn(
                'flex flex-row items-center px-2 py-3 w-full',
                className,
            )}
        >
            <img className="w-15 h-15 rounded-full" src={member.profileImage} />
            <span className="ml-2 flex-grow">
                {member.id} - {member.name}
            </span>
            <span
                className="bg-blue-primary text-white p-1 rounded cursor-pointer"
                onClick={onAddClick}
            >
                {!isLoading && 'Add Access'}
                {isLoading && <Loader className="w-3 h-3 mx-12" />}
            </span>
            {isShowingAddAlert && (
                <TwoButtonAlert
                    title="Are you sure to add user ot organization?"
                    content={`User ${member.name} will be added to ${organization.name}`}
                    leftTitle="Cancel"
                    leftClassName="text-lg"
                    rightTitle="Add User"
                    rightClassName="text-lg text-blue-primary"
                    onLeftClick={onDismissClick}
                    onRightClick={onAddConfirmed}
                />
            )}
        </div>
    )
}
