import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (props: any) => (
    <svg
        width={19}
        height={17}
        viewBox="0 0 19 17"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.056 5.07H0v6.083h4.056l5.07 5.07V0l-5.07 5.07zm11.518 9.49l-1.43-1.43a7.077 7.077 0 000-10.038l1.43-1.43a9.095 9.095 0 010 12.898zm-4.3-4.3l1.44 1.44a5.05 5.05 0 000-7.178l-1.44 1.44a3.042 3.042 0 010 4.299z"
        />
    </svg>
)
