import React from 'react'
import { intent } from 'Settings/api'
import history from 'utils/history'
import routes from 'routes'
import Loader from 'Shared/Loader'
import { Link } from 'react-router-dom'
import logo from 'assets/images/logo.svg'
import timeout from 'utils/timeout'
import { useCurrentUser } from 'User/currentUser'

export default function Succeed() {
    const [receiptUrl, setReceiptUrl] = React.useState<string | undefined>(
        undefined,
    )
    const [currentUser] = useCurrentUser()

    React.useEffect(() => {
        async function runner() {
            if ([1, 8].indexOf(currentUser.id) === -1) {
                history.push(routes.home())
                return
            }
            await timeout(1000)
            const response = await intent()

            if (response.receiptUrl) {
                setReceiptUrl(response.receiptUrl)
            } else {
                history.push(routes.home())
            }
        }

        runner().then()
    }, [])

    if (!receiptUrl) {
        return (
            <div className="flex flex-row justify-center items-center h-full">
                <Loader className="w-20 h-20 " color="#06ACEE" />
            </div>
        )
    }

    return (
        <div className="flex flex-col h-full bg-white items-center justify-center font-notoSans">
            <img src={logo} className="w-20 h-20" />
            <span className="mt-2 font-bold text-28">
                결제가 성공적으로 처리되었습니다
            </span>
            <span className="mt-12 text-center text-2xl">
                아래 버튼을 눌러 Registration Receipt를 다운로드 받으세요.
                <br />
                수강에 관한 상세한 내용은 SCHOOOL Class를 통해 알려드립니다.
            </span>
            <div className="flex flex-row mt-16 space-x-7">
                <a
                    href={receiptUrl}
                    target="_blank"
                    className="w-48 h-10 font-bold text-lg bg-blue-0B3546 rounded text-white cursor-pointer flex justify-center items-center"
                >
                    Receipt Download
                </a>
                <Link
                    to={routes.home()}
                    className="w-48 h-10 font-bold text-lg bg-blue-primary rounded text-white cursor-pointer flex justify-center items-center"
                >
                    SCHOOOL로 가기
                </Link>
            </div>
            <div className="mt-19 w-2/3 h-px bg-gray-ae" />
            <span className="font-italic font-semibold text-lg text-gray-c5 mt-6">
                <i>Study Smart @SCHOOOL</i>
            </span>
        </div>
    )
}
