import React from 'react'
import Modal from 'Shared/Modal'
import Post from 'Post/Card'
import { Post as PostType } from 'Post/types'
import useRecords from 'utils/useRecords'
import api from 'api'
import { getCurrentUser } from 'User/currentUser'
import { Comment } from 'Post/Comment/types'
import CommentForm from 'Post/Comment/Form'
import CommentsList from 'Post/Comment/List'
import Spin from 'assets/images/icons/Spin'
import MissionProgress, { MissionType } from 'Post/Modal/MissionProgress'
import { useQuery } from 'react-query'
import RecordMission from 'Post/Modal/RecordMission'
import AssignmentMission from './AssignmentMission'

type Props = {
    onClose: () => void
    post: PostType
    commentId?: number
    setCommentId?: (id: number | undefined) => void
    autoFocus?: boolean
}

export default function PostModal({
    onClose,
    post,
    autoFocus,
    ...props
}: Props) {
    const scrollingElementRef = React.useRef<HTMLDivElement>(null)
    const commentFormRef = React.useRef<HTMLDivElement>(null)

    const [highlightedCommentId, setHighlightedCommentId] = React.useState(
        props.commentId,
    )

    const { isFetching, data } = useRecords({
        key: [`comments${post.id}`],
        load: () => api.comment.list({ postId: post.id }),
        options: {
            enabled: post.user.id !== 0,
        },
    })
    const { data: missions } = useQuery(`mission${post.id}`, () =>
        api.post.missionCompleted({ post }),
    )

    React.useEffect(() => {
        if (autoFocus) {
            const scrollElement = scrollingElementRef.current
            const formElement = commentFormRef.current
            if (scrollElement && formElement) {
                scrollElement.scrollTo({
                    top: formElement.offsetTop,
                    behavior: 'auto',
                })
            }
        }
    }, [])

    const { avatar, name } = getCurrentUser()

    const comments = data?.pages.flat() as Comment[] | undefined

    const commentId = props.setCommentId
        ? props.commentId
        : highlightedCommentId
    const setCommentId = props.setCommentId ?? setHighlightedCommentId

    return (
        <Modal
            onClose={onClose}
            size="large"
            scrollingElementRef={scrollingElementRef}
        >
            <Post post={post} className="mb-0 rounded" disableComment={true} />
            <div
                ref={commentFormRef}
                className="m-8 border border-gray-c5 pt-5 px-5 pb-4 shadow-md"
                style={{ borderRadius: '10px' }}
            >
                <div className="flex items-center">
                    <img
                        src={avatar}
                        alt="avatar"
                        className="w-10 h-10 rounded-full"
                    />
                    <div className="ml-2 font-bold text-sm">{name}</div>
                </div>
                <CommentForm
                    post={post}
                    className="pt-4 flex items-end"
                    minHeight={90}
                    onSuccess={(comment) => setCommentId(comment.id)}
                    autoFocus
                />
            </div>
            {post.recordingText !== '' && (
                <RecordMission
                    post={post}
                    users={missions?.recordingCompleted ?? []}
                />
            )}
            {post.loopingMissionCount > 0 && (
                <MissionProgress
                    missionType={MissionType.looping}
                    post={post}
                    users={missions?.loopingCompleted ?? []}
                />
            )}
            {post.assignmentText !== '' && (
                <AssignmentMission
                    post={post}
                    users={missions?.assignmentCompleted ?? []}
                />
            )}
            {comments && (
                <CommentsList
                    post={post}
                    allComments={comments}
                    setHighlightedCommentId={setCommentId}
                    highlightedCommentId={commentId}
                    scrollingElementRef={scrollingElementRef}
                />
            )}
            {isFetching && post.commentsCount > 0 && (
                <div className="flex-center mb-5">
                    <Spin className="w-7 h-7 text-blue-primary animate-spin" />
                </div>
            )}
        </Modal>
    )
}
