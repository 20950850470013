import React from 'react'
import cn from 'classnames'
import { useQuery } from 'react-query'
import api from 'api'
import Loader from 'Shared/Loader'
import { Class } from 'Class/types'
import ClassItem from 'Class/Management/ClassItem'

type Props = {
    selectedClass: Class | undefined
    setClass: (arg0: Class) => void
}

export default function Sidebar({
    className,
    selectedClass,
    setClass,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
    const { data, isLoading } = useQuery(['class'], () => api.classes.list())
    const classes = data?.owning.concat(data?.joined)

    React.useEffect(() => {
        if (!classes) {
            return
        }
        if (selectedClass) {
            return
        }
        if (classes.length < 1) {
            return
        }

        setClass(classes[0])
    }, [selectedClass, setClass, classes?.length])

    return (
        <div
            className={cn('py-8 px-7 flex flex-col', className)}
            style={{ maxWidth: '420px', minWidth: '420px' }}
        >
            <span className="text-lg font-bold text-gray-6b">MY CLASSES</span>
            <div
                className={cn(
                    'flex flex-col flex-grow h-0 overflow-y-scroll py-2',
                    isLoading && 'items-center pt-5',
                )}
            >
                {isLoading && (
                    <Loader
                        className="w-20 h-20 rounded text-blue-primary blue-primary"
                        color="#06ACEE"
                    />
                )}
                {!isLoading &&
                    classes &&
                    classes.map((c) => (
                        <ClassItem
                            item={c}
                            setClass={setClass}
                            key={c.id}
                            className={
                                c.id === selectedClass?.id
                                    ? 'bg-gray-c5'
                                    : undefined
                            }
                        />
                    ))}
            </div>
            <span className="text-sm text-gray-a4 font-extrabold mt-2">
                Upload your posts with a few clicks here
                <br />
                SCHOOOL.ME
            </span>
        </div>
    )
}
