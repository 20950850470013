import React, { useState } from 'react'
import { ArrowLeft } from '@styled-icons/fa-solid/ArrowLeft'
import Loader from 'Shared/Loader'
import api from 'api'
import Modal from 'Shared/Modal'
import { UndoAlt } from '@styled-icons/fa-solid/UndoAlt'
import HighlightCorrection from 'utils/HighlightCorrection'
import { updateCache } from 'Post/cacheActions'

type Props = {
    text: string
    title?: string
    postId: number
    commentsCount: number
    onClose(): void
}

export default function CorrectionModal({
    title = 'Correct Mistakes',
    onClose,
    postId,
    commentsCount,
    text,
}: Props) {
    const [error, setError] = useState<Error | undefined>()
    const [isLoading, setLoading] = useState(false)
    const [correctionText, setCorrectionText] = useState('')
    const [range, setRange] = useState('')

    const submit = async (e: any) => {
        e.preventDefault()
        if (isLoading) return
        setLoading(true)
        try {
            const formatedCorrectionText = `\n\nORIGINAL\n${text}\n\nCORRECTION\n${correctionText}`
            await api.post.addCorrection({
                text: formatedCorrectionText,
                postId: postId,
                correction_ranges: range,
            })
            updateCache(postId, { commentsCount: commentsCount + 1 })
            onClose()
            setError(undefined)
        } catch (err) {
            setLoading(false)
            setError(err as Error)
        }
    }
    const handleOnMouseUp = () => {
        const selection = window.getSelection()?.toString()
        if (selection && selection.length > 2 && selection !== text) {
            const selectedTexts = range.split('|')
            const startIndex = text.replace(/\r?\n|\r/g, ' ').indexOf(selection)

            if (startIndex !== -1) {
                if (range) {
                    let overlap = false
                    range.split('|').forEach((r) => {
                        const sellectedStartIndex = parseInt(r.split(',')[0])
                        const sellectedLength = parseInt(r.split(',')[1])
                        if (
                            (startIndex > sellectedStartIndex &&
                                startIndex <
                                    sellectedStartIndex + sellectedLength) ||
                            (startIndex + selection.length >
                                sellectedStartIndex &&
                                startIndex + selection.length <
                                    sellectedStartIndex + sellectedLength) ||
                            (startIndex < sellectedStartIndex &&
                                startIndex + selection.length >
                                    sellectedStartIndex + sellectedLength)
                        ) {
                            overlap = true
                        }
                    })

                    if (!overlap) {
                        const newRangeStr = `${range}|${startIndex},${selection.length}`

                        const array = newRangeStr
                            .split('|')
                            .sort((a, b) => {
                                if (
                                    parseInt(a.split(',')[0]) >
                                    parseInt(b.split(',')[0])
                                ) {
                                    return 1
                                }
                                if (
                                    parseInt(b.split(',')[0]) >
                                    parseInt(a.split(',')[0])
                                ) {
                                    return -1
                                }
                                return 0
                            })
                            .join('|')
                        setRange(array) // setting range for correction_ranges
                    }
                } else {
                    setRange(`${startIndex},${selection.length}`) // setting range for correction_ranges
                }
            }
        }
    }
    const clearSelection = () => {
        if (range.split('|').length > 1) {
            const array = range.split('|')
            array.pop()
            setRange(array.join('|')) // setting range for correction_ranges
        } else {
            setRange('')
        }
    }
    return (
        <Modal onClose={onClose} size="medium" className="p-6 py-4">
            <form
                onSubmit={submit}
                className="flex flex-col justify-center items-center relative"
            >
                <button
                    type="button"
                    className="absolute top-0 left-0 text-gray-5f mt-6"
                    onClick={onClose}
                >
                    <ArrowLeft size={26} />
                </button>
                <div className={'text-xl text-center uppercase mt-6 mb-4'}>
                    {title}
                </div>
                <div className="text-blue-primary mb-4 mt-2 text-15 self-start">
                    Use this feature to correct other member’s sentence
                    mistakes.
                </div>
                {error && (
                    <div className="text-red-500 text-center mb-2">
                        {error.message}
                    </div>
                )}
                <div className="w-full">
                    <div className="text-sm text-gray-6b flex items-center justify-between uppercase text-17">
                        1. Highlight The Words that you want to correct. If you
                        drag the text with mouse, they're automatically
                        highlighted in red.
                    </div>
                    {/* <div className="my-4 text-sm text-gray-6b">
            {sellectedText && `Sellected text: "${sellectedText}"`}
          </div> */}
                    <div
                        className="text-15 resize-none bg-gray-ef placeholder-gray-97 py-2 px-3 w-full rounded mb-4 relative pb-8"
                        onMouseUp={handleOnMouseUp}
                    >
                        {range ? (
                            <HighlightCorrection
                                correctionRanges={range.split('|')}
                                text={text}
                            />
                        ) : (
                            // <span>
                            //   {text.substr(0, startIndex)}
                            //   <span className="text-red-5a">
                            //     {text.substr(startIndex, sellectedText.length)}
                            //   </span>
                            //   {text.substr(
                            //     startIndex + sellectedText.length,
                            //     text.length - 1,
                            //   )}
                            // </span>
                            <span>{text}</span>
                        )}
                        <UndoAlt
                            size={18}
                            onClick={clearSelection}
                            className="absolute cursor-pointer bottom-0 right-0 mr-3 mb-2 text-gray-45"
                        />
                    </div>
                    <div className="h-6 text-sm text-gray-6b flex items-center justify-between uppercase text-17 mb-1">
                        2. Enter your correction
                    </div>
                    <textarea
                        name="correction"
                        placeholder="Enter your corrections here."
                        value={correctionText}
                        onChange={(e) => setCorrectionText(e.target.value)}
                        required={true}
                        className="resize-none bg-gray-ef placeholder-gray-97 py-2 px-3 w-full rounded"
                    />
                    <div className={'flex-center my-12'}>
                        <button
                            className="primary rounded-full bg-blue-primary text-white h-8 px-7 font-bold cursor-pointer flex-center"
                            disabled={isLoading}
                        >
                            {isLoading && <Loader className="w-5 h-5" />}
                            {!isLoading && 'Post Correction'}
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}
