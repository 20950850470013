import React from 'react'
import studyfrom from 'assets/images/icons/studyflow.svg'

export default function Placeholder() {
    return (
        <div className="flex-grow flex-center flex-col mx-16 mb-16">
            <div
                style={{ width: '60px', height: '60px' }}
                className="bg-mustard-primary flex-center rounded-full mb-3"
            >
                <img
                    src={studyfrom}
                    alt="studyflow"
                    style={{ width: '60px', height: '60px' }}
                />
            </div>
            <div className="text-black mb-3 text-center font-thin">
                You can repeat and practice the expressions you keep here on the
                SCHOOOL app.
            </div>
            <div className="flex-center">
                <a
                    href="https://apps.apple.com/app/schoool-english-korean/id976878105"
                    target="_blank"
                    className="text-sm font-extrabold"
                >
                    iOS Download
                </a>
                <a
                    href="https://play.google.com/store/apps/details?id=com.onthego.onthego"
                    target="_blank"
                    className="text-sm font-extrabold ml-3"
                >
                    Android Download
                </a>
            </div>
        </div>
    )
}
