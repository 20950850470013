import React from 'react'
import { NavLink } from 'react-router-dom'
import routes from 'routes'
import { useCurrentUser } from 'User/currentUser'
import useToggle from 'utils/useToggle'
import Modal from 'Shared/Modal'
import cn from 'classnames'
import style from './style.module.css'
import { logout } from 'User/api'
import { useQuery } from 'react-query'
import api from 'api'
import { paymentAccess } from './api'

type Props = {
    children: React.ReactNode
}

export default function SettingsLayout({ children }: Props) {
    const [openSignOut, toggleSignOut] = useToggle()
    const [currentUser, setCurrentUser] = useCurrentUser()
    const [paymentAllowed, setPaymentAllowed] = React.useState(false)

    const { data: groups } = useQuery(['organizationGroup'], () =>
        api.classManagement.groups(),
    )

    const onSignOutClick = async () => {
        localStorage.removeItem('FIREBASE_TOKEN')
        logout().then()
        setCurrentUser(null)
    }

    React.useEffect(() => {
        async function run() {
            try {
                await paymentAccess()
                setPaymentAllowed(true)
            } catch (e) {
                setPaymentAllowed(false)
            }
        }
        run().then()
    }, [])

    return (
        <>
            {openSignOut && (
                <Modal onClose={toggleSignOut} className="text-center">
                    <div className="mt-8 text-xl font-bold">Sign Out</div>
                    <div className="text-17 mt-2 mb-6">
                        Are you sure to sign out?
                    </div>
                    <hr className="text-gray-bb" />
                    <div className="flex-center my-5">
                        <button
                            className="text-gray-4f h-7 font-bold w-24"
                            onClick={toggleSignOut}
                        >
                            Cancel
                        </button>
                        <button
                            className="rounded-full bg-red-58 text-white h-7 font-bold ml-3 flex-center w-24"
                            onClick={onSignOutClick}
                        >
                            Sign Out
                        </button>
                    </div>
                </Modal>
            )}
            <div
                className="bg-white w-full mx-auto mt-8 flex shadow"
                style={{ maxWidth: '960px' }}
            >
                <div
                    style={{ width: '320px' }}
                    className={cn(
                        'text-lg text-black border-r border-gray-c5 py-8 px-8',
                        style.hideOnSmallScreen,
                    )}
                >
                    <NavLink
                        to={routes.settings.profile()}
                        className="block"
                        activeClassName="text-blue-primary"
                    >
                        My Profile
                    </NavLink>
                    <NavLink
                        to={routes.settings.password()}
                        className="block mt-4"
                        activeClassName="text-blue-primary"
                    >
                        Password
                    </NavLink>
                    <NavLink
                        to={routes.settings.termsOfUse()}
                        className="block mt-4"
                        activeClassName="text-blue-primary"
                    >
                        Terms Of Use
                    </NavLink>
                    <NavLink
                        to={routes.settings.privacyPolicy()}
                        className="block mt-4"
                        activeClassName="text-blue-primary"
                    >
                        Privacy Policy
                    </NavLink>
                    {paymentAllowed && (
                        <NavLink
                            to={routes.settings.payment()}
                            className="block mt-4"
                            activeClassName="text-blue-primary"
                        >
                            Payments
                        </NavLink>
                    )}
                    {groups && groups.length > 0 && (
                        <NavLink
                            to={routes.class.management()}
                            className="block mt-4"
                        >
                            Post Management
                        </NavLink>
                    )}
                    <button className="block mt-4" onClick={toggleSignOut}>
                        Sign Out
                    </button>
                </div>
                <div className="flex-grow py-8 px-10">{children}</div>
            </div>
        </>
    )
}
