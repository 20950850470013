import React from 'react'
import creditCard from 'assets/images/creditcard.svg'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useCurrentUser } from 'User/currentUser'
import { paymentInfo } from '../api'
import routes from '../../routes'
import Loader from '../../Shared/Loader'

export default function Form() {
    const stripe = useStripe()
    const elements = useElements()
    const [currentUser] = useCurrentUser()
    const [email, setEmail] = React.useState(currentUser.email)
    const [username, setUsername] = React.useState(currentUser.name)
    const [posting, setPosting] = React.useState(false)

    const processPayment = async (event: React.FormEvent) => {
        event.preventDefault()

        if (!stripe || !elements) {
            return
        }

        setPosting(true)

        await paymentInfo({
            email,
            username,
        })

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url:
                    window.location.origin + routes.settings.paymentSucceed(),
            },
        })
        setPosting(false)
        alert(result.error.message)
    }

    return (
        <form
            className="flex flex-row h-full bg-white font-notoSans"
            onSubmit={processPayment}
        >
            <div className="flex flex-col w-7/12 px-5 py-15 bg-white">
                <div className="flex flex-col h-0 w-full px-2 flex-grow overflow-x-hidden overflow-scroll">
                    <div className="flex flex-row">
                        <img src={creditCard} />
                        <span className="font-bold text-3xl ml-4">Payment</span>
                    </div>
                    <div className="mt-2.5 w-full h-px bg-gray-ae" />
                    <span className="mt-8 font-semibold text-lg">
                        SCHOOOL 클래스 수강 등록을 진행합니다. SCHOOOL
                        홈페이지에 로그인한 상태에서 이 결제 페이지로
                        들어오세요.
                    </span>
                    <span className="text-black mt-6 font-mulish">
                        SCHOOOL Account Email
                    </span>
                    <input
                        className="border mt-1 text-base px-2"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <span className="text-black mt-2.5">SCHOOOL Name</span>
                    <input
                        className="border mt-1 text-base px-2"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <span className="text-xl font-bold mt-9">Payment Card</span>
                    <span className="font-medium text-base text-gray-6e">
                        All transactions are secure and encrypted
                    </span>
                    <PaymentElement className="mt-4" />
                    <div className="mt-7 w-full h-px bg-gray-ae" />
                    <span className="text-center text-lg font-semibold mx-3 mt-5">
                        결제가 완료되면 환불이 불가능합니다. 이 점에 동의하고
                        결제를 진행하시려면 아래 ‘결제하기’ 버튼을 눌러 주세요
                    </span>
                    <div className="mt-9 flex flex-row justify-center">
                        {!posting && (
                            <button
                                className="w-40 h-10 bg-blue-0089c0 rounded text-white"
                                type="submit"
                            >
                                결제하기
                            </button>
                        )}
                        {posting && (
                            <div className="w-40 h-10 bg-blue-0089c0 rounded flex items-center justify-center">
                                <Loader className="w-5 h-5" color="white" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="w-5/12 bg-gray-f7">
                <div className="flex flex-row mt-19 justify-center items-center">
                    <div className="flex flex-col items-center">
                        <span className="font-bold text-lg">
                            말킴의 응용 500+ 클래스
                        </span>
                        <span className="text-sm text-gray-5b">
                            Tuition for one whole semester
                        </span>
                    </div>
                    <span className="font-bold text-lg text-gray-45 ml-12">
                        USD $195
                    </span>
                </div>
                <div className="mt-7 mx-12 h-px bg-gray-ae" />
                <div className="mx-12 flex flex-row mt-5 justify-between">
                    <span className="font-medium text-gray-45">Subtotal</span>
                    <span className="font-bold text-gray-45">USD $195</span>
                </div>
                <div className="mx-12 flex flex-row mt-2.5 justify-between">
                    <span className="font-medium text-gray-45">Taxes</span>
                    <span className="font-bold text-gray-45">USD $0</span>
                </div>
                <div className="mt-8 mx-12 h-px bg-gray-ae" />
                <div className="mx-12 flex flex-row mt-5 justify-between items-center">
                    <span className="font-medium text-gray-45">Total</span>
                    <span className="font-bold text-gray-45">
                        USD <span className="font-bold text-2xl">$195</span>
                    </span>
                </div>
            </div>
        </form>
    )
}
