import { Post } from 'Post/types'
import React from 'react'
import studyflowimage from 'assets/images/studyflow.png'
import Alert from 'Shared/Modal/Alert'
import useToggle from 'utils/useToggle'

export default function StudyFlowBanner({ post }: { post: Post }) {
    const [showAlert, toggleAlert] = useToggle()
    return (
        <>
            {showAlert && (
                <Alert
                    title=""
                    text="Please use the SCHOOOL App for this feature."
                    onClose={toggleAlert}
                />
            )}
            <div
                className="flex justify-start bg-gray-f7 text-white p-4 cursor-pointer"
                onClick={() => toggleAlert()}
            >
                <div>
                    <img
                        className="w-16 h-16 mr-4 object-cover rounded"
                        src={studyflowimage}
                    />
                </div>
                <div className="flex flex-col flex-1 justify-start overflow-hidden">
                    <div className="text-black">{post.shared_flow_title}</div>
                    <div className="text-gray-87 text-xs mt-1">
                        {post.shared_flow_username}
                    </div>
                </div>
            </div>
        </>
    )
}
