import { queryClient, updateData } from 'utils/queryClient'
import { Organization, OrganizationPostGroup } from 'Class/Management/types'
import { OrganizationMember } from './types'
import { InfiniteData } from 'react-query'

export const addOrganization = (organization: Organization) => {
    updateData<Organization[]>(['adminOrganizations'], (data) =>
        data.concat([organization]),
    )
}

export const addAccess = (
    organizationId: number,
    group: OrganizationPostGroup,
) => {
    updateData<OrganizationPostGroup[]>(
        ['adminGroupAccess', organizationId],
        (data) => data.concat([group]),
    )
}

export const removeAccess = (organizationId: number, groupId: number) => {
    updateData<OrganizationPostGroup[]>(
        ['adminGroupAccess', organizationId],
        (data) => data.filter((g) => g.id !== groupId),
    )
}

export const addUserToOrganization = (
    organizationId: number,
    user: OrganizationMember,
) => {
    updateData<OrganizationMember[]>(
        ['adminOrganizationMember', organizationId],
        (data) => data.concat([user]),
    )
    updateData<InfiniteData<OrganizationMember[]>>(
        ['adminOrganizationNonMember', organizationId],
        (data) => ({
            pages: data.pages.map((page) =>
                page.filter((m) => m.id !== user.id),
            ),
            pageParams: data.pageParams,
        }),
    )
}

export const removeUserFromOrganization = (
    organizationId: number,
    userId: number,
) => {
    updateData<OrganizationMember[]>(
        ['adminOrganizationMember', organizationId],
        (data) => data.filter((d) => d.id !== userId),
    )
}
