import React from 'react'
import logo from 'assets/images/logo.svg'

export default function Unauthorized() {
    return (
        <div className="w-full h-full flex flex-col">
            <div className="w-full h-full flex flex-col justify-center items-center my-9 bg-white rounded shadow-lg">
                <img style={{ width: '50px' }} src={logo} alt="logo" />
                <span className="mt-5 font-bold text-gray-6b">
                    You are not authorized to enter this page.
                </span>
            </div>
        </div>
    )
}
