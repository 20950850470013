import React from 'react'
import cn from 'classnames'
import { useQuery } from 'react-query'
import api from 'api'
import Loader from 'Shared/Loader'
import { Organization } from 'Class/Management/types'
import Modal from 'Shared/Modal'
import AddOrganizationForm from "./AddOrganizationForm";

type Props = {
    selectedOrganization?: Organization
    setSelectedOrganization: (arg0: Organization) => void
}

export default function Sidebar({
    className,
    selectedOrganization,
    setSelectedOrganization,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
    const { data: organizations, isLoading } = useQuery(
        ['adminOrganizations'],
        () => api.admin.organizations(),
    )
    const [isShowingAdd, setIsShowingAdd] = React.useState(false)

    const onAddClick = React.useCallback(() => {
        setIsShowingAdd(true)
    }, [setIsShowingAdd])
    const onCancelClick = React.useCallback(() => {
        setIsShowingAdd(false)
    }, [setIsShowingAdd])
    const onAddCompleted = React.useCallback(() => {
        setIsShowingAdd(false)
    }, [setIsShowingAdd])

    return (
        <div
            className={cn('p-7 flex flex-col', className)}
            style={{ maxWidth: '420px', minWidth: '420px' }}
        >
            <div className="flex flex-row items-center">
                <span className="text-lg font-semibold flex-1">
                    Class Management Access
                </span>
                <span
                    className="text-2xl font-semibold cursor-pointer"
                    onClick={onAddClick}
                >
                    +
                </span>
            </div>
            <div
                className={cn(
                    'flex flex-col flex-grow h-0 overflow-y-scroll py-2',
                    isLoading && 'items-center pt-5',
                )}
            >
                {isLoading && (
                    <Loader
                        className="w-20 h-20 rounded text-blue-primary blue-primary"
                        color="#06ACEE"
                    />
                )}
                {organizations &&
                    organizations.map((o) => (
                        <span
                            key={o.id}
                            className={cn(
                                'py-2 px-1 text-lg cursor-pointer rounded',
                                o.id === selectedOrganization?.id &&
                                    'bg-gray-ef font-medium',
                            )}
                            onClick={() => setSelectedOrganization(o)}
                        >
                            {o.name}
                        </span>
                    ))}
            </div>
            {isShowingAdd && (
                <Modal size="large">
                    <AddOrganizationForm
                        onAddCompleted={onAddCompleted}
                        onCancel={onCancelClick}
                    />
                </Modal>
            )}
        </div>
    )
}
