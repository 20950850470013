import React, { useState } from 'react'
import { useMutation } from 'react-query'
import ActionButton from './ActionButton'

import api from 'api'
import { removeFromCache } from 'Post/Comment/cacheActions'

type Props = {
    commentId: number
    postId: number
    setOpenMenu(arg: boolean): void
}

const Delete = ({ commentId, postId, setOpenMenu }: Props) => {
    const [error, setError] = useState<string>()

    const { mutate, isLoading } = useMutation(api.classes.deleteComment, {
        onSettled(response: any, error) {
            if (error) {
                setError("Couldn't delete")
            } else if (response) {
                setError('')
                setOpenMenu(false)
                removeFromCache(commentId, postId)
            }
        },
    })

    const deleteComment = () => {
        mutate({
            reply_id: commentId,
        })
    }

    return (
        <ActionButton onClick={deleteComment} loading={isLoading} isRed>
            Delete
            {error}
        </ActionButton>
    )
}

export default Delete
