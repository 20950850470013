import React from 'react'
import ClassLayout from 'Class/Layout'
import logo from 'assets/images/logo.svg'
import { Link, useRouteMatch } from 'react-router-dom'
import Posts from 'Post/List'
import { useMutation, useQuery } from 'react-query'
import api from 'api'
import Spin from 'assets/images/icons/Spin'
import routes from 'routes'
import JoinedClassActions from 'Class/Page/JoinedClassActions'
import ClassActions from 'Class/Page/ClassActions'
import Tabs from 'Shared/Tabs'

export default function ClassPage() {
    const {
        params: { id: stringId },
        path,
    } = useRouteMatch<{ id: string }>()
    const id = parseInt(stringId)
    const allPosts = path === routes.class.classDetail()
    const scrollRef = React.useRef<HTMLDivElement>(null)

    const { data: item, isLoading } = useQuery(['class', id], () =>
        api.classes.getClass({ id }),
    )

    const { mutate: getUser, data: user } = useMutation(api.user.getUser)

    React.useEffect(() => {
        if (item) {
            getUser({ id: item.owner.id })
        }
    }, [item?.owner.id])

    return (
        <ClassLayout scrollRef={scrollRef}>
            <div className="bg-white shadow mb-5 pt-16">
                {isLoading && (
                    <div className="flex-center pb-16">
                        <Spin className="w-10 h-10 text-blue-primary animate-spin" />
                    </div>
                )}
                {!isLoading && item && (
                    <>
                        <div className="px-16">
                            <div className="flex-center mb-4">
                                <Link to={routes.user(item.owner.id)}>
                                    <div
                                        className="bg-center bg-cover rounded-full"
                                        style={{
                                            width: '70px',
                                            height: '70px',
                                            backgroundImage: `url("${
                                                user ? user.avatar : logo
                                            }")`,
                                        }}
                                    />
                                </Link>
                                <div
                                    className="mx-10 rounded-full flex-shrink-0 block bg-center bg-cover"
                                    style={{
                                        width: '240px',
                                        height: '240px',
                                        backgroundImage: `url("${item.image}")`,
                                    }}
                                />
                                <div style={{ width: '100px' }}>
                                    <div className="border border-black rounded-full flex-center h-6 text-sm text-black w-full">
                                        {item.isPublic ? 'Public' : 'Private'}
                                    </div>
                                    {item.isLocked && (
                                        <div className="border border-red-50 text-red-50 rounded-full flex-center h-6 text-sm w-full mt-2">
                                            Join Blocked
                                        </div>
                                    )}
                                    {item.completed && (
                                        <div className="border border-blue-0089c0 text-blue-0089c0 rounded-full flex-center h-6 text-sm w-full mt-2">
                                            Completed
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className="text-3xl font-bold text-black flex-center"
                                style={{ minHeight: '38px' }}
                            >
                                {item.name}
                            </div>
                            <Link
                                to={routes.user(item.owner.id)}
                                className="text-blue-primary text-lg flex-center h-6 mb-4 hover:underline"
                            >
                                Created by {item.owner.name}
                            </Link>
                            <div className="text-black text-lg mb-4">
                                {item.description}
                            </div>
                        </div>
                        {(item.isOwn || item.isJoined) && (
                            <JoinedClassActions item={item} />
                        )}
                        {!(item.isOwn || item.isJoined) && (
                            <ClassActions item={item} />
                        )}
                    </>
                )}
            </div>
            <Tabs
                tabs={[
                    {
                        label: 'All posts',
                        value: 'all',
                        link: routes.class.classDetail(id),
                    },
                    {
                        label: 'Saved posts',
                        value: 'saved',
                        link: routes.class.classSavedPosts(id),
                    },
                ]}
                activeTab={allPosts ? 'all' : 'saved'}
            />
            <Posts classId={id} savedPosts={!allPosts} scrollRef={scrollRef} />
        </ClassLayout>
    )
}
