import React from 'react'
import cn from 'classnames'

type Props = {
    video: string
    className?: string
}

export default function Video({ video, className }: Props) {
    return (
        <video controls className={cn('w-full', className)} key={video}>
            <source src={video} type="video/mp4"></source>
            Your browser does not support the video tag.
        </video>
    )
}
