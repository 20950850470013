import React from 'react'
import { UseFormMethods, Controller, useFieldArray } from 'react-hook-form'
import cn from 'classnames'
import { StudyFlowType } from 'Studyflow/types'
import { ArrowDown } from '@styled-icons/bootstrap/ArrowDown'
import { useSelectLanguage } from 'utils/learningLanguage'
type Props = {
    type: StudyFlowType
    // eslint-disable-next-line
    form: UseFormMethods<any>
}

const inputClass = 'bg-gray-e2 px-3 py-2 w-full mb-1'
const inputNativeClass = 'bg-gray-f7 px-3 py-2 w-full mb-1'

const englishConversationFields = [
    {
        name: 'question',
        className: inputClass,
        placeholder: 'Question in English',
        hidden: '',
    },
    {
        name: 'questionNative',
        className: inputClass,
        placeholder: 'Question in your native language',
        hidden: '',
    },
    {
        name: 'answer',
        className: inputNativeClass,
        placeholder: 'Answer in English',
        hidden: '',
    },
    {
        name: 'answerNative',
        className: inputNativeClass,
        placeholder: 'Answer in your native language',
        hidden: '',
    },
    {
        name: 'eId',
        className: '',
        placeholder: '',
        hidden: 'none',
    },
]
const koreanConversationFields = [
    {
        name: 'question',
        className: inputClass,
        placeholder: 'Question in Korean',
        hidden: '',
    },
    {
        name: 'questionNative',
        className: inputClass,
        placeholder: 'Question in your native language',
        hidden: '',
    },
    {
        name: 'answer',
        className: inputNativeClass,
        placeholder: 'Answer in Korean',
    },
    {
        name: 'answerNative',
        className: inputNativeClass,
        placeholder: 'Answer in your native language',
        hidden: '',
    },
    {
        name: 'eId',
        className: '',
        placeholder: '',
        hidden: 'none',
    },
]

const englishRepetitionFields = [
    {
        name: 'sentence',
        className: inputClass,
        placeholder: 'Sentence in English',
        hidden: '',
    },
    {
        name: 'sentenceNative',
        className: inputNativeClass,
        placeholder: 'Sentence in your native language',
        hidden: '',
    },
    {
        name: 'eId',
        className: '',
        placeholder: '',
        hidden: 'none',
    },
]
const koreanRepetitionFields = [
    {
        name: 'sentence',
        className: inputClass,
        placeholder: 'Sentence in Korean',
    },
    {
        name: 'sentenceNative',
        className: inputNativeClass,
        placeholder: 'Sentence in your native language',
    },
    {
        name: 'eId',
        className: '',
        placeholder: '',
        hidden: 'none',
    },
]

export default function Expressions({ type, form }: Props) {
    const { errors, control } = form
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'expressions',
    })
    const fieldsLen = fields.length

    const [conversationFields, repetitionFields] = useSelectLanguage({
        korean: [koreanConversationFields, koreanRepetitionFields],
        english: [englishConversationFields, englishRepetitionFields],
    })

    const fieldsDef =
        type === 'conversation' ? conversationFields : repetitionFields

    return (
        <>
            {fieldsLen <= 1 && (
                <div className="pt-7 px-4 pb-2 text-blue-9e text-17">
                    Add Expressions
                </div>
            )}

            {fields.map((field, index) => {
                const isLast = index === fieldsLen - 1

                return (
                    <div key={field.id}>
                        {fieldsLen > 1 && (
                            <div
                                className={`flex items-baseline ${
                                    index === 0
                                        ? 'justify-between'
                                        : 'justify-end'
                                }`}
                            >
                                {index === 0 && (
                                    <div className="ml-4 mb-2 mt-8 text-blue-9e text-17">
                                        Add Expressions
                                    </div>
                                )}
                                <button
                                    type="button"
                                    className="border border-gray-35 text-gray-35 flex-center rounded-full mr-2 mb-2"
                                    style={{
                                        width: '90px',
                                        borderWidth: '1px',
                                    }}
                                    onClick={() => remove(index)}
                                >
                                    Delete
                                    <ArrowDown size={13} className="ml-1" />
                                </button>
                            </div>
                        )}
                        {fieldsDef.map(
                            ({ name, className, placeholder, hidden }, i) => {
                                return (
                                    <Controller
                                        key={`field.id${i}`}
                                        control={control}
                                        name={`expressions[${index}].${name}`}
                                        render={({
                                            value,
                                            name,
                                            onChange,
                                            onBlur,
                                        }) => {
                                            const error = errors[name]

                                            return (
                                                <div>
                                                    <input
                                                        className={className}
                                                        name={name}
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        style={{
                                                            display: hidden,
                                                        }}
                                                        placeholder={
                                                            placeholder
                                                        }
                                                    />
                                                    {error && (
                                                        <div className="text-red-600 mb-2 px-4">
                                                            {error.message}
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        }}
                                    />
                                )
                            },
                        )}
                        <div
                            className={cn(
                                'flex items-center px-4',
                                isLast
                                    ? 'justify-between h-16'
                                    : 'justify-end h-8',
                            )}
                        >
                            {isLast && (
                                <button
                                    type="button"
                                    className="bg-gray-35 text-white flex-center rounded-full"
                                    style={{ width: '95px' }}
                                    onClick={() =>
                                        append({
                                            question: '',
                                            questionNative: '',
                                            answer: '',
                                            answerNative: '',
                                        })
                                    }
                                >
                                    Add More
                                </button>
                            )}
                        </div>
                    </div>
                )
            })}
        </>
    )
}
