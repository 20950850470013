import React, { useState, useEffect, useRef } from 'react'
import { Play } from '@styled-icons/boxicons-regular/Play'
import { Pause } from '@styled-icons/boxicons-regular/Pause'
const AudioPlayer = ({ tracks, startAudio, isPlaying, setIsPlaying }: any) => {
    // State
    const repeat = 1
    const [trackIndex, setTrackIndex] = useState(0)
    const [trackProgress, setTrackProgress] = useState(0)
    const [playTimes, setPlayTimes] = useState(0)
    // Destructure for conciseness
    const audioSrc = tracks[trackIndex]

    // Refs
    const audioRef = useRef(new Audio(audioSrc))
    const intervalRef = useRef<any>()
    const isReady = useRef(false)

    // Destructure for conciseness
    const { duration } = audioRef.current

    const startTimer = () => {
        // Clear any timers already running
        clearInterval(intervalRef.current)

        intervalRef.current = setInterval(() => {
            if (audioRef.current.ended) {
                toNextTrack()
            } else {
                setTrackProgress(audioRef.current.currentTime)
            }
        }, 1000) // Time it takes to start new audio 1000 = 1 second
    }

    // Handle setup when changing tracks
    useEffect(() => {
        audioRef.current.pause()

        audioRef.current = new Audio(audioSrc)
        setTrackProgress(audioRef.current.currentTime)

        if (isReady.current) {
            audioRef.current.play()
            startTimer()
        } else {
            // Set the isReady ref as true for the next pass
            isReady.current = true
        }
    }, [trackIndex])
    useEffect(() => {
        audioRef.current.pause()

        if (isReady.current) {
            audioRef.current.play()
            startTimer()
        } else {
            // Set the isReady ref as true for the next pass
            isReady.current = true
        }
    }, [playTimes])

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play()
            startTimer()
        } else {
            audioRef.current.pause()
        }
    }, [isPlaying])

    useEffect(() => {
        // Pause and clean up on unmount
        return () => {
            audioRef.current.pause()
            clearInterval(intervalRef.current)
        }
    }, [])

    const toPrevTrack = () => {
        if (trackIndex - 1 < 0) {
            setTrackIndex(tracks.length - 1)
        } else {
            setTrackIndex(trackIndex - 1)
        }
    }

    const toNextTrack = () => {
        if (playTimes + 1 < repeat) {
            setPlayTimes(playTimes + 1)
        } else if (trackIndex < tracks.length - 1) {
            setTrackIndex(trackIndex + 1)
            setPlayTimes(0)
        } else {
            setTrackIndex(0)
            setPlayTimes(0)
        }
    }
    const start = () => {
        if (!isPlaying) {
            // Start Player
            setIsPlaying(true)
            startAudio()
        } else {
            // Pause player
            setIsPlaying(false)
            clearInterval(intervalRef.current)
            setTrackIndex(0)
            setPlayTimes(0)
        }
    }
    return (
        <button type="button" onClick={() => start()}>
            {!isPlaying ? (
                <Play className="mr-1 text-gray-2a" size={28} />
            ) : (
                <Pause className="mr-1 text-gray-2a" size={28} />
            )}
        </button>
    )
}

export default AudioPlayer
