import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import history from 'utils/history'
import Routes from 'App/Router'
import './styles.css'
import './style.css'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import smoothscroll from 'smoothscroll-polyfill'
import DragNDropWrapper from 'Shared/DragNDropWrapper'
import { QueryClientProvider } from 'react-query'
import { queryClient } from 'utils/queryClient'
import { LearningLanguageProvider } from 'utils/learningLanguage'

dayjs.extend(utc)
dayjs.extend(isToday)
dayjs.extend(isYesterday)
smoothscroll.polyfill()

ReactDOM.render(
    <React.StrictMode>
        <DragNDropWrapper>
            <Router history={history}>
                <QueryClientProvider client={queryClient}>
                    <LearningLanguageProvider>
                        <Routes />
                    </LearningLanguageProvider>
                </QueryClientProvider>
            </Router>
        </DragNDropWrapper>
    </React.StrictMode>,
    document.getElementById('root'),
)
