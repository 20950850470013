import React from 'react'
import ClassFormModal from 'Class/Form/Modal'
import State from 'Class/State'
import { observer } from 'mobx-react-lite'
import ClassSidebar from 'Class/Sidebar'

export default observer(function ClassLayout({
    children,
    scrollRef,
}: {
    children: React.ReactNode
    scrollRef?: React.RefObject<HTMLDivElement>
}) {
    return (
        <>
            {State.openCreateModal && (
                <ClassFormModal onClose={() => State.toggleCreateModal()} />
            )}
            <div className="flex h-full relative">
                <ClassSidebar />
                <div
                    ref={scrollRef}
                    className="mt-8 w-full absolute right-0 top-0 bottom-0 overflow-scroll flex flex-col"
                    style={{ maxWidth: '640px' }}
                >
                    {children}
                </div>
            </div>
        </>
    )
})
