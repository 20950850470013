import React from 'react'
import cn from 'classnames'
import style from './style.module.css'

type Props = {
    className?: string
    color?: string
}

export default function Loader({ className, color }: Props) {
    return (
        <div className={cn(className, style.loader)}>
            <div
                style={{
                    borderColor: color
                        ? `${color} transparent transparent transparent`
                        : 'white transparent transparent transparent',
                }}
            />
            <div
                style={{
                    borderColor: color
                        ? `${color} transparent transparent transparent`
                        : 'white transparent transparent transparent',
                }}
            />
            <div
                style={{
                    borderColor: color
                        ? `${color} transparent transparent transparent`
                        : 'white transparent transparent transparent',
                }}
            />
            <div
                style={{
                    borderColor: color
                        ? `${color} transparent transparent transparent`
                        : 'white transparent transparent transparent',
                }}
            />
        </div>
    )
}
