import React from 'react'
import { Link } from 'react-router-dom'
import routes from 'routes'
import { Class } from 'Class/types'
import cn from 'classnames'

type Props = {
    item: Class
    setClass: (arg0: Class) => void
}

export default function ClassItem({
    item,
    setClass,
    className,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div
            className={cn(
                'flex items-center py-2 px-1 rounded cursor-pointer',
                className,
            )}
            onClick={() => setClass(item)}
        >
            <img
                className="w-15 h-15 rounded-full mr-4 bg-center bg-cover flex-shrink-0"
                src={item.image}
            />
            <div className="flex justify-center flex-col">
                <span className="text-lg text-black font-bold mb-1 hover:underline truncate">
                    {item.name}
                </span>
                <Link
                    to={routes.user(item.owner.id)}
                    className="text-sm text-gray-97 font-bold hover:underline"
                >
                    {item.owner.name}
                </Link>
            </div>
        </div>
    )
}
