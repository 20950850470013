import * as user from 'User/api'
import * as post from 'Post/api'
import * as comment from 'Post/Comment/api'
import * as upload from 'Upload/api'
import * as classes from 'Class/api'
import * as classManagement from 'Class/Management/api'
import * as notebook from 'Notebook/api'
import * as studyFlow from 'Studyflow/api'
import * as notifications from 'App/Notifications/api'
import * as admin from 'Admin/api'
import * as app from 'App/api'

export default {
    admin,
    user,
    post,
    comment,
    upload,
    classes,
    classManagement,
    notebook,
    studyFlow,
    notifications,
    app,
}
