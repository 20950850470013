import { del, get, post, put } from 'utils/apiUtils'
import { getUserToken } from 'User/currentUser'
import {
    ConversationExpression,
    Expression,
    RepetitionExpression,
    StudyFlow,
    StudyFlowLevel,
    StudyFlowType,
    StudyFlowResponse,
    ExpressionResponse,
    StudyFlowForm,
} from 'Studyflow/types'

const levelToNumber: Record<StudyFlowLevel, number> = {
    basic: 0,
    intermediate: 1,
    advanced: 2,
}

const levelFromNumber: Record<number, StudyFlowLevel> = {
    0: 'basic',
    1: 'intermediate',
    2: 'advanced',
}
const levelValues = ['basic', 'intermediate', 'advanced']

const typeToNumber: Record<StudyFlowType, number> = {
    conversation: 0,
    repetition: 1,
}

const typeFromNumber: Record<number, StudyFlowType> = {
    0: 'conversation',
    1: 'repetition',
}

export const list = get(() => ({
    path: '/study_flow/list',
    params: {
        access_token: getUserToken(),
    },
    response(studyFlows: StudyFlowResponse[]): StudyFlow[] {
        return studyFlows.map((flow) => ({
            id: flow.id,
            title: flow.title,
            isPublic: Boolean(flow.is_public),
            type: typeFromNumber[flow.type],
            level: levelFromNumber[flow.level],
            shared_users:
                flow.shared_users
                    ?.split('|')
                    ?.map((user) => parseInt(user.split(',')[0])) ?? [],
            shared_classes:
                flow.shared_classes
                    ?.split('|')
                    ?.map((user) => parseInt(user.split(',')[0])) ?? [],
        }))
    },
}))

export const create = post(
    (
        flow: Omit<StudyFlow, 'id'> & {
            expressions: Expression[]
        },
    ) => ({
        path: '/study_flow',
        data: {
            access_token: getUserToken(),
            theme: 'Everyday Expression',
            description: flow.title,
            title: flow.title,
            level: levelToNumber[flow.level],
            is_public: flow.isPublic ? 1 : 0,
            type: typeToNumber[flow.type],
            shared_users: JSON.stringify(flow.shared_users),
            shared_classes: JSON.stringify(flow.shared_classes),
            profile_image_dir:
                'https://dzh6ulgfepbq.cloudfront.net/study_flow/photo/cover/2-1.png',
            expressions: JSON.stringify(
                flow.type === 'conversation'
                    ? (flow.expressions as ConversationExpression[]).map(
                          (expr) => ({
                              question: expr.question,
                              native: expr.questionNative,
                              answers: [
                                  {
                                      answer: expr.answer,
                                      native: expr.answerNative,
                                  },
                              ],
                          }),
                      )
                    : (flow.expressions as RepetitionExpression[]).map(
                          (expr) => ({
                              question: expr.sentence,
                              native: expr.sentenceNative,
                              answers: [],
                          }),
                      ),
            ),
        },
        response({
            result_code,
            data,
        }: {
            result_code: string
            data: { id: number }
        }): StudyFlow {
            if (result_code !== '01.00') throw new Error('Something went wrong')

            return {
                ...flow,
                id: data.id,
            }
        },
    }),
)

export const update = put((flow: StudyFlowForm) => ({
    path: `/study_flow/${flow.id}`,

    data: {
        access_token: getUserToken(),
        theme: 'Everyday Expression',
        description: flow.title,
        type: typeToNumber[flow.type],
        title: flow.title,
        level: levelToNumber[flow.level],
        is_public: flow.isPublic ? 1 : 0,
        shared_users: JSON.stringify(flow.userIds),
        shared_classes: JSON.stringify(flow.classIds),
        profile_image_dir:
            flow.profile_image ||
            'https://dzh6ulgfepbq.cloudfront.net/study_flow/photo/cover/2-1.png',
        expressions: JSON.stringify(
            flow.type === 'conversation'
                ? (flow.expressions as ConversationExpression[]).map(
                      (expr) => ({
                          id: expr.eId,
                          question: expr.question,
                          native: expr.questionNative,
                          answers: [
                              {
                                  answer: expr.answer,
                                  native: expr.answerNative,
                              },
                          ],
                      }),
                  )
                : (flow.expressions as RepetitionExpression[]).map((expr) => ({
                      id: expr.eId,
                      question: expr.sentence,
                      native: expr.sentenceNative,
                      answers: [],
                  })),
        ),
    },
    async response({ result_code }) {
        const lingos = await findLingo(flow.id)
        if (result_code !== '08.00') return { error: 'something happened' }
        return {
            ...flow,
            level: levelValues.indexOf(flow.level),
            is_public: flow.isPublic ? 1 : 0,
            expressions: lingos,
        }
    },
}))

export const remove = del(({ id }: { id: number }) => ({
    path: `/study_flow/${id}`,
    params: {
        access_token: getUserToken(),
    },
    response({ result_code }: { result_code: string }) {
        if (result_code !== '07.00') throw new Error('Something went wrong')
    },
}))

export const findOne = get(({ id }: { id: number }) => ({
    path: `/study_flow/${id}`,
    params: { access_token: getUserToken() },
    async response({
        result_code,
        data,
    }: {
        result_code: string
        data: StudyFlowResponse
    }) {
        const lingos = await findLingo(id)
        return { ...data, expressions: lingos }
    },
}))

export const findLingo = get((id?: number) => ({
    path: `/study_flow/${id}/sentence`,
    auth: true,
    response(data: ExpressionResponse[]) {
        return data
    },
}))

type SearchFriendsResponse = {
    user_id: number
    name: string
    profile_image_dir: string
    type: number
    priority: number
}

export const searchFriendsToShareWith = get(
    ({ search }: { search?: string } = {}) => ({
        path: '/study_flow/for_tag',
        params: {
            access_token: getUserToken(),
            limit_posts: 0,
            num_of_posts: 0,
            remove_self: 1,
            search_key: search,
        },
        response({
            result_code,
            data,
        }: {
            result_code: string
            data: SearchFriendsResponse[]
        }) {
            if (result_code !== '17.00') throw new Error('Something went wrong')

            return data
                .filter((user) => user.type === 1)
                .map((user) => ({
                    id: user.user_id,
                    name: user.name,
                    avatar: user.profile_image_dir,
                }))
        },
    }),
)
