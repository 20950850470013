import React from 'react'
import { observer } from 'mobx-react-lite'
import useToggle from 'utils/useToggle'
import api from 'api'
import { removeFromCache, updateCache } from 'Post/cacheActions'
import PostForm from 'Post/Form'
import DeleteModal from 'Shared/Modal/Delete'
import Dropdown from 'Shared/Dropdown'
import { Post } from 'Post/types'
import style from 'Post/Card/Menu/style.module.css'
import { removeScheduled } from '../../api'

const itemClass = `w-full flex-center transition duration-200 hover:bg-gray-f2 cursor-pointer ${style.menuItem}`

type Props = {
    post: Post
    button: (params: { onClick(): void }) => React.ReactNode
    className?: string
}

export default observer(function Menu({ post, button, className }: Props) {
    const [isOpen, setOpen] = React.useState(false)
    const [showPostForm, togglePostForm] = useToggle()
    const [showDeleteModal, toggleDeleteModal] = useToggle()

    return (
        <>
            {showPostForm && <PostForm post={post} onClose={togglePostForm} />}
            {showDeleteModal && (
                <DeleteModal
                    onClose={toggleDeleteModal}
                    onDelete={() => {
                        api.post.removeScheduled({ id: -1 * post.id }).then()
                        removeFromCache(post, { key: ['posts', 'schedule'] })
                    }}
                />
            )}
            <Dropdown
                isOpen={isOpen}
                setOpen={setOpen}
                button={button}
                className={className}
                contentClass="absolute mt-2 rounded-lg shadow-around bg-white right-0 z-20 text-17 font-bold"
            >
                <button
                    type="button"
                    className={`${itemClass} text-blue-deep`}
                    onClick={togglePostForm}
                >
                    Edit
                </button>
                <button
                    type="button"
                    className={`${itemClass} text-red-500`}
                    onClick={toggleDeleteModal}
                >
                    Delete
                </button>
            </Dropdown>
        </>
    )
})
