import React from 'react'
import {
    Expression,
    StudyFlow,
    StudyFlowLevel,
    StudyFlowType,
} from 'Studyflow/types'
import { useMutation } from 'react-query'
import api from 'api'
import { addToCache } from 'Studyflow/cacheActions'

export default function useCreateStudyFlow({
    type,
    setError,
    isPublic,
    userIds,
    classIds,
    onClose,
}: {
    type: StudyFlowType
    setError(error?: Error): void
    isPublic: boolean
    userIds: number[]
    classIds: number[]
    onClose(): void
}) {
    const [createdStudyFlow, setCreatedStudyFlow] = React.useState<StudyFlow>()
    const { mutate: performCreate, isLoading: isCreating } = useMutation(
        api.studyFlow.create,
        {
            onSettled(flow, error) {
                if (error) {
                    setError(error as Error)
                } else if (flow) {
                    setCreatedStudyFlow(flow)
                    onClose()
                    addToCache(flow)
                }
            },
        },
    )

    const create = (values: {
        title: string
        level: StudyFlowLevel
        expressions: Expression[]
    }) => {
        if (!values || isCreating || createdStudyFlow) return

        performCreate({
            type,
            title: values.title,
            level: values.level,
            isPublic,
            shared_users: userIds,
            shared_classes: classIds,
            expressions: values.expressions,
        })
    }

    return {
        create,
        createdStudyFlow,
        isCreating,
    }
}
