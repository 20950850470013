import React from 'react'
import { Tag } from 'Post/types'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import routes from 'routes'
import { observer } from 'mobx-react-lite'
import { getTextAndTagsParts } from 'utils/tags'
import { InReplyTo } from 'Post/Comment/types'
import style from 'Home/style.module.css'
import { urlify } from 'utils/meta'
import HighlightCorrection from 'utils/HighlightCorrection'
import Linkify from 'react-linkify'

type Props = {
    data: {
        text: string
        tags?: Tag[]
        inReplyTo?: InReplyTo
        images?: string[]
        video?: string
        correction_ranges?: string
        youtubeId?: string
        s_lecture_id?: number
    }
    textRef?: { current: null | HTMLDivElement }
    showFullText?: boolean
    className?: string
    highlightText?: string
    children?: React.ReactNode
}

export default observer(function Text({
    data,
    textRef,
    showFullText,
    className,
    highlightText,
    children,
}: Props) {
    const [{ parts, partsTags, text }, setTextParts] = React.useState(() =>
        getTextAndTagsParts(data),
    )

    React.useEffect(() => {
        if (data.text !== text) setTextParts(getTextAndTagsParts({ ...data }))
        // setTextParts(getTextAndTagsParts({ ...data, text: urlify(data.text) }))
    }, [data.text, data.tags, data.inReplyTo])

    const clampSize = () => {
        if (data.images?.length || data.video || data.youtubeId) {
            return style.clampedTextLarge
        } else if (data.s_lecture_id) {
            return style.clampedTextSmall
        } else {
            return style.clampedTextMedium
        }
    }

    return (
        <div
            ref={textRef}
            className={cn(
                `whitespace-pre-wrap`,
                className,
                style.text,
                !showFullText && clampSize(),
            )}
        >
            {children}
            <Linkify
                componentDecorator={(href, text, key) => (
                    <a
                        key={key}
                        target="_blank"
                        href={href}
                        className="hover:underline"
                        style={{ color: '#06ACEE' }}
                    >
                        {new URL(href).origin}...
                    </a>
                )}
            >
                {parts.map((part, i) => {
                    const tag = partsTags[i]
                    if (!tag || tag.type === 'studyflow') {
                        if (data.correction_ranges) {
                            const text = part
                            const correctionRanges = data.correction_ranges.split(
                                '|',
                            )
                            const ORIGINAL = `\n\nORIGINAL\n`
                            return (
                                <React.Fragment key={1}>
                                    {text.substr(0, ORIGINAL.length)}
                                    <HighlightCorrection
                                        correctionRanges={correctionRanges}
                                        text={text.replace(ORIGINAL, '')}
                                    />
                                </React.Fragment>
                            )
                        } else {
                            return (
                                <React.Fragment key={i}>
                                    {!highlightText && <span>{part}</span>}
                                    {highlightText &&
                                        part
                                            .split(highlightText)
                                            .map((part, i) => (
                                                <React.Fragment key={i}>
                                                    {i !== 0 && (
                                                        <span className="text-blue-primary">
                                                            {highlightText}
                                                        </span>
                                                    )}
                                                    {part}
                                                </React.Fragment>
                                            ))}
                                </React.Fragment>
                            )
                        }
                    }

                    const { type, id } = tag
                    return (
                        <Link
                            key={i}
                            to={
                                type === 'user' || type === 'reply'
                                    ? routes.user(id)
                                    : type === 'class'
                                    ? routes.class.classDetail(id)
                                    : routes.studyFlow(id)
                            }
                            className="text-blue-primary"
                        >
                            {part}
                        </Link>
                    )
                })}
            </Linkify>
        </div>
    )
})
