import React from 'react'
import api from 'api'
import { StudyFlowType } from 'Studyflow/types'
import Spin from 'assets/images/icons/Spin'
import { useQuery } from 'react-query'
import UpdateFormValues from './UpdateFormValues'

type Props = {
    type: StudyFlowType
    onClose(): void
    id: number
}

const UpdateForm = ({ type, id, onClose }: Props) => {
    const { isLoading, data } = useQuery(['studyFlow.get', { id }], () =>
        api.studyFlow.findOne({ id }),
    )
    if (isLoading) {
        return (
            <div className="bg-white h-full flex justify-center items-center">
                <Spin className="w-7 h-7 text-blue-primary animate-spin" />
            </div>
        )
    }

    if (data) {
        return (
            <UpdateFormValues
                onClose={onClose}
                type={type}
                currentStudyFlow={data}
            />
        )
    }

    return <div>Error</div>
}

export default UpdateForm
