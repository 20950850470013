import React from 'react'
import Modal from 'Shared/Modal'
import { ArrowLeft } from '@styled-icons/fa-solid/ArrowLeft'
import { MissionRecordUser } from 'Post/api'
import Row from 'Post/Modal/RecordMission/Completed/Row'
import { Post } from 'Post/types'

type Props = {
    post: Post
    onClose: () => void
    users: MissionRecordUser[]
}
export default function Completed({ post, onClose, users }: Props) {
    return (
        <Modal onClose={onClose} size="medium">
            <div className="flex flex-col h-full overflow-scroll">
                <div className="w-full my-6 flex flex-row items-center justify-center">
                    <button type="button" onClick={onClose} className="ml-8">
                        <ArrowLeft size={26} />
                    </button>
                    <span className="text-2xl text-black font-semibold w-full text-center">
                        Recording Mission
                    </span>
                    <button type="button" className="mr-8 invisible">
                        <ArrowLeft size={26} />
                    </button>
                </div>
                {users.map((user) => (
                    <Row user={user} post={post} key={user.missionId} />
                ))}
            </div>
        </Modal>
    )
}
