import React from 'react'
import { Organization, OrganizationPostGroup } from 'Class/Management/types'
import cn from 'classnames'
import { useQuery } from 'react-query'
import api from 'api'
import Loader from 'Shared/Loader'
import AccessiblePostGroupRow from './AccessiblePostGroupRow'
import Modal from '../Shared/Modal'
import AddGroupAccessForm from './AddGroupAccessForm'
import OrganizationMemberRow from './OrganizationMemberRow'
import AddMemberToOrganizationForm from './AddMemberToOrganizationForm'

type Props = {
    organization: Organization
}

export default function OrganizationDetail({
    className,
    organization,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
    const { data: accessibleGroups, isLoading: isGroupsLoading } = useQuery(
        ['adminGroupAccess', organization.id],
        () => api.admin.accessibleGroups(organization.id),
    )
    const { data: allGroups } = useQuery(['adminAllGroups'], () =>
        api.admin.allGroups(),
    )
    const { data: organizationMember, isLoading: isMemberLoading } = useQuery(
        ['adminOrganizationMember', organization.id],
        () => api.admin.organizationMember(organization.id),
    )

    const [isShowingAddAccessForm, setShowingAddAccessForm] = React.useState(
        false,
    )
    const [isShowingAddMemberForm, setShowingAddMemberForm] = React.useState(
        false,
    )

    const notAddedGroups: OrganizationPostGroup[] = allGroups
        ? allGroups.filter(
              (g) =>
                  accessibleGroups?.filter((ig) => g.id === ig.id).length === 0,
          )
        : []

    const onAddAccessClick = React.useCallback(() => {
        setShowingAddAccessForm(true)
    }, [setShowingAddAccessForm])
    const onAddMemberClick = React.useCallback(() => {
        setShowingAddMemberForm(true)
    }, [setShowingAddMemberForm])

    return (
        <div
            className={cn(
                'flex flex-col bg-white rounded shadow-lg p-7',
                className,
            )}
        >
            <span className="font-semibold text-2xl">
                Organization: {organization.id} - {organization.name}
            </span>
            <div className="flex flex-col flex-grow h-0 overflow-y-scroll">
                <div className="flex flex-row items-center">
                    <span className="mt-4 font-semibold text-lg flex-grow">
                        Accessible Groups
                    </span>
                    {notAddedGroups.length > 0 && (
                        <span
                            className="font-bold text-2xl cursor-pointer"
                            onClick={onAddAccessClick}
                        >
                            +
                        </span>
                    )}
                </div>
                <div className="flex flex-col pt-2 w-full">
                    {isGroupsLoading && (
                        <Loader
                            className="w-20 h-20 rounded text-blue-primary blue-primary"
                            color="#06ACEE"
                        />
                    )}
                    {accessibleGroups &&
                        accessibleGroups.map((g, i) => (
                            <AccessiblePostGroupRow
                                organization={organization}
                                group={g}
                                className={
                                    i % 2 === 0 ? 'bg-white' : 'bg-gray-ef'
                                }
                                key={g.id}
                            />
                        ))}
                </div>
                <div className="flex flex-row mt-7">
                    <span className="font-semibold text-lg flex-grow">
                        Admin Users
                    </span>
                    <span
                        className="font-bold text-2xl cursor-pointer"
                        onClick={onAddMemberClick}
                    >
                        +
                    </span>
                </div>
                <div className="flex flex-col pt-2 w-full">
                    {isMemberLoading && (
                        <Loader
                            className="w-20 h-20 rounded text-blue-primary blue-primary"
                            color="#06ACEE"
                        />
                    )}
                    {organizationMember &&
                        organizationMember.map((u, i) => (
                            <OrganizationMemberRow
                                organization={organization}
                                className={
                                    i % 2 === 0 ? 'bg-white' : 'bg-gray-ef'
                                }
                                member={u}
                            />
                        ))}
                </div>
            </div>

            {isShowingAddAccessForm && (
                <Modal
                    size="large"
                    onClose={() => setShowingAddAccessForm(false)}
                >
                    <AddGroupAccessForm
                        organization={organization}
                        groups={notAddedGroups}
                        onComplete={() => setShowingAddAccessForm(false)}
                    />
                    '
                </Modal>
            )}
            {isShowingAddMemberForm && (
                <Modal
                    size="large"
                    onClose={() => setShowingAddMemberForm(false)}
                    className="h-full flex"
                    hFull
                >
                    <AddMemberToOrganizationForm
                        organization={organization}
                        onClose={() => setShowingAddMemberForm(false)}
                    />
                </Modal>
            )}
        </div>
    )
}
