import React from 'react'
import { useQuery } from 'react-query'
import api from 'api'
import { getCurrentUserId } from 'User/currentUser'
import history from 'utils/history'
import routes from 'routes'
import LoadingPage from 'Shared/LoadingPage'
import Sidebar from 'Admin/Sidebar'
import { Organization } from 'Class/Management/types'
import OrganizationDetail from 'Admin/OrganizationDetail'

export default function Admin() {
    const currentUserId = getCurrentUserId()

    if ([1, 6, 8].indexOf(currentUserId) === -1) {
        history.push(routes.home())
        return <div />
    }

    const { data: adminAccess, isLoading } = useQuery(['adminAccess'], () =>
        api.user.adminAccess(),
    )

    const [selectedOrganization, setSelectedOrganization] = React.useState<
        Organization
    >()

    if (isLoading) {
        return <LoadingPage />
    }
    if (!adminAccess?.classManagement) {
        history.push(routes.home())
        return <div />
    }

    return (
        <div className="w-full h-full py-9 flex flex-row">
            <Sidebar
                className="w-full bg-white shadow-lg"
                selectedOrganization={selectedOrganization}
                setSelectedOrganization={setSelectedOrganization}
            />
            {selectedOrganization && (
                <OrganizationDetail
                    className="ml-8 w-full"
                    organization={selectedOrganization}
                />
            )}
        </div>
    )
}
