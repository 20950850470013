import React from 'react'
import Modal from 'Shared/Modal'

type Props = {
    onClose(): void
    onDuplicate(): void
}

export default function DuplicateModal({ onClose, onDuplicate }: Props) {
    return (
        <Modal onClose={onClose} className="text-center">
            <div className="mt-8 mb-2 text-lg font-bold text-xl">
                Duplicate StudyFlow?
            </div>
            <div className="mb-5">
                Are you sure to duplicate this StudyFlow?
            </div>
            <hr className="text-gray-bb" />
            <div className="flex-center my-4">
                <button
                    className="text-gray-4f h-7 font-bold w-24"
                    onClick={(e) => {
                        e.stopPropagation()
                        onClose()
                    }}
                >
                    Cancel
                </button>
                <button
                    className="rounded-full bg-blue-primary text-white h-7 font-bold ml-3 flex-center w-24"
                    onClick={async () => {
                        onDuplicate()
                        onClose()
                    }}
                >
                    Duplicate
                </button>
            </div>
        </Modal>
    )
}
