import React from 'react'
import LoadingPage from 'Shared/LoadingPage'
import Unauthorized from 'Class/Management/Unauthorized'
import Sidebar from 'Class/Management/Sidebar'
import logo from 'assets/images/logo.svg'
import { useQuery } from 'react-query'
import api from 'api'
import { OrganizationPostGroup } from './types'
import Radio from 'Shared/Form/Radio'
import { Class } from 'Class/types'
import Loader from 'Shared/Loader'
import RemainingRow from 'Class/Management/RemainingRow'
import PendingRow from 'Class/Management/PendingRow'
import PostedRow from 'Class/Management/PostedRow'
import Modal from 'Shared/Modal'
import Members from 'Class/Management/Members'

export default function Management() {
    const {
        data: organization,
        isLoading: organizationLoading,
        isError: organizationError,
    } = useQuery('organization', () => api.classManagement.organizations())
    const {
        data: groups,
        isLoading: groupLoading,
        isError: groupError,
    } = useQuery(['organizationGroup'], () => api.classManagement.groups())
    const [group, setGroup] = React.useState<OrganizationPostGroup>()
    const [selectedClass, setSelectedClass] = React.useState<Class>()
    const { data: candidates, isLoading: candidatesLoading } = useQuery(
        ['organizationCandidates', group?.id, selectedClass?.id],
        () =>
            group && selectedClass
                ? api.classManagement.candidates({
                      groupId: group.id,
                      classId: selectedClass.id,
                  })
                : Promise.resolve(undefined),
        { enabled: group !== undefined && selectedClass !== undefined },
    )
    const [isShowingMembers, setIsShowingMembers] = React.useState(false)

    React.useEffect(() => {
        if (!groups) {
            return
        }
        const length = groups.length
        if (!group && length > 0) {
            setGroup(groups[0])
        }
    }, [groups?.length])

    const onMembersClick = React.useCallback(() => {
        setIsShowingMembers(true)
    }, [setIsShowingMembers])
    const onMembersDismiss = React.useCallback(() => {
        setIsShowingMembers(false)
    }, [setIsShowingMembers])

    if (organizationLoading || groupLoading) {
        return <LoadingPage />
    }
    if (organizationError || groupError || !groups || !organization) {
        return <Unauthorized />
    }

    return (
        <div className="w-full h-full py-9 flex flex-row">
            <Sidebar
                className="w-full bg-white shadow-lg"
                setClass={setSelectedClass}
                selectedClass={selectedClass}
            />
            <div className="ml-15 bg-white rounded shadow-lg w-full flex flex-col p-8">
                <div className="flex flex-row mb-7">
                    <img style={{ width: '30px' }} src={logo} alt="logo" />
                    <span className="text-xl ml-2.5 font-semibold truncate">
                        POST MANAGEMENT: {organization.name}
                    </span>
                </div>
                {groups.length > 1 && (
                    <div className="flex flex-row mb-7">
                        {groups.map((g) => (
                            <div key={g.id}>
                                <label className="flex flex-row items-center mr-2">
                                    <Radio
                                        checked={g.id === group?.id}
                                        classes={{ root: 'mr-2' }}
                                        onChange={() => setGroup(g)}
                                        name="group"
                                        value={g.name}
                                        size={10}
                                    />
                                    {g.name}
                                </label>
                            </div>
                        ))}
                    </div>
                )}
                <div className="flex flex-col flex-grow h-0 overflow-y-scroll">
                    <span className="text-lg text-blue-008bc2 font-semibold">
                        PUBLISH YOUR POST
                    </span>
                    <span className="text-base mt-2 text-gray-6b">
                        왼쪽 클래스 섹션에서 포스팅하려는 Class를 선택한 후,
                        아래 포스팅하고자 하는 과의 포스팅 시간을 설정하세요.
                        시간을 설정하면 Publish 버튼이 나타나고, Publish 버튼을
                        누르면 포스팅이 예약됩니다. 시간은 포스팅에 사용하고
                        있는 현재 컴퓨터에 나타난 시간을 기준으로 합니다. 시간은
                        30분 단위로 설정할 수 있습니다. Publish한 포스트는
                        예약된 포스팅 시간이 되기 전에는 취소할 수 있습니다.
                        화면을 아래로 스크롤 해서 내리면 Publish한 레슨을 볼 수
                        있고 포스팅 이전이라면 Cancel 할 수 있습니다. 포스트
                        제목을 클릭하면 미리보기 창이 열립니다. 아래 리스트에
                        있는 레슨이 아닌 공지와 같은 포스트를 올리려면 SCHOOOL
                        앱이나 웹을 이용해서 올리시면 됩니다.
                    </span>
                    <div className="mx-2.5 h-px bg-gray-c5 mt-5 mb-5" />
                    {candidatesLoading && (
                        <div className="flex flex-col items-center">
                            <Loader
                                className="w-20 h-20 rounded text-blue-primary blue-primary"
                                color="#06ACEE"
                            />
                        </div>
                    )}
                    <div className="flex flex-row items-center mb-2">
                        <span className="font-bold text-lg">
                            {selectedClass?.name}
                        </span>
                        <div className="flex-1" />
                        <span
                            className="h-6 border border-gray-97 rounded-full px-2 cursor-pointer font-bold bg-gray-fc text-gray-97 flex items-center"
                            onClick={onMembersClick}
                        >
                            Class Members
                        </span>
                    </div>
                    {candidates?.remainingCandidates &&
                        candidates.remainingCandidates.map((c, i) => (
                            <RemainingRow
                                key={c.id}
                                candidate={c}
                                selectedClassId={selectedClass?.id}
                                className={
                                    i % 2 === 0 ? 'bg-gray-ef' : 'bg-white'
                                }
                            />
                        ))}
                    <span className="text-lg text-blue-008bc2 font-semibold mt-7 mb-2">
                        PUBLISHED / PENDING POST
                    </span>
                    {candidates?.pendingCandidates &&
                        candidates.pendingCandidates.map((c, i) => (
                            <PendingRow
                                key={c.id}
                                candidate={c}
                                className={
                                    i % 2 === 0 ? 'bg-gray-ef' : 'bg-white'
                                }
                            />
                        ))}
                    {candidates?.postedCandidates &&
                        candidates.postedCandidates.map((c, i) => (
                            <PostedRow
                                key={c.id}
                                candidate={c}
                                className={
                                    (candidates.pendingCandidates.length + i) %
                                        2 ===
                                    0
                                        ? 'bg-gray-ef'
                                        : 'bg-white'
                                }
                            />
                        ))}
                </div>
            </div>
            {isShowingMembers && selectedClass && (
                <Modal size="large" onClose={onMembersDismiss}>
                    <Members
                        onDismiss={onMembersDismiss}
                        selectedClass={selectedClass}
                    />
                </Modal>
            )}
        </div>
    )
}
