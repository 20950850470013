import React from 'react'
import { PendingCandidate } from 'Class/Management/types'
import cn from 'classnames'
import api from 'api'
import { cancelPostSchedule } from 'Class/Management/cacheActions'
import Loader from 'Shared/Loader'
import TwoButtonAlert from 'Shared/Modal/TwoButtonAlert'

type Props = {
    candidate: PendingCandidate
}

export default function PendingRow({
    candidate,
    className,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
    const [isShowingCancelAlert, setShowingCancelAlert] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)

    const onDismiss = React.useCallback(() => {
        setShowingCancelAlert(false)
    }, [setShowingCancelAlert])
    const onCancel = React.useCallback(async () => {
        if (isLoading) {
            return
        }
        setIsLoading(true)
        setShowingCancelAlert(false)
        await api.classManagement.cancelPost({ scheduleId: candidate.id })
        cancelPostSchedule(candidate.id)
    }, [isLoading, setIsLoading, setShowingCancelAlert])
    const onCancelClick = React.useCallback(() => {
        if (isLoading) {
            return
        }
        setShowingCancelAlert(true)
    }, [isLoading, setShowingCancelAlert, candidate])

    return (
        <div
            className={cn(
                'h-10 flex-shrink-0 flex flex-row items-center px-5 text-base',
                className,
            )}
        >
            <span className="flex-1">{candidate.title}</span>
            <span>
                {candidate.scheduledAt.local().format('YYYY-MM-DD, hh:mm A')}
            </span>
            <button
                className={cn(
                    'h-6 border rounded-full px-2 flex items-center ml-3 bg-gray-fc border-gray-97 text-gray-6b text-base',
                )}
                onClick={onCancelClick}
            >
                {!isLoading && 'Cancel'}
                {isLoading && (
                    <Loader
                        className="w-4 h-4 text-blue-primary mx-4"
                        color="#06ACEE"
                    />
                )}
            </button>
            {isShowingCancelAlert && (
                <TwoButtonAlert
                    title="Cancel Publish"
                    content="Are you sure to cancel this publish?"
                    leftTitle="No"
                    leftClassName="text-red-600 text-lg"
                    rightTitle="Cancel"
                    rightClassName="font-semibold text-white bg-blue-primary rounded-full h-7 px-4 flex items-center"
                    onLeftClick={onDismiss}
                    onRightClick={onCancel}
                />
            )}
        </div>
    )
}
