import { post, get, put, del } from 'utils/apiUtils'

export const intent = get(() => ({
    path: '/payment/intent',
    response: (data: any) => data,
}))

export const paymentInfo = post(
    (data: { username: string; email: string }) => ({
        path: '/payment/info',
        data,
    }),
)

export const paymentAccess = get(() => ({
    path: '/payment/access',
    response: (data: any) => data,
}))
