import React from 'react'
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { intent, paymentAccess } from '../api'
import Form from 'Settings/Payment/Form'
import Loader from 'Shared/Loader'
import routes from 'routes'
import history from 'utils/history'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? '')

export default function Index() {
    const [paymentOptions, setPaymentOptions] = React.useState<
        StripeElementsOptions | undefined
    >(undefined)
    const [paymentAllowed, setPaymentAllowed] = React.useState<
        boolean | undefined
    >(undefined)

    React.useEffect(() => {
        async function run() {
            try {
                await paymentAccess()
                setPaymentAllowed(true)
            } catch (e) {
                setPaymentAllowed(false)
            }
        }
        run().then()
    }, [])

    React.useEffect(() => {
        async function runner() {
            if (paymentAllowed === undefined) {
                return
            }
            if (!paymentAllowed) {
                history.push(routes.home())
                return
            }
            const response = await intent()

            if (response.clientSecret) {
                setPaymentOptions(response)
            } else {
                history.push(routes.settings.paymentSucceed())
            }
        }

        runner().then()
    }, [paymentAllowed])

    if (!paymentOptions) {
        return (
            <div className="flex flex-row justify-center items-center h-full">
                <Loader className="w-20 h-20 " color="#06ACEE" />
            </div>
        )
    }

    return (
        <Elements stripe={stripePromise} options={paymentOptions}>
            {paymentOptions && <Form />}
        </Elements>
    )
}
