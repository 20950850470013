import React from 'react'
import { useQuery } from 'react-query'
import { Class } from 'Class/types'
import api from 'api'
import cn from 'classnames'
import Loader from 'Shared/Loader'
import TwoButtonAlert from 'Shared/Modal/TwoButtonAlert'
import { useCurrentUser } from 'User/currentUser'
import { queryClient } from 'utils/queryClient'
import { Member } from 'Class/api'
import { ArrowLeft, CircleXmark } from '@styled-icons/fa-solid'

type Props = {
    onDismiss: () => void
    selectedClass: Class
}

export default function Members({ onDismiss, selectedClass }: Props) {
    const { data: members, isLoading } = useQuery(
        ['class', selectedClass.id, 'members'],
        () => api.classes.members({ classId: selectedClass.id }),
    )
    const [isShowingDismissAlert, setShowingDismissAlert] = React.useState(
        false,
    )
    const [currentUser] = useCurrentUser()
    const [dismissingUser, setDismissingUser] = React.useState<
        number | undefined
    >()
    const [isDismissingUser, setIsDismissingUser] = React.useState(false)

    const [addingUserEmail, setAddingUserEmail] = React.useState('')
    const [inputFocusLost, setInputFocusLost] = React.useState(false)

    const [addingUser, setAddingUser] = React.useState<Member>()
    const [isAddingUserLoading, setIsAddingUserLoading] = React.useState(false)
    const [isAddingUser, setIsAddingUser] = React.useState(false)

    const onDismissClick = React.useCallback(
        (userId: number) => {
            if (isDismissingUser) {
                return
            }
            setShowingDismissAlert(true)
            setDismissingUser(userId)
        },
        [setShowingDismissAlert, setDismissingUser, isDismissingUser],
    )
    const onNotDismissClick = React.useCallback(() => {
        setShowingDismissAlert(false)
    }, [setShowingDismissAlert])
    const onDismissConfirmedClick = React.useCallback(async () => {
        if (!dismissingUser) {
            setShowingDismissAlert(false)
            return
        }
        if (isDismissingUser) {
            return
        }
        setIsDismissingUser(true)
        setShowingDismissAlert(false)
        await api.classes.removeMember({
            classId: selectedClass.id,
            userId: dismissingUser,
        })
        await queryClient.invalidateQueries([
            'class',
            selectedClass.id,
            'members',
        ])
        setIsDismissingUser(false)
    }, [
        setShowingDismissAlert,
        dismissingUser,
        isDismissingUser,
        setIsDismissingUser,
    ])
    const onEmailChange = React.useCallback(
        (email: string) => {
            setAddingUserEmail(email)
            setAddingUser(undefined)
        },
        [setAddingUserEmail, setAddingUser],
    )

    const searchUserEmail = React.useCallback(async () => {
        if (!addingUserEmail) {
            setAddingUser(undefined)
            return
        }
        if (isAddingUserLoading) {
            return
        }
        setIsAddingUserLoading(true)
        const users = await api.user.searchUserByEmail({
            email: addingUserEmail,
        })
        if (users.length !== 1) {
            setAddingUser({
                user_id: -1,
                name: 'No name matches',
                email: addingUserEmail,
                profile_image_dir: '',
            })
            setIsAddingUserLoading(false)
            return
        }
        setAddingUser(users[0])
        setIsAddingUserLoading(false)
    }, [
        addingUserEmail,
        setAddingUser,
        setIsAddingUserLoading,
        isAddingUserLoading,
    ])
    const addUserAsMember = React.useCallback(async () => {
        if (!addingUser || addingUser.user_id === -1) {
            return
        }
        if (isAddingUser) {
            return
        }

        setIsAddingUser(true)
        await api.classManagement.addMember({
            classId: selectedClass.id,
            userId: addingUser.user_id,
        })

        await queryClient.invalidateQueries([
            'class',
            selectedClass.id,
            'members',
        ])
        setAddingUser(undefined)
        setAddingUserEmail('')
        setIsAddingUser(false)
    }, [
        selectedClass,
        addingUser,
        setAddingUser,
        setAddingUserEmail,
        setIsAddingUser,
        isAddingUser,
    ])

    React.useEffect(() => {
        if (!inputFocusLost) {
            return
        }
        searchUserEmail().then()
    }, [inputFocusLost, addingUserEmail])

    return (
        <div className="flex flex-col pb-3">
            <div
                onClick={onDismiss}
                className="absolute mt-5 ml-5 text-gray-97 cursor-pointer"
            >
                <ArrowLeft size={26} />
            </div>
            <span className="text-2xl font-semibold my-4 mx-auto">
                Class Members
            </span>
            <span className="text-gray-97 font-semibold text-lg ml-5">
                Add Member
            </span>
            <div className="flex flex-row mx-5 h-10 space-x-2 mb-2">
                <div className="flex-1 border border-gray-97 p-1 flex">
                    <input
                        className="w-full"
                        placeholder="Email"
                        value={addingUserEmail}
                        onChange={(e) => onEmailChange(e.target.value)}
                        onBlur={() => {
                            setTimeout(() => {
                                setInputFocusLost(true)
                            }, 500)
                        }}
                        onFocus={() => setInputFocusLost(false)}
                    />
                    {addingUserEmail && (
                        <button
                            className="text-black"
                            onClick={() => onEmailChange('')}
                        >
                            <CircleXmark size={15} />
                        </button>
                    )}
                </div>
                <span
                    className={cn(
                        'p-1 flex-1 border border-gray-97  flex items-center select-none',
                        addingUser && 'text-black',
                        !addingUser && 'text-gray-97',
                    )}
                >
                    {isAddingUserLoading && (
                        <Loader className="w-8 h-8 rounded" color="#06ACEE" />
                    )}
                    {!isAddingUserLoading && (addingUser?.name ?? 'Name')}
                </span>
                <span
                    className={cn(
                        'px-3 flex items-center select-none',
                        (!addingUser || addingUser.user_id === -1) &&
                            'border border-gray-97 text-gray-97',
                        addingUser &&
                            addingUser.user_id !== -1 &&
                            'cursor-pointer bg-blue-008bc2 text-white',
                    )}
                    onClick={addUserAsMember}
                >
                    {!isAddingUser && 'Add'}
                    {isAddingUser && (
                        <Loader
                            className="w-8 h-8 rounded text-white"
                            color="#FFFFFF"
                        />
                    )}
                </span>
            </div>
            <span className="text-gray-97 font-semibold text-lg ml-5">
                Members
            </span>
            {isLoading && (
                <div className="w-full flex mb-2 flex-row justify-center">
                    <Loader
                        className="w-20 h-20 rounded text-blue-primary blue-primary"
                        color="#06ACEE"
                    />
                </div>
            )}
            {members &&
                members
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((member, index) => (
                        <div
                            className={cn(
                                'flex flex-row h-11 items-center',
                                index % 2 == 0 && 'bg-gray-ef',
                            )}
                            key={member.id}
                        >
                            <img
                                className="w-7 h-7 rounded-full ml-5"
                                src={member.avatar}
                            />
                            <div className="mr-5 w-full flex flex-row">
                                <span className="font-semibold ml-2 flex-1">
                                    {member.name}
                                </span>
                                <span className="flex-1">{member.email}</span>
                                <div className="flex-1 flex flex-row justify-end">
                                    {currentUser.id !== member.id && (
                                        <span
                                            className={cn(
                                                'text-red-e0 cursor-pointer',
                                                isDismissingUser &&
                                                    dismissingUser !==
                                                        member.id &&
                                                    'opacity-50',
                                            )}
                                            onClick={() =>
                                                onDismissClick(member.id)
                                            }
                                        >
                                            {isDismissingUser &&
                                                dismissingUser ===
                                                    member.id && (
                                                    <Loader
                                                        className="w-8 h-8 rounded text-white"
                                                        color="#E02020"
                                                    />
                                                )}
                                            {(!isDismissingUser ||
                                                dismissingUser !== member.id) &&
                                                'Dismiss'}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
            {isShowingDismissAlert && (
                <TwoButtonAlert
                    title="Dismiss Member"
                    content="Are you sure to dismiss this member?"
                    leftTitle="No"
                    leftClassName=""
                    onLeftClick={onNotDismissClick}
                    rightTitle="Dismiss"
                    rightClassName="bg-red-e0 text-white px-3 rounded-full font-semibold"
                    onRightClick={onDismissConfirmedClick}
                />
            )}
        </div>
    )
}
