export default {
    admin: {
        classManagement: () => '/admin/classManagement',
    },
    home: () => '/',
    class: {
        classes: () => '/classes',
        classDetail: (id?: number) => `/classes/${id || ':id'}`,
        classSavedPosts: (id?: number) => `/classes/${id || ':id'}/saved_posts`,
        management: () => '/classes/management',
    },
    settings: {
        index: () => '/settings',
        profile: () => '/settings/profile',
        password: () => '/settings/password',
        passwordForgot: () => '/settings/password/forgot',
        termsOfUse: () => '/settings/terms-of-use',
        privacyPolicy: () => '/settings/privacy-policy',
        payment: () => '/settings/payments',
        paymentSucceed: () => '/settings/payments/succeed',
    },
    signIn: () => '/sign-in',
    signUp: () => '/sign-up',
    signUpForm: () => '/sign-up-form',
    forgotPassword: () => '/forgot-password',
    user: (id?: number | 'me') => `/users/${id || ':id'}`,
    userSavedPosts: () => `/users/me/saved_posts`,
    studyFlow: (id?: number) => `/studyflows/${id || ':id'}`,
}
