import React, { ChangeEvent, ChangeEventHandler } from 'react'
import { observer } from 'mobx-react-lite'
import { State } from 'Post/Form/State'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

type Props = {
    state: State
    close: () => void
    onSchedule: () => void
}

function minimumDate(): Date {
    const now = dayjs(Date())

    const min = now.minute()
    const minDiff = 60 + 15 - (min % 15)
    let target = now.add(minDiff, 'minute')
    target = target.second(0)
    target = target.millisecond(0)

    return target.toDate()
}

function maximumDate(): Date {
    const now = dayjs(Date())

    let target = now.add(30, 'day')
    target = target.hour(23)
    target = target.minute(59)
    target = target.second(0)
    target = target.millisecond(0)

    return target.toDate()
}

function defaultDate(): Date {
    const now = dayjs(Date())

    let target = now.add(1, 'day')
    target = target.hour(11)
    target = target.minute(0)
    target = target.second(0)
    target = target.millisecond(0)

    return target.toDate()
}

function availableDates(): string[] {
    const min = dayjs(minimumDate())
    const max = dayjs(maximumDate())

    let target = min
    const dates: string[] = []
    while (target < max) {
        dates.push(target.format('MMM DD, YYYY'))
        target = target.add(1, 'day')
    }

    return dates
}

function allHours(): string[] {
    const hours: string[] = []
    for (const amPm of ['AM', 'PM']) {
        for (const hour of [
            '12',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
        ]) {
            for (const minute of ['00', '15', '30', '45']) {
                hours.push(`${hour}:${minute} ${amPm}`)
            }
        }
    }
    return hours
}

export default observer(function SelectSchedule({
    state,
    close,
    onSchedule,
}: Props) {
    const [targetDate, setTargetDate] = React.useState(
        state.values.schedule ?? defaultDate(),
    )

    const dates = availableDates()
    let hours = allHours()
    if (dayjs(targetDate).isSame(minimumDate(), 'day')) {
        const minimumAvailableHour = dayjs(minimumDate())
            .local()
            .format('h:mm A')
        const index = hours.indexOf(minimumAvailableHour)
        hours = hours.splice(index)
    }

    const onDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const date = dayjs(e.target.value)
        const currentDate = dayjs(targetDate)
        const target = currentDate
            .date(date.date())
            .month(date.month())
            .year(date.year())
            .toDate()
        if (target < minimumDate()) {
            setTargetDate(minimumDate())
        } else {
            setTargetDate(target)
        }
    }
    const onHourChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const date = dayjs(e.target.value, 'h:mm A')
        const currentDate = dayjs(targetDate)
        setTargetDate(
            currentDate.hour(date.hour()).minute(date.minute()).toDate(),
        )
    }

    const selectedDate = dayjs(targetDate).format('MMM DD, YYYY')
    const selectedHour = dayjs(targetDate).format('h:mm A')

    const onSubmit = () => {
        state.setSchedule(targetDate)
        close()
        onSchedule()
    }

    return (
        <div className="flex flex-col items-center">
            <div className="flex flex-col mt-6">
                <span className="font-semibold text-xl">Schedule Post</span>
                <span className="text-gray-5b">
                    Schedule the post in your local time.
                </span>
                <div className="flex flex-row mt-4 mb-4">
                    <select
                        onChange={onDateChange}
                        value={selectedDate}
                        className="h-10 appearance-none bg-black text-white pl-2 pr-2"
                    >
                        {dates.map((d) => (
                            <option key={d}>{d}</option>
                        ))}
                    </select>
                    <select
                        onChange={onHourChange}
                        value={selectedHour}
                        className="ml-3 h-10 appearance-none bg-black text-white pl-2 pr-2"
                    >
                        {hours.map((d) => (
                            <option key={d}>{d}</option>
                        ))}
                    </select>
                </div>
                <div
                    className="text-black font-normal"
                    style={{
                        fontFamily:
                            'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                    }}
                >
                    Your scheduled post is saved in the{' '}
                    <span className="font-semibold">‘All Posts’</span>
                    <br />
                    section of your{' '}
                    <span className="font-semibold">Me Tab</span>. You can{' '}
                    <span className="font-semibold">
                        edit or re-
                        <br />
                        schedule
                    </span>{' '}
                    your post until the publish time.
                </div>
            </div>
            <div className="bg-gray-bc w-full h-px mt-7" />
            <div
                className="mt-5 mb-5 text-white bg-blue-primary rounded-2xl w-24 h-7 inline-flex items-center"
                onClick={onSubmit}
            >
                <span className="w-full text-center">Submit</span>
            </div>
        </div>
    )
})
