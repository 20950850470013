import React from 'react'
import { Organization } from 'Class/Management/types'
import useRecords from 'utils/useRecords'
import api from 'api'
import OrganizationNonMemberRow from 'Admin/OrganizationNonMemberRow'

type Props = {
    organization: Organization
    onClose: () => void
}

export default function AddMemberToOrganizationForm({ organization }: Props) {
    const [searchKey, setSearchKey] = React.useState('')
    const scrollRef = React.useRef<HTMLDivElement>(null)
    const { data } = useRecords({
        key: ['adminOrganizationNonMember', organization.id, searchKey],
        load: ({ offset }) =>
            api.admin.organizationNonMember(organization.id, searchKey, offset),
        loadOnScroll: {
            ref: scrollRef,
            threshold: 500,
        },
    })

    return (
        <div className="flex flex-col p-2 h-full w-full">
            <span className="text-2xl font-semibold">
                Add Member To Organization
            </span>
            <input
                className="h-20 border rounded-lg px-2 mx-3 my-4 flex-shrink-1"
                placeholder="Search Key"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
            />
            <div
                className="w-full flex flex-col overflow-y-scroll"
                ref={scrollRef}
            >
                {data?.pages.map((members, page) => (
                    <React.Fragment key={page}>
                        {members.map((m, i) => (
                            <OrganizationNonMemberRow
                                organization={organization}
                                member={m}
                                key={i}
                                className={
                                    i % 2 === 0 ? 'bg-white' : 'bg-gray-ef'
                                }
                            />
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}
