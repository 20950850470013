import firebase from 'firebase/app'
import 'firebase/messaging'

const firebaseConfig = {
    apiKey: 'AIzaSyCbo4K_-oV53csN4UNqOXyfSnKgdg9rfO8',
    authDomain: 'fluent-century-88607.firebaseapp.com',
    projectId: 'fluent-century-88607',
    storageBucket: 'fluent-century-88607.appspot.com',
    messagingSenderId: '862406951151',
    appId: '1:862406951151:web:f702a52aa2cf7c2fce9b1a',
}

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
} else {
    firebase.app()
}

let messaging: firebase.messaging.Messaging | undefined = undefined
if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging()
}

export const getToken = async (): Promise<string> => {
    if (!messaging) {
        throw Error()
    }

    try {
        return await messaging.getToken({
            vapidKey:
                'BDcJNuDtUE2yPNZ-xO-LCzyhzrHFbexfqrkK-FiIrZDT0E4xLZ1ZmJfObSmxBN4A1DswDruIzvFOKlruQEC0cy4',
        })
    } catch (e) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (e.code === 'messaging/token-unsubscribe-failed') {
            return getToken()
        }
        throw e
    }
}

export const onMessageListener = () =>
    new Promise((resolve: (arg0: any) => void) => {
        if (!messaging) {
            return
        }
        messaging.onMessage((payload) => {
            resolve(payload)
        })
    })
