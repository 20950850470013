import React from 'react'
import Item from './Item'
import { LearningLanguage, useSetLanguage } from 'utils/learningLanguage'

type Props = {}

const learningLanguages: LearningLanguage[] = ['English', 'Korean']

export default function LanguageSelector({}: Props) {
    const setLanguage = useSetLanguage()
    const onLanguageChosen = (language: LearningLanguage) => {
        setLanguage(language)
        window.location.reload()
    }

    return (
        <div
            className="flex flex-col"
            style={{ width: '100pt', background: 'white' }}
        >
            <div
                style={{ height: '2px', background: '#06ACEEFF' }}
                className="w-full"
            />
            <span className="mt-1 ml-2">Teach or Learn</span>
            <div className="ml-2 mb-2">
                {learningLanguages.map((language) => (
                    <button onClick={() => onLanguageChosen(language)}>
                        <Item language={language} />
                    </button>
                ))}
            </div>
        </div>
    )
}
