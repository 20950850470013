import React from 'react'
import Spin from 'assets/images/icons/Spin'
import PostItem from 'Post/Card'
import api from 'api'
import useRecords from 'utils/useRecords'
import { Page, Post, UsefulExpression } from 'Post/types'
import UsefulExpressionItem from './UsefulExpression'
import LevelComplete from 'Post/UsefulExpression/LevelComplete'
import { Camera } from '@styled-icons/boxicons-regular/Camera'
import { Smile } from '@styled-icons/fa-regular'
import { Heart } from '@styled-icons/boxicons-solid/Heart'
import dayjs from 'dayjs'
import { formatDate } from 'utils/date'
import logo from 'assets/images/logo.svg'

import { Comment } from '@styled-icons/fa-solid/Comment'
import Notebook from 'assets/images/icons/notebook'
import { Check } from '@styled-icons/boxicons-regular/Check'

type Props = {
    userId?: number
    classId?: number
    savedPosts?: boolean
    search?: string
    usefulExpressions?: boolean
    children?: React.ReactNode
    scrollRef?: React.RefObject<HTMLDivElement>
}

const getNextPageParam = (lastPage: Page, pages: Page[]) =>
    lastPage.posts.length > 0
        ? pages.reduce((sum, page) => sum + page.posts.length, 0)
        : undefined

const Posts = ({
    userId,
    classId,
    savedPosts: loadSavedPosts,
    search,
    usefulExpressions: showUsefulExpressions = false,
    children,
    scrollRef,
}: Props) => {
    const wrapRef = React.useRef<HTMLDivElement>(null)

    const { data: savedPosts, isFetching: isFetchingSavedPosts } = useRecords<
        Page
    >({
        key: ['posts', 'saved', { classId }],
        load: ({ limit, offset }) =>
            api.post.listSaved({ classId, limit, offset }).then((posts) => ({
                posts,
                usefulExpressions: [] as UsefulExpression[],
            })),
        getNextPageParam,
        loadOnScroll: {
            ref: scrollRef ?? wrapRef,
            threshold: 500,
        },
        options: {
            enabled: loadSavedPosts,
        },
    })

    const { data: allPosts, isFetching: isFetchingAllPosts } = useRecords<Page>(
        {
            key: ['posts', { classId, userId, search }],
            load: ({ limit, offset }) => {
                if (userId)
                    return api.post
                        .userPosts({ userId, search, limit, offset })
                        .then((posts) => ({
                            posts,
                            usefulExpressions: [] as UsefulExpression[],
                        }))
                else return api.post.list({ classId, limit, offset })
            },
            getNextPageParam,
            loadOnScroll: {
                ref: scrollRef ?? wrapRef,
                threshold: 500,
            },
            options: {
                enabled: !loadSavedPosts,
            },
        },
    )
    const { data: scheduledPost } = useRecords<Page>({
        key: ['posts', 'schedule'],
        load: () => {
            return api.post.scheduled()
        },
        getNextPageParam,
        options: {
            enabled: !loadSavedPosts && userId !== undefined,
        },
    })

    const posts = loadSavedPosts ? savedPosts : allPosts
    const isFetching = loadSavedPosts
        ? isFetchingSavedPosts
        : isFetchingAllPosts

    if (!isFetching && posts?.pages.length === 0 && children)
        return <>{children}</>

    return (
        <div ref={wrapRef}>
            {isFetching && posts && (
                <div className="flex-center my-5">
                    <Spin className="w-7 h-7 text-blue-primary animate-spin" />
                </div>
            )}
            {!loadSavedPosts &&
                userId !== undefined &&
                scheduledPost?.pages.map(({ posts }: { posts: Post[] }) =>
                    posts.map((post) => <PostItem key={post.id} post={post} disableComment={true} />),
                )}
            {posts?.pages.map(
                (
                    {
                        posts,
                        usefulExpressions,
                    }: { posts: Post[]; usefulExpressions: UsefulExpression[] },
                    page,
                ) => (
                    <React.Fragment key={page}>
                        {showUsefulExpressions && page === 0 && posts[0] && (
                            <PostItem post={posts[0]} />
                        )}

                        {showUsefulExpressions &&
                            page === 0 &&
                            usefulExpressions.map((item) => (
                                <UsefulExpressionItem
                                    key={item.id}
                                    item={item}
                                />
                            ))}

                        {showUsefulExpressions &&
                            page === 0 &&
                            usefulExpressions.length === 0 && <LevelComplete />}

                        {(showUsefulExpressions && page === 0
                            ? posts.slice(1)
                            : posts
                        ).map((post) => (
                            <PostItem key={post.id} post={post} />
                        ))}
                    </React.Fragment>
                ),
            )}
            {isFetching && !posts && (
                <div className="flex-center flex-col my-5">
                    <div className="bg-white shadow relative w-full flex flex-col mb-4">
                        <div className="flex-center mb-3 p-4">
                            <img
                                src={logo}
                                alt="avatar"
                                style={{ width: '60px', height: '60px' }}
                                className="mr-3 rounded-full"
                            />
                            <div className="flex-grow">
                                <div className="text-gray-b0 text-sm">
                                    <div className="text-black text-xl">
                                        SCHOOOL
                                    </div>
                                    <div>{formatDate(dayjs(Date()))}</div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-center mb-4 text-blue-primary bg-gray-f7 w-full py-150px">
                            <Spin className="animate-spin h-7 w-7 mr-3" />
                        </div>
                        <div className="border-b border-gray-d6 flex justify-around px-8 py-6">
                            <button className="flex-center text-gray-5f transition duration-200">
                                <Heart size={34} />
                            </button>
                            <div className="flex-center text-gray-5f transition duration-200">
                                <Comment size={29} />
                            </div>
                            <div className="flex-center transition duration-200">
                                <Check size={40} />
                            </div>
                            <div className="flex-center">
                                <Notebook className="transition duration-200" />
                            </div>
                        </div>
                        <div className="text-gray-ae pt-4 px-4">
                            Write a comment
                        </div>
                        <div className="flex justify-between items-center py-2 pb-6 px-4">
                            <div>
                                <Camera
                                    className="text-gray-a4 cursor-pointer transition duration-200 hover:text-blue-primary mr-2"
                                    size={25}
                                />
                                <Smile className="text-gray-a4" size={20} />
                            </div>
                            <div className="text-blue-primary text-lg flex-center opacity-25 cursor-default mr-2">
                                Post
                            </div>
                        </div>
                    </div>
                    <div className="bg-white shadow relative py-6 px-5 mb-5 pb-12 w-full">
                        <div className="flex items-center justify-between text-blue-primary text-xl mb-4">
                            <div className="uppercase">Useful expressions</div>
                        </div>
                    </div>
                </div>
            )}
            {isFetching && posts && (
                <div className="flex-center my-5">
                    <Spin className="w-7 h-7 text-blue-primary animate-spin" />
                </div>
            )}
        </div>
    )
}

export default Posts
