import React from 'react'
import { Organization, OrganizationPostGroup } from 'Class/Management/types'
import Radio from 'Shared/Form/Radio'
import api from 'api'
import { addAccess } from 'Admin/cacheActions'
import { queryClient } from 'utils/queryClient'
import Loader from 'Shared/Loader'

type Props = {
    organization: Organization
    groups: OrganizationPostGroup[]
    onComplete: () => void
}

export default function AddGroupAccessForm({
    organization,
    groups,
    onComplete,
}: Props) {
    const [selectedGroups, setSelectedGroups] = React.useState<number[]>([])
    const [isLoading, setIsLoading] = React.useState(false)

    const onAddAccess = React.useCallback(async () => {
        if (isLoading) {
            return
        }
        setIsLoading(true)
        for (const group of selectedGroups) {
            await api.admin.addAccess(organization.id, group)
            const rawGroups = groups.filter((g) => g.id === group)
            if (rawGroups.length === 1) {
                addAccess(organization.id, rawGroups[0])
            } else {
                await queryClient.invalidateQueries([
                    'adminGroupAccess',
                    organization.id,
                ])
            }
        }
        onComplete()
    }, [selectedGroups, groups, isLoading, setIsLoading, onComplete])

    return (
        <div className="flex flex-col items-center">
            <span className="font-bold text-2xl mt-5">
                Add access to groups
            </span>
            <div className="flex flex-row mt-3">
                {groups.map((g) => (
                    <label
                        key={g.id}
                        className="flex flex-row items-center mr-4"
                    >
                        <Radio
                            type="checkbox"
                            checked={selectedGroups.indexOf(g.id) > -1}
                            classes={{ root: 'mr-2' }}
                            onChange={() => {
                                if (selectedGroups.indexOf(g.id) > -1) {
                                    setSelectedGroups(
                                        selectedGroups.filter(
                                            (ig) => ig !== g.id,
                                        ),
                                    )
                                } else {
                                    setSelectedGroups(
                                        selectedGroups.concat([g.id]),
                                    )
                                }
                            }}
                            name={g.name}
                            value={g.name}
                            size={10}
                        />
                        {g.name}
                    </label>
                ))}
            </div>
            <span
                className="bg-blue-primary text-white rounded p-2 mt-4 text-lg font-semibold cursor-pointer"
                onClick={onAddAccess}
            >
                {!isLoading && 'Add Access'}
                {isLoading && <Loader className="w-3 h-3 mx-12" />}
            </span>
        </div>
    )
}
