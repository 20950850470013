import React from 'react'
import { AudioState, createState } from 'Post/Card/Audio/state'
import timeout from 'utils/timeout'
import useToggle from 'utils/useToggle'
import { useKey } from 'react-use'
import cn from 'classnames'
import { PauseFill } from '@styled-icons/bootstrap/PauseFill'
import { PlayFill } from '@styled-icons/bootstrap/PlayFill'
import ProgressBar from 'Post/Card/Audio/ProgressBar'
import { StopFill } from '@styled-icons/bootstrap/StopFill'
import { observer } from 'mobx-react-lite'

const useKeyWhenFocused = (
    focused: boolean,
    key: string,
    fn: () => void,
    deps: unknown[],
) =>
    useKey(
        key,
        (e) => {
            if (!focused) return
            e.preventDefault()
            fn()
        },
        {},
        [focused, ...deps],
    )

type Props = {
    src: string
    onFinished?(): void
}

export default observer(function Audio({
    src,
    className,
    onFinished,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
    const [state, setState] = React.useState<AudioState>()
    const [audio, setAudio] = React.useState<HTMLAudioElement>()

    React.useEffect(() => {
        // Pausing on unload
        return () => audio?.pause()
    }, [audio])

    React.useEffect(() => {
        const state = createState()
        setState(state)

        const audio = new window.Audio(src)
        audio.onloadedmetadata = () => state.setDuration(audio.duration)
        audio.onplay = () => state.setPlaying(true)
        audio.onpause = async () => {
            await timeout(100)
            if (audio.paused) {
                state.setPlaying(false)
            }
        }
        audio.onended = async () => {
            state.setCurrentTime(0)
            onFinished?.()
        }
        setAudio(audio)
    }, [src])

    const togglePlay = () => {
        if (state?.playing) {
            audio?.pause()
        } else {
            audio?.play().then()
        }
    }

    const stop = () => {
        if (!audio || !state) return
        audio.currentTime = 0
        audio.pause()
        state.setCurrentTime(0)
    }

    React.useEffect(() => {
        if (!state || !audio) return

        if (!state.playing) {
            state.setCurrentTime(audio.currentTime)
        } else {
            const animation = () => {
                state.setCurrentTime(audio.currentTime)
                request = requestAnimationFrame(animation)
            }
            let request = requestAnimationFrame(animation)

            return () => cancelAnimationFrame(request)
        }
    }, [audio, state?.playing])

    const [focused, toggleFocused] = useToggle()
    useKeyWhenFocused(
        focused,
        'ArrowLeft',
        () => {
            if (!audio) return

            const value = audio.currentTime
            audio.currentTime = Math.max(value - 1, 0)
        },
        [audio],
    )
    useKeyWhenFocused(focused, ' ', togglePlay, [audio])
    useKeyWhenFocused(
        focused,
        'ArrowRight',
        () => {
            if (!audio || !state) return

            const value = audio.currentTime as number
            audio.currentTime = Math.min(value + 1, state.duration)
        },
        [audio],
    )

    if (!state || !audio) return null

    return (
        <div
            className={cn(
                className,
                'h-7 px-3 flex flex-row items-center border-blue-12BBFE border rounded-full bg-gray-f7 text-blue-06',
            )}
            tabIndex={-1}
            onFocus={toggleFocused}
            onBlur={toggleFocused}
        >
            <button onClick={togglePlay}>
                {state.playing ? (
                    <PauseFill size={36} />
                ) : (
                    <PlayFill size={36} />
                )}
            </button>
            <ProgressBar audio={audio} state={state} tintColor="bg-blue-06" />
            <button onClick={stop}>
                <StopFill size={36} />
            </button>
        </div>
    )
})
