import React from 'react'
import Modal from 'Shared/Modal'
import { ArrowLeft } from '@styled-icons/fa-solid/ArrowLeft'
import { MissionType } from 'Post/Modal/MissionProgress/index'
import { MissionUser } from 'Post/api'
import dayjs from 'dayjs'

type Props = {
    onClose: () => void
    missionType: MissionType
    users: MissionUser[]
}

export default function Completed({ onClose, missionType, users }: Props) {
    return (
        <Modal onClose={onClose} size="medium">
            <div className="flex flex-col h-full overflow-scroll">
                <div className="w-full my-6 flex flex-row items-center justify-center">
                    <button type="button" onClick={onClose} className="ml-8">
                        <ArrowLeft size={26} />
                    </button>
                    <span className="text-2xl text-black font-semibold w-full text-center">
                        {missionType === MissionType.lecture
                            ? 'Lecture Mission'
                            : 'Looping Mission'}
                    </span>
                    <button type="button" className="mr-8 invisible">
                        <ArrowLeft size={26} />
                    </button>
                </div>
                {users.map((user) => (
                    <div
                        key={user.id}
                        className="flex flex-row py-2.5 px-4 items-center"
                    >
                        <img
                            className="w-10 h-10 rounded-3xl"
                            alt={`${user.name} Profile Image`}
                            src={user.profileImage}
                        />
                        <span className="ml-2">
                            <span className="font-semibold text-lg">
                                {user.name}
                            </span>
                            <span className="font-normal text-black text-lg">
                                &nbsp;completed the mission at&nbsp;
                            </span>
                            <span className="font-normal text-black text-lg">
                                {dayjs(user.updatedAt).format(
                                    'hh:mm A, MMM DD, YYYY',
                                )}
                            </span>
                        </span>
                    </div>
                ))}
            </div>
        </Modal>
    )
}
