const urlRegex = /(https?:\/\/[^\s]+)/g
const topDomains = [
    /\.com/,
    /\.de/,
    /\.org/,
    /\.net/,
    /\.us/,
    /\.co/,
    /\.edu/,
    /\.gov/,
    /\.biz/,
    /\.za/,
    /\.info/,
    /\.cc/,
    /\.ca/,
    /\.cn/,
    /\.fr/,
    /\.ch/,
    /\.au/,
    /\.in/,
    /\.jp/,
    /\.be/,
    /\.it/,
    /\.nl/,
    /\.uk/,
    /\.mx/,
    /\.no/,
    /\.ru/,
    /\.br/,
    /\.se/,
    /\.es/,
    /\.at/,
    /\.dk/,
    /\.eu/,
    /\.il/,
]
export const urlify = (text: string): string => {
    const urls = text.match(urlRegex)
    if (urls) {
        return text.replace(urls[0], '')
    }
    return text
}
const someStackOverflowURLRegex = /(?:https?:\/\/)?(?:www\.)?(?:[\da-z-]+\.)+[a-z]{2,10}(?:\/[^\s]+)*\/?\s/gi
const someStackOverflowURLRegexNoSpace = /(?:https?:\/\/)?(?:www\.)?(?:[\da-z-]+\.)+[a-z]{2,10}(?:\/[^\s]+)*\/?/gi
const enterMatch = /(?:https?:\/\/)?(?:www\.)?(?:[\da-z-]+\.)+[a-z]{2,10}(?:\/[^\s]+)*\/?</gi
const newLineMatch = />(?:https?:\/\/)?(?:www\.)?(?:[\da-z-]+\.)+[a-z]{2,10}(?:\/[^\s]+)*\/?</gi

export const containsUrl = (text: string) => {
    // eslint-disable-next-line prettier/prettier
  return text.match(someStackOverflowURLRegex) ||
        text.match(newLineMatch) ||
        text.match(enterMatch)
        ? true
        : false
}

export const containsCommentUrl = (text: string) => {
    // eslint-disable-next-line prettier/prettier
  return text.match(someStackOverflowURLRegex) ||
        text.match(newLineMatch) ||
        text.match(enterMatch) ||
        text.match(someStackOverflowURLRegexNoSpace)
        ? true
        : false
}

export const getFirstUrl = (text: string): string => {
    if (text.match(someStackOverflowURLRegex)) {
        const urls = text.match(someStackOverflowURLRegex)
        return urls ? urls[0] : ''
    }
    if (text.match(newLineMatch)) {
        const urls = text.match(newLineMatch)
        return urls ? urls[0].replace('>', '').replace('<', '') : ''
    }

    if (text.match(enterMatch)) {
        const urls = text.match(enterMatch)
        return urls ? urls[0].replace('<', '') : ''
    }
    if (text.match(someStackOverflowURLRegexNoSpace)) {
        const urls = text.match(someStackOverflowURLRegexNoSpace)
        return urls ? urls[0] : ''
    }

    return ''
}
