import React from 'react'
import { Post } from 'Post/types'
import { Comment } from 'Post/Comment/types'
import CommentItem from 'Post/Comment/Item'

type Props = {
    post: Post
    allComments: Comment[]
    scrollingElementRef?: { current: HTMLDivElement | null }
    highlightedCommentId?: number
    setHighlightedCommentId(id?: number): void
}

export default function List({
    post,
    allComments,
    scrollingElementRef,
    highlightedCommentId,
    setHighlightedCommentId,
}: Props) {
    return (
        <>
            {allComments.map((comment) => (
                <CommentItem
                    key={comment.id}
                    post={post}
                    comment={comment}
                    indentBorder={!!comment.parentCommentId}
                    scrollingElementRef={scrollingElementRef}
                    highlightedCommentId={highlightedCommentId}
                    setHighlightedCommentId={setHighlightedCommentId}
                />
            ))}
        </>
    )
}
