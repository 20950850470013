import React from 'react'
import style from 'Home/style.module.css'
import { Post as PostType } from 'Post/types'
import cn from 'classnames'
import ReadMore from 'Post/Card/ReadMore'
import useToggle from 'utils/useToggle'
import Menu from 'Post/Card/Menu'
import { DotsHorizontalRounded } from '@styled-icons/boxicons-regular/DotsHorizontalRounded'
import CommentForm from 'Post/Comment/Form'
import Attachments from 'Post/Attachments'
import Spin from 'assets/images/icons/Spin'
import { formatDate } from 'utils/date'
import Text from 'Post/Text'
import PostBottomPanel from 'Post/Card/BottomPanel'
import CommentsModal from 'Post/Modal'
import PostTitle from 'Post/Card/Title'
import OnlyForMembersAlert from 'Post/Card/OnlyForMembersAlert'
import AddSentence from 'Notebook/AddSentence'
import { Link } from 'react-router-dom'
import routes from 'routes'
import CorrectionModal from 'Post/Card/CorrectionModal'
import BannerStatic from 'Shared/BannerStatic'
import StudyFlowBanner from 'Post/Card/StudyFlowBanner'
import Logo from 'assets/images/logo.svg'
import ScheduledMenu from 'Post/Card/Menu/ScheduledMenu'
import { updateCache } from 'Post/cacheActions'
import { missionUpdate } from 'Post/api'
import { MissionType } from 'Post/Modal/MissionProgress'
import { queryClient } from 'utils/queryClient'

type Props = {
    post: PostType
    className?: string
    highlightText?: string
    disableComment?: boolean
    disableSocial?: boolean
    bypassMemberOnly?: boolean
}

const Post = ({
    post,
    className,
    disableComment,
    disableSocial,
    bypassMemberOnly,
    highlightText,
}: Props) => {
    const textRef = React.useRef(null)
    const [showOnlyForMembersAlert, toggleOnlyForMembersAlert] = useToggle()
    const [openAddToNotebook, toggleAddToNotebook] = useToggle()
    const [openCorrectionModal, toggleCorrectionModal] = useToggle()
    const [showFullText, toggleShowFullText] = useToggle(false)
    const [openComments, setOpenComments] = React.useState(false)

    const toggleComments = () => setOpenComments(!openComments)

    const tryToOpenComments = () => {
        if (disableComment) {
            return
        }
        if (post.classes.length === 0 || post.joinedToClass)
            setOpenComments(true)
        else toggleOnlyForMembersAlert()
    }

    const onLoopingFinished = async () => {
        const currentCount = post.currentLoopingCount
        if (post.loopingMissionCount > 0 && currentCount !== undefined) {
            post.currentLoopingCount = currentCount + 1
            updateCache(post.id, {
                currentLoopingCount: currentCount + 1,
            })
            await missionUpdate({
                post,
                missionType: MissionType.looping,
                count: currentCount + 1,
            })
            if (post.loopingMissionCount === currentCount + 1) {
                await queryClient.invalidateQueries(`mission${post.id}`)
            }
        }
    }

    const [highlightedCommentId, setHighlightedCommentId] = React.useState<
        number | undefined
    >(undefined)

    if (post.user.id === 0) {
        return (
            <>
                {openComments && (
                    <CommentsModal
                        post={post}
                        onClose={toggleComments}
                        commentId={highlightedCommentId}
                        setCommentId={setHighlightedCommentId}
                    />
                )}
                <div
                    className={cn(
                        'bg-white shadow relative flex flex-col',
                        className || 'mb-5',
                    )}
                >
                    <DotsHorizontalRounded
                        size={24}
                        className="absolute top-0 right-0 mt-8 mr-5"
                    />
                    <div className="px-5 pt-5">
                        <div className="flex-center mb-3">
                            <img
                                src={Logo}
                                alt="avatar"
                                style={{ width: '60px', height: '60px' }}
                                className="mr-3 rounded-full"
                            />
                            <div className="flex-grow">
                                <div className="text-gray-b0 text-sm">
                                    <div className="text-xl text-gray-02">
                                        <span className="font-bold">
                                            SCHOOOL
                                        </span>
                                    </div>
                                    <span>Today</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex-center mb-4 text-blue-primary w-full py-150px">
                            <Spin className="animate-spin h-7 w-7 mr-3" />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {openAddToNotebook && (
                <AddSentence
                    onClose={toggleAddToNotebook}
                    title="Send to my notebook"
                    buttonText="Add"
                    contentClass="pt-4 px-5 pb-6"
                    buttonWrapClass="flex-center mt-5"
                    sentence={{
                        text: post.notebookSentence?.text || post.text,
                        translation: post.notebookSentence?.translation || '',
                    }}
                />
            )}
            {openCorrectionModal && (
                <CorrectionModal
                    text={post.text}
                    postId={post.id}
                    commentsCount={post.commentsCount}
                    onClose={toggleCorrectionModal}
                />
            )}
            {openComments && (
                <CommentsModal
                    post={post}
                    onClose={toggleComments}
                    commentId={highlightedCommentId}
                    setCommentId={setHighlightedCommentId}
                    autoFocus={true}
                />
            )}
            {showOnlyForMembersAlert && (
                <OnlyForMembersAlert onClose={toggleOnlyForMembersAlert} />
            )}
            <div
                className={cn(
                    'bg-white shadow relative flex flex-col',
                    className || 'mb-5',
                )}
            >
                {post.id < 0 && (
                    <ScheduledMenu
                        post={post}
                        button={({ onClick }) => (
                            <button type="button" onClick={onClick}>
                                <DotsHorizontalRounded size={24} />
                            </button>
                        )}
                        className="absolute top-0 right-0 mt-8 mr-5"
                    />
                )}
                {post.id > 0 && (
                    <Menu
                        post={post}
                        button={({ onClick }) => (
                            <button type="button" onClick={onClick}>
                                <DotsHorizontalRounded size={24} />
                            </button>
                        )}
                        className="absolute top-0 right-0 mt-8 mr-5"
                    />
                )}

                <div className="px-5 pt-5">
                    {(post.isUploading || post.error) && (
                        <div
                            className={cn(
                                'flex-center mb-4',
                                post.error
                                    ? 'text-red-600'
                                    : 'text-blue-primary',
                            )}
                        >
                            {!post.error && (
                                <Spin className="animate-spin h-5 w-5 mr-3" />
                            )}
                            <div
                                className={cn(
                                    'text-xl font-bold text-center',
                                    !post.error && 'animate-pulse',
                                )}
                            >
                                {post.error || 'Uploading'}
                            </div>
                        </div>
                    )}
                    <div className="flex-center mb-3">
                        <Link
                            to={routes.user(post.user.id)}
                            className="flex-shrink-0"
                        >
                            <img
                                src={post.user.avatar}
                                alt="avatar"
                                style={{ width: '60px', height: '60px' }}
                                className="mr-3 rounded-full"
                            />
                        </Link>
                        <div className="flex-grow">
                            <div className="text-gray-b0 text-sm">
                                <PostTitle post={post} />
                                {post.id > 0 && formatDate(post.date)}
                                {post.id < 0 &&
                                    'Scheduled at ' +
                                        post.date
                                            .local()
                                            .format('MMM DD, YYYY h:mm A')}
                            </div>
                        </div>
                    </div>
                    {post.allow_correction === 1 && (
                        <div className="text-green-normal">
                            Please correct mistakes in the sentence below.
                        </div>
                    )}

                    <Text
                        className="text-gray-02 mb-3"
                        data={post}
                        textRef={textRef}
                        showFullText={showFullText}
                        highlightText={highlightText}
                    />
                    {post.preview_image && post.preview_title && post.link && (
                        <BannerStatic
                            previewImage={post.preview_image}
                            previewTitle={post.preview_title}
                            url={post.link}
                        />
                    )}

                    {post.shared_flow_id &&
                    post.shared_flow_title &&
                    post.shared_flow_username ? (
                        <StudyFlowBanner post={post} />
                    ) : null}

                    <div className="flex items-end justify-end">
                        {post.notebookSentence && (
                            <div className="flex-grow">
                                <button
                                    type="button"
                                    onClick={toggleAddToNotebook}
                                    className={`text-blue-primary uppercase mb-1 ${style.text}`}
                                >
                                    Send to notebook
                                </button>

                                <div
                                    className={`text-gray-87 uppercase mb-1 ${style.text}`}
                                >
                                    {post.notebookSentence.text}
                                </div>

                                <div
                                    className={`text-gray-87 uppercase mb-1 font-bold font-italic ${style.text}`}
                                >
                                    {post.notebookSentence.translation}
                                </div>
                            </div>
                        )}
                        <ReadMore
                            showFullText={showFullText}
                            toggleShowFullText={toggleShowFullText}
                            bypassMemberOnly={bypassMemberOnly}
                            post={post}
                            textRef={textRef}
                        />
                    </div>
                    {post.allow_correction === 1 && (
                        <button
                            onClick={toggleCorrectionModal}
                            className="border border-blue-primary text-blue-primary rounded-full inline cursor-pointer px-4 mt-2 py-1"
                        >
                            Correct Mistakes
                        </button>
                    )}
                </div>

                <Attachments
                    audioClass="px-5 pt-5"
                    linkClass="mx-5 mt-5"
                    imageClass="w-full mt-5"
                    videoClass="mt-5"
                    attachments={{
                        ...post,
                        loopingCount:
                            post.loopingMissionCount > 0
                                ? post.currentLoopingCount
                                : undefined,
                    }}
                    onLoopingFinished={onLoopingFinished}
                />

                {!disableSocial && (
                    <PostBottomPanel
                        post={post}
                        toggleComments={tryToOpenComments}
                        toggleAddToNotebook={toggleAddToNotebook}
                    />
                )}

                {!disableComment && (
                    <CommentForm
                        post={post}
                        className="pt-6 pb-3 pl-5 pr-8 flex-center"
                        minHeight={28}
                        onSuccess={(comment) => {
                            setOpenComments(true)
                            setHighlightedCommentId(comment.id)
                        }}
                    />
                )}
            </div>
        </>
    )
}

export default Post
