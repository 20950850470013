import React from 'react'
import api from 'api'
import Loader from 'Shared/Loader'
import { useMutation, useQueryClient } from 'react-query'
import Modal from 'Shared/Modal'
import { Class } from 'Class/types'
import { getCurrentUserId } from 'User/currentUser'
import { updateClassCache } from 'Class/cacheActions'

type Props = {
    onClose(): void
    item: Class
}

export default function LeaveModal({ onClose, item }: Props) {
    const queryClient = useQueryClient()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { mutate, isLoading } = useMutation(api.classes.removeMember, {
        onSuccess: (data) => queryClient.setQueryData(['class'], data),

        onSettled() {
            updateClassCache(item.id, { isJoined: false })

            onClose()
        },
    })
    const leaveClass = () => {
        mutate({ classId: item.id, userId: getCurrentUserId() })
    }
    return (
        <Modal
            // onClose={() =>}
            className="text-center"
        >
            <div className="p-8 px-12">
                <div className="text-xl font-black mb-4">
                    ARE YOU SURE TO{' '}
                    <div className="text-red-5a inline">LEAVE THIS CLASS?</div>
                </div>
                <div className="text-lg">
                    If you leave this class, you can't see the posts of this
                    class anymore
                </div>
            </div>

            <div className="flex border-t-2 justify-center px-8 py-6 border-gray-d6">
                {isLoading ? (
                    <Loader className="w-8 h-8" color="#06ACEE" />
                ) : (
                    <>
                        <button
                            onClick={onClose}
                            className="w-full rounded-full text-gray-2a"
                            // disabled={isLoading}
                        >
                            Cancel
                            {/* {!isLoading && item.id ? 'Update' : 'Create'} */}
                        </button>
                        <button
                            onClick={leaveClass}
                            className="w-full rounded-full text-white bg-red-5a font-bold flex-center h-10"
                        >
                            Leave
                        </button>
                    </>
                )}
            </div>
        </Modal>
    )
}
