import { updatePages } from 'utils/queryClient'
import { Comment } from 'Post/Comment/types'
import { update } from './api'

export const addToCache = (newComment: Comment, postId: number) => {
    updatePages<Comment[][]>([`comments${postId}`], (pages) =>
        pages.map((comments, i) => {
            if (i === 0) {
                const newComments = comments.concat([newComment])
                let pinnedId = -1
                const pinnedComment = newComments.filter(
                    (c) => (c.pinned ?? 0) > 0,
                )
                if (pinnedComment.length > 0) {
                    pinnedId = pinnedComment[0].id
                }
                newComments.sort((c1, c2) => {
                    if (c1.pinned != c2.pinned) {
                        return (c2.pinned ?? 0) - (c1.pinned ?? 0)
                    } else if (c1.isLike != c2.isLike) {
                        return (c1.isLike ? 1 : 0) - (c2.isLike ? 1 : 0)
                    }
                    if (
                        c1.parentCommentId === pinnedId ||
                        c2.parentCommentId === pinnedId
                    ) {
                        if (c1.parentCommentId != c2.parentCommentId) {
                            if (c1.parentCommentId === pinnedId) {
                                return -1
                            } else if (c2.parentCommentId === pinnedId) {
                                return 1
                            }
                        }
                    }
                    const c1Id = c1.parentCommentId ?? c1.id
                    const c2Id = c2.parentCommentId ?? c2.id
                    if (c1Id === c2Id) {
                        const c1ActualReplyId =
                            c1.inReplyTo?.id && c1.inReplyTo.id > 0
                                ? c1.inReplyTo.id
                                : c1.id
                        const c2ActualReplyId =
                            c2.inReplyTo?.id && c2.inReplyTo.id > 0
                                ? c2.inReplyTo.id
                                : c2.id
                        if (c1ActualReplyId === c2ActualReplyId) {
                            return c1.id - c2.id
                        }
                        return c1ActualReplyId - c2ActualReplyId
                    }
                    return c1Id - c2Id
                })
                return newComments
            } else {
                return comments
            }
        }),
    )
}

export const updateCache = (updatedComment: Comment, postId: number) => {
    updatePages<Comment[][]>([`comments${postId}`], (pages) =>
        pages.map((comments) =>
            comments.map((c) =>
                c.id === updatedComment.id ? updatedComment : c,
            ),
        ),
    )
}

export const updatePinCache = (
    updatedComment: Comment,
    postId: number,
    pinned: boolean,
) => {
    updatePages<Comment[][]>([`comments${postId}`], (pages) => {
        return pages.map((comments) => {
            if (pinned) {
                return [
                    updatedComment,
                    ...comments.filter((c) => c.id !== updatedComment.id),
                ]
            } else {
                return comments.map((c) => {
                    if (c.pinned === 1) {
                        return { ...c, pinned: 0 }
                    }
                    return c
                })
            }
        })
    })
}

export const removeFromCache = (commentId: number, postId: number) => {
    updatePages<Comment[][]>([`comments${postId}`], (pages) =>
        pages.map((comments) => comments.filter((c) => c.id !== commentId)),
    )
}

export const replaceComments = (comments: Comment[], postId: number) => {
    updatePages<Comment[][]>([`comments${postId}`], (pages) =>
        pages.map(() => comments),
    )
}
