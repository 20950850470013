import React from 'react'
import { Post } from 'Post/types'
import { MissionRecordUser } from 'Post/api'
import Completed from 'Post/Modal/RecordMission/Completed'

export enum MissionType {
    lecture,
    looping,
}

export type Props = {
    post: Post
    users: MissionRecordUser[]
}

export default function RecordMission({
    post,
    users,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
    const [showCompleted, setShowCompleted] = React.useState(false)

    const onMissionClick = () => {
        setShowCompleted(true)
    }
    const closeMission = () => {
        setShowCompleted(false)
    }

    return (
        <div className="flex flex-col" onClick={onMissionClick}>
            <div className="bg-gray-dc h-px" />
            <div className="flex flex-col py-6 px-8">
                <span className="font-semibold text-lg">RECORDING MISSION</span>
                <span className="mt-1 font-normal text-base text-black">
                    Members who submitted the recorded file are displayed below.
                    Tap any profile icon to see the details.
                </span>
                <div className="flex flex-row mt-4 space-x-2.5 w-full overflow-x-scroll flex-grow">
                    {users.map((user) => (
                        <img
                            key={user.missionId}
                            className="w-10 h-10 rounded-3xl"
                            alt={`${user.name} Profile Image`}
                            src={user.profileImage}
                        />
                    ))}
                </div>
            </div>
            {showCompleted && (
                <Completed onClose={closeMission} users={users} post={post} />
            )}
        </div>
    )
}
