import React from 'react'
import Loader from 'Shared/Loader'
import cn from 'classnames'

export default function LoadingPage({
    className,
}: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div className="h-full w-full flex flex-col">
            <div
                className={cn(
                    className,
                    'my-9 bg-white h-full rounded shadow-lg flex flex-row justify-center items-center',
                )}
            >
                <Loader
                    className="w-20 h-20 rounded text-blue-primary blue-primary"
                    color="#06ACEE"
                />
            </div>
        </div>
    )
}
