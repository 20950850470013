import { del, get, post, put } from 'utils/apiUtils'
import {
    Candidate,
    Organization,
    OrganizationPostGroup,
    PendingCandidate,
    PostedCandidate,
} from 'Class/Management/types'
import dayjs from 'dayjs'

export const organizations = get(() => ({
    path: '/class_management/organization',
    response(res: Organization): Organization {
        return res
    },
}))

export const groups = get(() => ({
    path: '/class_management/groups',
    response(res: OrganizationPostGroup[]): OrganizationPostGroup[] {
        return res
    },
}))

type PendingResponse = {
    id: number
    title: string
    candidate_id: number
    scheduled_at: string
}
function mapPendingCandidate({
    id,
    title,
    candidate_id,
    scheduled_at,
}: PendingResponse): PendingCandidate {
    return {
        id,
        title,
        candidateId: candidate_id,
        scheduledAt: dayjs(scheduled_at, { utc: true }),
    }
}

type PostedResponse = {
    id: number
    title: string
    created_at: string
}
function mapPostedCandidate({
    id,
    title,
    created_at,
}: PostedResponse): PostedCandidate {
    return {
        id,
        title,
        postedAt: dayjs(created_at, { utc: true }),
    }
}

type RemainingResponse = {
    id: number
    group_id: number
    title: string
    share_post_id: number
}
function mapRemainingCandidate({
    id,
    title,
    share_post_id,
}: RemainingResponse): Candidate {
    return {
        id,
        title,
        shareId: share_post_id,
        dateToPost: undefined,
    }
}

type CandidateResponse = {
    postedCandidates: PostedResponse[]
    pendingCandidates: PendingResponse[]
    remainingCandidates: RemainingResponse[]
}
export type CandidateMapped = {
    postedCandidates: PostedCandidate[]
    pendingCandidates: PendingCandidate[]
    remainingCandidates: Candidate[]
}
export const candidates = get(
    ({ groupId, classId }: { groupId: number; classId: number }) => ({
        path: `/class_management/managed_post/${classId}`,
        params: {
            groupId,
        },
        response(data: CandidateResponse): CandidateMapped {
            return {
                postedCandidates: data.postedCandidates.map(mapPostedCandidate),
                pendingCandidates: data.pendingCandidates.map(
                    mapPendingCandidate,
                ),
                remainingCandidates: data.remainingCandidates.map(
                    mapRemainingCandidate,
                ),
            }
        },
    }),
)

export const cancelPost = del(({ scheduleId }: { scheduleId: number }) => ({
    path: `/class_management/managed_post/${scheduleId}`,
}))

export const schedulePost = post(
    ({
        candidateId,
        classId,
        scheduledAt,
    }: {
        candidateId: number
        classId: number
        scheduledAt: string
    }) => ({
        path: `/class_management/managed_post/${candidateId}`,
        data: {
            class_id: classId,
            scheduled_at: scheduledAt,
        },
        response(data: { id: number }): number {
            return data.id
        },
    }),
)

export const addMember = post(
    ({ classId, userId }: { classId: number; userId: number }) => ({
        path: '/class_management/add_member',
        data: {
            classId,
            userId,
        },
    }),
)
