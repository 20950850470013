import React from 'react'
import { OrganizationMember } from 'Admin/types'
import cn from 'classnames'
import api from 'api'
import { Organization } from 'Class/Management/types'
import TwoButtonAlert from '../Shared/Modal/TwoButtonAlert'
import { removeUserFromOrganization } from 'Admin/cacheActions'
import Loader from "../Shared/Loader";

type Props = {
    organization: Organization
    member: OrganizationMember
}

export default function OrganizationMemberRow({
    organization,
    member,
    className,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
    const [isShowingConfirmAlert, setShowingConfirmAlert] = React.useState(
        false,
    )
    const [isLoading, setLoading] = React.useState(false)

    const onRemoveClick = React.useCallback(() => {
        setShowingConfirmAlert(true)
    }, [setShowingConfirmAlert])
    const onDismiss = React.useCallback(() => {
        setShowingConfirmAlert(false)
    }, [setShowingConfirmAlert])
    const onConfirmed = React.useCallback(async () => {
        if (isLoading) {
            return
        }
        setLoading(true)
        setShowingConfirmAlert(false)
        await api.admin.removeUserFromOrganization(organization.id, member.id)
        removeUserFromOrganization(organization.id, member.id)
        setLoading(false)
    }, [setShowingConfirmAlert, isLoading, setLoading, organization, member])

    return (
        <div className={cn('flex flex-row items-center p-1', className)}>
            <img className="w-10 h-10 rounded-full" src={member.profileImage} />
            <span className="ml-3 flex-grow">
                {member.id} - {member.name}
            </span>
            <span
                className="bg-blue-primary text-white p-1 rounded cursor-pointer"
                onClick={onRemoveClick}
            >
                {!isLoading && 'Remove User'}
                {isLoading && <Loader className="w-3 h-3 mx-12" />}
            </span>
            {isShowingConfirmAlert && (
                <TwoButtonAlert
                    title="Are you sure?"
                    content={`User ${member.name} will be removed from ${organization.name}`}
                    leftTitle="Cancel"
                    leftClassName="text-lg"
                    rightTitle="Remove user"
                    rightClassName="text-red-700"
                    onLeftClick={onDismiss}
                    onRightClick={onConfirmed}
                />
            )}
        </div>
    )
}
