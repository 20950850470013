/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack'
import RadioGroup from 'Shared/Form/RadioGroup'
import Expressions from 'Studyflow/Form/Expressions'
import useToggle from 'utils/useToggle'
import DeleteModal from 'Shared/Modal/Delete'
import DuplicateModal from 'Studyflow/Form/Update/DuplicateModal'
import Input from 'Shared/Form/Input'
import SelectTarget from 'Studyflow/Form/SelectTarget'
import { useForm } from 'Shared/Form'
import {
    Expression,
    StudyFlow,
    StudyFlowResponse,
    StudyFlowLevel,
    StudyFlowType,
    ExpressionResponse,
} from 'Studyflow/types'
import { useMutation, useQueryClient } from 'react-query'
import api from 'api'
import Loader from 'Shared/Loader'
import { filterCache, updateCache } from 'Studyflow/cacheActions'
import useCreateStudyFlow from '../Create/useCreate'

type Props = {
    onClose(): void
    currentStudyFlow: StudyFlowResponse
    type: StudyFlowType
}
const classes = {
    blueFormGroup: 'bg-blue-light pt-3 pb-2 px-4 border-b border-gray-c5',
    blueFormGroupLabel: 'uppercase text-gray-6b text-sm mb-1 block',
    radioGroup: {
        group: 'flex items-center',
        root: 'flex-center mr-5',
        input: 'w-10px h-10px ml-5px',
        error: 'text-red-600 mt-2',
    },
}
const privacyValues = ['onlyForMe', 'public', 'shared']
const levelValues = ['basic', 'intermediate', 'advanced']
const levelOptions = [
    { value: 'basic', label: 'Basic' },
    { value: 'intermediate', label: 'Intermediate' },
    { value: 'advanced', label: 'Advanced' },
]

const UpdateFormValues = ({ currentStudyFlow, type, onClose }: Props) => {
    const [showDeleteModal, toggleDeleteModal] = useToggle()
    const [showDuplicateModal, toggleDuplicateModal] = useToggle()
    const [isDuplicate, toggleDuplicate] = useToggle()
    const [error, setError] = useState<any>()
    const [isPublic, setIsPublic] = useState(currentStudyFlow.is_public === 1)
    const [userIds, setUserIds] = useState<number[]>(
        currentStudyFlow.shared_users
            ?.split('|')
            ?.map((user) => parseInt(user.split(',')[0]))
            ?.filter((userId) => !isNaN(userId)) ?? [],
    )
    const [updatedStudyFlow, setUpdateStudyFlow] = useState<StudyFlow>()
    const queryClient = useQueryClient()
    const classIds =
        currentStudyFlow.shared_classes
            ?.split('|')
            ?.map((user) => parseInt(user.split(',')[0]))
            ?.filter((userId) => !isNaN(userId)) ?? []
    const classSelected = classIds.length > 0

    const { mutate, isLoading: isUpdating } = useMutation(
        api.studyFlow.update,
        {
            onSuccess: (flow: any) =>
                queryClient.removeQueries(['studyFlow.get', { id: flow.id }]),
            onSettled(flow: any, error) {
                if (error) {
                    setError("Couldn't update")
                } else {
                    setUpdateStudyFlow(flow)
                    updateCache(flow)
                    onClose()
                    // filterCache(({ id }) => id !== updatedStudyFlow.id)
                }
            },
        },
    )
    const { create, createdStudyFlow, isCreating } = useCreateStudyFlow({
        type,
        setError,
        isPublic,
        userIds,
        classIds,
        onClose,
    })
    const isLoading = isUpdating || isCreating

    const renderConversation = (conversations: ExpressionResponse[]) => {
        return conversations.map((e: any) => ({
            eId: e.id,
            question: e.expression,
            questionNative: e.native,
            answer: e.answers,
            answerNative: e.native_answer,
        }))
    }
    const renderRepetition = (repetitions: ExpressionResponse[]) => {
        return repetitions.map((e: any) => ({
            eId: e.id,
            sentence: e.expression,
            sentenceNative: e.native,
        }))
    }
    const form = useForm({
        defaultValues: {
            title: currentStudyFlow.title,
            label: '',
            level: levelValues[currentStudyFlow.level],
            privacy:
                privacyValues[
                    userIds.length > 0 || classSelected
                        ? 2
                        : currentStudyFlow.is_public
                ],
            expressions:
                type === 'conversation' && currentStudyFlow.expressions
                    ? renderConversation(currentStudyFlow.expressions)
                    : renderRepetition(currentStudyFlow.expressions),
        },
    })

    const update = (values: {
        title: string
        level: StudyFlowLevel
        privacy: boolean
        expressions: Expression[]
    }) => {
        if (!values || isLoading || updatedStudyFlow || createdStudyFlow) return
        if (isDuplicate) {
            create(values)
            return
        }
        mutate({
            id: currentStudyFlow.id,
            type,
            lingo_id: currentStudyFlow.lingo_id,
            title: values.title,
            level: values.level,
            isPublic,
            userIds,
            classIds,
            profile_image: currentStudyFlow.profile_image,
            expressions: values.expressions,
        })
    }

    const handleDelete = async () => {
        try {
            await api.studyFlow.remove({ id: currentStudyFlow.id })
            filterCache(({ id }) => id !== currentStudyFlow.id)
            onClose()
        } catch (error) {
            setError(error)
        }
    }

    // eslint-disable-next-line
    const submit = update as any

    return (
        <>
            {showDeleteModal && (
                <DeleteModal
                    onClose={toggleDeleteModal}
                    onDelete={handleDelete}
                />
            )}
            {showDuplicateModal && (
                <DuplicateModal
                    onClose={toggleDuplicateModal}
                    onDuplicate={toggleDuplicate}
                />
            )}
            <form
                onSubmit={form.handleSubmit(submit)}
                className="flex flex-grow flex-1 min-h-0"
            >
                <div className="flex-1 overflow-auto">
                    {error && (
                        <div className="text-red-500 text-center mt-4">
                            {error.message ? error.message : error}
                        </div>
                    )}
                    <div className="text-xl flex-center py-7 relative border-b border-gray-c5">
                        <button
                            type="button"
                            className="absolute left-0 ml-4 text-gray-5f"
                            style={{ top: '26px' }}
                            onClick={onClose}
                        >
                            <ArrowBack size={26} />
                        </button>
                        {isDuplicate ? 'Duplicate StudyFlow' : 'EDIT'}
                        {!isDuplicate && (
                            <button
                                type="button"
                                className="absolute right-0 mr-4 text-black text-15 pl-3 pr-3 pt-0.5 pb-0.5 bg-gray-dc rounded-full border-gray-4f border-0.5"
                                onClick={toggleDuplicateModal}
                            >
                                Duplicate
                            </button>
                        )}
                    </div>
                    <Input
                        form={form}
                        name="title"
                        label="Title"
                        placeholder="Enter title"
                        classes={{
                            root: classes.blueFormGroup,
                            label: classes.blueFormGroupLabel,
                            input: 'placeholder-gray-a4',
                            error: 'text-red-600 mt-2',
                        }}
                    />
                    <div className={classes.blueFormGroup}>
                        <div className={classes.blueFormGroupLabel}>
                            Level of difficulty
                        </div>
                        <RadioGroup
                            form={form}
                            name="level"
                            values={levelOptions}
                            classes={classes.radioGroup}
                        />
                    </div>
                    <SelectTarget
                        form={form}
                        isPublic={isPublic}
                        setIsPublic={setIsPublic}
                        userIds={userIds}
                        setUserIds={setUserIds}
                        classes={{
                            root: classes.blueFormGroup,
                            label: classes.blueFormGroupLabel,
                            radioGroup: classes.radioGroup,
                        }}
                        disabled={classSelected}
                    />
                    <Expressions type={type} form={form} />
                    <div className="px-5 mt-7">
                        <div className="flex-center pb-5">
                            <button
                                className="bg-blue-primary w-full h-10 flex-center text-white rounded-full font-bold"
                                onSubmit={onClose}
                                disabled={isLoading}
                            >
                                {isLoading && <Loader className="w-5 h-5" />}
                                {!isLoading && 'Done'}
                            </button>
                            {!isDuplicate && (
                                <button
                                    type="button"
                                    className="bg-red-58 w-full h-10 flex-center text-white rounded-full font-bold ml-3"
                                    onClick={toggleDeleteModal}
                                    disabled={isLoading}
                                >
                                    Delete
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default UpdateFormValues
