import React from 'react'
import { useMutation } from 'react-query'
import ActionButton from './ActionButton'

import api from 'api'
import { Comment } from 'Post/Comment/types'
import { queryClient } from 'utils/queryClient'
import useRecords from 'utils/useRecords'
import { updateCache } from 'Post/cacheActions'
import { replaceComments } from 'Post/Comment/cacheActions'

type Props = {
    share_id: number
    review_id: number
    pinned?: number
    comment: Comment
    setOpenMenu(arg: boolean): void
    postId: number
}

const PinToTop = ({
    comment,
    share_id,
    review_id,
    pinned,
    postId,
    setOpenMenu,
}: Props) => {
    const [error, setError] = React.useState<Error | unknown>()
    const { mutate, isLoading } = useMutation(api.comment.pin, {
        onSettled(data, error) {
            if (data && !error) {
                try {
                    replaceComments(data, share_id)
                    // queryClient.setQueryData(['comments'], data)
                    setOpenMenu(false)
                } catch {
                    setError("Couldn't update comments")
                }
            } else {
                setError(error)
            }
        },
    })

    const pinNote = () => {
        mutate({
            pinned: pinned ? 0 : 1,
            share_id,
            review_id,
            postId,
        })
    }
    return (
        <ActionButton onClick={pinNote} loading={isLoading}>
            {pinned ? 'Remove From Top' : 'Pin To Top'} {error}
        </ActionButton>
    )
}

export default PinToTop
