import React from 'react'
import { NotebookSentence } from 'Notebook/types'
import CircleCheckbox from 'Shared/CircleCheckbox'
import useToggle from 'utils/useToggle'
import { useMutation } from 'react-query'
import api from 'api'
import { Play } from '@styled-icons/boxicons-regular/Play'
import { Pause } from '@styled-icons/boxicons-regular/Pause'

type Props = {
    sentence: NotebookSentence
    selecting: boolean
    checkedIds: Record<number, boolean>
    toggleItem(id: number): void
}

export default function SentenceItem({
    sentence,
    selecting,
    checkedIds,
    toggleItem,
}: Props) {
    const playTimes = 1
    const playAgainTimeoutMs = 200
    const textRef = React.useRef<HTMLDivElement>(null)
    const translationRef = React.useRef<HTMLDivElement>(null)
    const [height, setHeight] = React.useState<number>()
    const [audioUrl, setAudioUrl] = React.useState<string>()
    const [loading, setLoading] = React.useState(false)
    const [showTranslation, toggleTranslation] = useToggle()
    const [isPlaying, setPlaying] = React.useState(false)
    const [, setPlayedTimes] = React.useState(0)
    const [playAgainTimeout, setPlayAgainTimeout] = React.useState(0)
    const [audio] = React.useState(() => {
        const audio = new window.Audio()
        audio.onplay = () => setPlaying(true)
        audio.onpause = () => {
            setPlaying(false)
            setLoading(false)
        }

        audio.onended = () => {
            setPlayedTimes((times) => {
                const value = times + 1
                if (value < playTimes) {
                    setPlayAgainTimeout(
                        () =>
                            (setTimeout(() => {
                                audio.currentTime = 0
                                audio.play()
                            }, playAgainTimeoutMs) as unknown) as number,
                    )
                }
                return value
            })
        }
        return audio
    })
    React.useEffect(() => {
        const text = textRef.current
        const translation = translationRef.current
        if (text && translation) {
            setHeight(Math.max(text.offsetHeight, translation.offsetHeight))
        }
    }, [])
    const { mutate: loadAudio, isLoading: isLoadingAudio } = useMutation(
        api.app.speech,
        {
            onSuccess(url) {
                setAudioUrl(url)
                audio.src = url
                audio.play()
            },
        },
    )

    const toggleSpeech = (e: any) => {
        e.stopPropagation() //  <------ Here is the magic
        setLoading(true)

        if (isPlaying) {
            clearTimeout(playAgainTimeout)
            audio.pause()
        } else if (audioUrl) {
            setPlayedTimes(0)
            audio.currentTime = 0
            audio.play()
        } else if (!isLoadingAudio) loadAudio({ text: sentence.text })
    }
    const onPause = (e: any) => {
        e.stopPropagation() //  <------ Here is the magic
        setPlaying(false)
        audio.pause()
    }

    return (
        <div
            className="text-17 py-3 px-4 border-b border-gray-c5 flex items-center"
            onClick={() => selecting && toggleItem(sentence.id)}
        >
            <div
                className="flex-grow flex justify-between relative"
                style={{
                    height: !selecting ? height : undefined,
                    perspective: '250px',
                }}
                onClick={() => !selecting && toggleTranslation()}
            >
                <div>
                    <div
                        ref={textRef}
                        className="transition-all ease-in-out duration-500 cursor-pointer"
                        style={{
                            minHeight: !selecting ? height : undefined,
                            transform:
                                !selecting && showTranslation
                                    ? 'rotateX(180deg)'
                                    : undefined,
                            opacity:
                                !selecting && showTranslation ? 0 : undefined,
                        }}
                    >
                        {sentence.text}
                    </div>
                    {!selecting && (
                        <div
                            className="absolute top-0 left-0 right-0 transition-all duration-500 opacity-0 cursor-pointer"
                            ref={translationRef}
                            style={{
                                minHeight: height,
                                transform: !showTranslation
                                    ? 'rotateX(-180deg)'
                                    : undefined,
                                opacity: showTranslation ? 1 : undefined,
                            }}
                        >
                            {sentence.translation}
                        </div>
                    )}
                    {selecting && (
                        <div className="text-gray-6b mt-2">
                            {sentence.translation}
                        </div>
                    )}
                    {selecting && (
                        <CircleCheckbox
                            className="flex-shrink-0 ml-2"
                            checked={checkedIds[sentence.id]}
                        />
                    )}
                </div>
                <button
                    className="z-10"
                    disabled={isPlaying}
                    onClick={toggleSpeech}
                >
                    {!isPlaying ? (
                        <Play
                            className={`mr-1 ${
                                !loading ? 'text-gray-87' : 'text-gray-ef'
                            }`}
                            size={25}
                        />
                    ) : (
                        <Pause
                            onClick={onPause}
                            className={`mr-1 text-gray-87`}
                            size={25}
                        />
                    )}
                </button>
            </div>
        </div>
    )
}
