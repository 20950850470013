import React from 'react'
import Modal from 'Shared/Modal'
import cn from 'classnames'

type Props = {
    title: string
    content: string
    leftTitle: string
    leftClassName: string
    rightTitle: string
    rightClassName: string
    onDismiss?: () => void
    onLeftClick: () => void
    onRightClick: () => void
}

export default function TwoButtonAlert({
    title,
    content,
    leftTitle,
    leftClassName,
    rightTitle,
    rightClassName,
    onDismiss,
    onLeftClick,
    onRightClick,
}: Props) {
    return (
        <Modal onClose={onDismiss} className="text-center">
            <div className="flex flex-col">
                <span className="mt-4 mb-3 text-xl font-semibold px-5">
                    {title}
                </span>
                <span className="text-base font-normal mb-3 px-5">
                    {content}
                </span>
                <div className="w-full h-px bg-gray-bc" />
                <div className="flex flex-row justify-center my-4 space-x-7 items-center">
                    <span
                        className={cn('cursor-pointer', leftClassName)}
                        onClick={onLeftClick}
                    >
                        {leftTitle}
                    </span>
                    <span
                        className={cn('cursor-pointer', rightClassName)}
                        onClick={onRightClick}
                    >
                        {rightTitle}
                    </span>
                </div>
            </div>
        </Modal>
    )
}
