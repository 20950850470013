import React from 'react'
import { makeAutoObservable } from 'mobx'
import { NotebookSentence, Post, SharedPost } from 'Post/types'
import { getCurrentUser } from 'User/currentUser'
import dayjs from 'dayjs'
import { UploadingImage } from 'utils/imageUploadState'
import { UploadingVideo } from 'utils/videoUploadState'
import { UploadingAudio } from 'Post/Form/RecordAudio/State'
import { Voice } from 'Upload/api'
import { getTaggedEditorHTML } from 'utils/tags'
// import urlRegex from 'url-regex'
import normalizeUrl from 'normalize-url'

type Screen =
    | 'form'
    | 'selectTarget'
    | 'youtube'
    | 'sentence'
    | 'tag'
    | 'audio'
    | 'loopingAudio'
    | 'zoom'

export type LoopingAudioDraft = {
    url?: string
    text: string
    repetition: number
    voices: Voice[]
}

export const emptyLoopingAudioDraft: LoopingAudioDraft = {
    url: undefined,
    text: '',
    repetition: 1,
    voices: [],
}
const renderClasses = (post?: Partial<Post>) => {
    if (post?.classes) {
        return post?.classes
    }
    if (localStorage.getItem('selected_classes') !== null) {
        return JSON.parse(localStorage.getItem('selected_classes') || '')
    }
    return []
}
export const createFormState = ({ post }: { post?: Partial<Post> }) => {
    const values = {
        id: post?.id || 0,
        isUploading: true,
        isPublic: post?.isPublic || false,
        addedToSaved: post?.addedToSaved || false,
        classes: renderClasses(post),
        isMine: true,
        isClassOwner: post?.isClassOwner ? true : false,
        isClassAdmin: post?.isClassAdmin ? true : false,
        isFollowing: false,
        allow_correction: post?.allow_correction || 0,
        user: getCurrentUser(),
        date: dayjs(),
        schedule: post && post.id! < 0 ? post?.date?.toDate() : undefined,
        liked: post?.liked || false,
        joinedToClass: true,
        likesCount: post?.likesCount || 0,
        commentsCount: post?.commentsCount || 0,
        html: getTaggedEditorHTML(post),
        link: post?.link || '',
        preview_title: post?.preview_title || '',
        preview_image: post?.preview_image || '',
        notebookSentence: post?.notebookSentence,
        images: post?.images?.map((url) => ({ isNew: false, url })) || [],
        video: post?.video && { isNew: false, url: post.video },
        audio: post?.audio && { isNew: false, url: post.audio },
        youtubeId: post?.youtubeId,
        loopingAudio: post?.loopingAudio,
        loopingMissionCount: post?.loopingMissionCount || 0,
        recordingText: post?.recordingText || '',
        assignmentText: post?.assignmentText || '',
        zoomLink: post?.zoomLink,
        sharedPost: post?.sharedPost,
        sLectures: [],
        isVR: post?.isVR || false,
        shared_flow_id: post?.shared_flow_id,
        shared_flow_title: post?.shared_flow_title,
    } as Omit<Post, 'images' | 'video' | 'audio' | 'text' | 'tags'> & {
        html: string
        images: UploadingImage[]
        video?: UploadingVideo
        audio?: UploadingAudio
        schedule: Date | undefined
    }

    type Values = typeof values

    return makeAutoObservable({
        editorRef: { current: null } as { current: null | HTMLDivElement },
        currentScreen: 'form' as Screen,
        selectionRange: undefined as Range | undefined,
        loopingAudioDraft: emptyLoopingAudioDraft,
        setCurrentScreen(screen: Screen) {
            this.currentScreen = screen
        },
        backToForm() {
            this.currentScreen = 'form'
        },
        values,
        setSelectionRange(range: Range) {
            this.selectionRange = range
        },
        setHTML(html: string) {
            this.values.html = html
        },
        setSentence(sentence?: NotebookSentence) {
            this.values.notebookSentence = sentence
        },
        setImages(images: UploadingImage[]) {
            this.values.images = images
            this.values.isVR = false
        },
        setVideo(video: UploadingVideo | undefined) {
            this.values.video = video
        },
        setYouTubeId(id: string | undefined) {
            this.values.youtubeId = id
        },
        setAudio(audio?: UploadingAudio) {
            this.values.audio = audio
        },
        setMeta(link: string, preview_title: string, preview_image: string) {
            this.values.link = link
            this.values.preview_title = preview_title
            this.values.preview_image = preview_image
            // this.values.html = this.values.html.replace(link, '')
        },
        changeLoopingRepetition(repetition: number) {
            this.loopingAudioDraft.repetition = repetition
        },
        toggleLoopingAudioVoice(voice: Voice) {
            const { voices } = this.loopingAudioDraft
            const index = voices.indexOf(voice)
            if (index !== -1) voices.splice(index, 1)
            else voices.push(voice)
        },
        updateLoopingAudioDraft(draft: Partial<LoopingAudioDraft>) {
            Object.assign(this.loopingAudioDraft, draft)
        },
        saveLoopingAudio() {
            this.values.loopingAudio = this.loopingAudioDraft?.url
        },
        removeLoopingAudio() {
            this.loopingAudioDraft.url = this.values.loopingAudio = undefined
        },
        setZoomLink(zoomLink?: string) {
            this.values.zoomLink = zoomLink
        },
        setSharedPost(sharedPost?: SharedPost) {
            this.values.sharedPost = sharedPost
        },
        setIsPublic(value: boolean) {
            this.values.isPublic = value
        },
        setClasses(classes: { id: number; name: string; isOwn?: boolean }[]) {
            classes.forEach((c) => {
                if (c.isOwn) {
                    this.values.isClassOwner = true
                    this.values.isClassAdmin = true
                }
            })
            this.values.classes = classes
        },
        setLink(link?: string) {
            this.values.link = link
        },
        setSchedule(date?: Date) {
            this.values.schedule = date
        },
        onHTMLInput(e: React.FormEvent<HTMLDivElement>) {
            const { data } = (e.nativeEvent as unknown) as {
                data: string | null
            }
            if (data && !/\s/.test(data)) return

            this.onHTMLPaste(this.values.html)
        },
        onHTMLPaste(html: string) {
            // if (this.values.sharedPost || this.values.zoomLink) return
            //
            // const match = urlRegex({ strict: false }).exec(html)
            // if (!match) return
            //
            // const url = match[0]
            // this.setLink(normalizeUrl(match[0]))
            //
            // const value = this.values.html.replace(new RegExp(`${url}\\s?`), '')
            // this.values.html = value
            // ;(this.editorRef.current as HTMLElement).innerHTML = value
        },
        get canPost() {
            const values = this.values as Values

            return (
                values.html.trim().length > 0 ||
                values.images.length > 0 ||
                values.video ||
                values.youtubeId ||
                values.audio ||
                values.loopingAudio ||
                values.notebookSentence ||
                values.zoomLink ||
                values.sharedPost
            )
        },
    })
}

export type State = ReturnType<typeof createFormState>
