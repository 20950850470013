import React from 'react'
import { Post } from 'Post/types'
import { MissionUser } from 'Post/api'
import Completed from 'Post/Modal/MissionProgress/Completed'

export enum MissionType {
    lecture,
    looping,
}

export type Props = {
    missionType: MissionType
    post: Post
    users: MissionUser[]
}

export default function MissionProgress({
    missionType,
    post,
    users,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
    const [showCompleted, setShowCompleted] = React.useState(false)

    const onMissionClick = () => {
        setShowCompleted(true)
    }
    const closeCompleted = () => {
        setShowCompleted(false)
    }

    return (
        <div className="flex flex-col" onClick={onMissionClick}>
            <div className="bg-gray-dc h-px" />
            <div className="flex flex-col py-6 px-8">
                <span className="font-semibold text-lg">
                    {missionType == MissionType.lecture
                        ? 'LECTURE MISSION'
                        : `LOOPING AUDIO MISSION - ${post.loopingMissionCount} times`}
                </span>
                <span className="mt-1 font-normal text-base text-black">
                    {missionType == MissionType.lecture
                        ? 'Members who finish listening to the lecture are displayed below. Tap anywhere to see the details.'
                        : 'Members who finish repeating the looping audio as many times as directed are displayed below.'}
                </span>
                <div className="flex flex-row mt-4 space-x-2.5 w-full overflow-x-scroll flex-grow">
                    {users.map((user) => (
                        <img
                            key={user.id}
                            className="w-10 h-10 rounded-3xl"
                            alt={`${user.name} Profile Image`}
                            src={user.profileImage}
                        />
                    ))}
                </div>
            </div>
            {showCompleted && (
                <Completed
                    onClose={closeCompleted}
                    missionType={missionType}
                    users={users}
                />
            )}
        </div>
    )
}
