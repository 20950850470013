import React, { useContext } from 'react'
import { queryClient } from 'utils/queryClient'

export type LearningLanguage = 'English' | 'Korean'

export const learningLanguages: LearningLanguage[] = ['English', 'Korean']

function learningLanguageConverter(language: string | null): LearningLanguage {
    if (language === 'Korean') {
        return 'Korean'
    }
    return 'English'
}

export function getCurrentLearningLanguage(): LearningLanguage {
    const language = localStorage.getItem('LearningLanguage')
    return learningLanguageConverter(language)
}

export function setCurrentLearningLanguage(language: LearningLanguage) {
    localStorage.setItem('LearningLanguage', language)
    queryClient.clear()
}

// Context
export type LanguageState = {
    language: LearningLanguage
}

export type LanguageAction = {
    type: 'change'
    language: LearningLanguage
}

export type LanguageContextType = {
    state: LanguageState
    dispatch: React.Dispatch<LanguageAction>
}

const LearningLanguageContext = React.createContext<LanguageContextType>(
    // @ts-ignore
    undefined,
)

function learningLanguageReducer(
    state: LanguageState,
    action: LanguageAction,
): LanguageState {
    return {
        ...state,
        language: action.language,
    }
}

export function useSetLanguage() {
    const { dispatch } = useContext(LearningLanguageContext)
    return (language: LearningLanguage) => {
        setCurrentLearningLanguage(language)
        dispatch({
            type: 'change',
            language,
        })
    }
}

export function useLearningLanguage() {
    const { state } = useContext(LearningLanguageContext)
    return state.language
}

type LanguageObject<T> = {
    korean: T
    english: T
}
export function useSelectLanguage<T>({
    korean,
    english,
}: LanguageObject<T>): T {
    const { state } = useContext(LearningLanguageContext)
    switch (state.language) {
        case 'English':
            return english
        case 'Korean':
            return korean
    }
}

type Props = {
    children: React.ReactNode
}

export function LearningLanguageProvider({ children }: Props) {
    const [state, dispatch] = React.useReducer(learningLanguageReducer, {
        language: getCurrentLearningLanguage(),
    })
    const value = { state, dispatch }
    return (
        <LearningLanguageContext.Provider value={value}>
            {children}
        </LearningLanguageContext.Provider>
    )
}
