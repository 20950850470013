import { del, get, post } from 'utils/apiUtils'
import { Organization, OrganizationPostGroup } from 'Class/Management/types'
import { OrganizationMember } from './types'

export const organizations = get(() => ({
    path: '/admin/organization',
    response: (data: Organization[]) => data,
}))

export const createOrganization = post((name: string) => ({
    path: '/admin/organization',
    data: {
        name,
    },
    response: (data: Organization) => data,
}))

export const accessibleGroups = get((organizationId: number) => ({
    path: `/admin/organization/${organizationId}/group`,
    response: (data: OrganizationPostGroup[]) => data,
}))

export const allGroups = get(() => ({
    path: '/admin/group',
    response: (data: OrganizationPostGroup[]) => data,
}))

export const addAccess = post((organizationId: number, groupId: number) => ({
    path: `/admin/organization/${organizationId}/group/${groupId}`,
}))

export const removeAccess = del((organizationId: number, groupId: number) => ({
    path: `/admin/organization/${organizationId}/group/${groupId}`,
}))

type OrganizationMemberResponse = {
    user_id: number
    name: string
    profile_image_dir: string
}
function mapMember(u: OrganizationMemberResponse): OrganizationMember {
    return {
        id: u.user_id,
        name: u.name,
        profileImage: u.profile_image_dir,
    }
}
export const organizationMember = get((organizationId: number) => ({
    path: `/admin/organization/${organizationId}/user`,
    response: (data: OrganizationMemberResponse[]): OrganizationMember[] =>
        data.map(mapMember),
}))

export const organizationNonMember = get(
    (organizationId: number, searchKey: string, skip: number) => ({
        path: `/admin/organization/${organizationId}/nonmember`,
        params: {
            search_key: searchKey,
            skip,
            limit: 20,
        },
        response: (data: OrganizationMemberResponse[]) => data.map(mapMember),
    }),
)

export const addUserToOrganization = post(
    (organizationId: number, userId: number) => ({
        path: `/admin/organization/${organizationId}/member/${userId}`,
    }),
)

export const removeUserFromOrganization = del(
    (organizationId: number, userId: number) => ({
        path: `/admin/organization/${organizationId}/member/${userId}`,
    }),
)
