import React from 'react'
import { Candidate } from 'Class/Management/types'
import cn from 'classnames'
import TimeSelector from 'Class/Management/TimeSelector'
import { Dayjs } from 'dayjs'
import { schedulePost, updateSelectedTime } from 'Class/Management/cacheActions'
import TwoButtonAlert from 'Shared/Modal/TwoButtonAlert'
import Loader from 'Shared/Loader'
import api from 'api'
import Modal from 'Shared/Modal'
import { Post } from 'Post/types'
import { default as PostCard } from 'Post/Card'

type Props = {
    candidate: Candidate
    selectedClassId?: number
}

export default function RemainingRow({
    candidate,
    className,
    selectedClassId,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
    const [isShowingDatePicker, setShowingDatePicker] = React.useState(false)
    const [isShowingPublishAlert, setShowingPublishAlert] = React.useState(
        false,
    )
    const [
        isShowingPostCandidatePreview,
        setShowingPostCandidatePreview,
    ] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)

    const [post, setPost] = React.useState<Post>()

    const onPostTitleClick = React.useCallback(async () => {
        setShowingPostCandidatePreview(true)
        if (post) {
            return
        }
        const data = await api.post.findById({ id: candidate.shareId })
        setPost(data)
    }, [setShowingPostCandidatePreview, post, setPost])
    const onPostPreviewDismiss = React.useCallback(() => {
        setShowingPostCandidatePreview(false)
    }, [setShowingPostCandidatePreview])

    const onCancelClick = React.useCallback(() => {
        updateSelectedTime(candidate.id, undefined)
        setShowingDatePicker(false)
    }, [setShowingDatePicker])
    const onTimeSelect = (time: Dayjs) => {
        updateSelectedTime(candidate.id, time)
        setShowingDatePicker(false)
    }

    const onPublishClick = React.useCallback(() => {
        if (!candidate.dateToPost) {
            return
        }
        if (isLoading) {
            return
        }
        setShowingPublishAlert(true)
    }, [candidate.dateToPost, isLoading, setShowingPublishAlert])

    const onDismiss = React.useCallback(() => {
        setShowingPublishAlert(false)
    }, [setShowingPublishAlert])

    const onPublish = React.useCallback(async () => {
        if (isLoading) {
            return
        }
        if (!candidate.dateToPost) {
            return
        }
        if (!selectedClassId) {
            return
        }
        setIsLoading(true)
        setShowingPublishAlert(false)
        const scheduleId = await api.classManagement.schedulePost({
            candidateId: candidate.id,
            classId: selectedClassId,
            scheduledAt: candidate.dateToPost.toISOString(),
        })
        schedulePost(candidate.id, scheduleId)
    }, [
        isLoading,
        setIsLoading,
        setShowingPublishAlert,
        candidate,
        selectedClassId,
    ])

    return (
        <div
            className={cn(
                'h-10 flex-shrink-0 flex flex-row items-center px-5 text-base',
                className,
            )}
        >
            <span className="flex-1 cursor-pointer" onClick={onPostTitleClick}>
                {candidate.title}
            </span>
            <span
                className="cursor-pointer"
                onClick={() => setShowingDatePicker(true)}
            >
                {candidate.dateToPost
                    ? candidate.dateToPost.local().format('YYYY-MM-DD, hh:mm A')
                    : 'Date/Time'}
            </span>
            <button
                className={cn(
                    'h-6 border rounded-full px-2 flex items-center ml-3 bg-gray-fc border-gray-97 text-gray-6b text-base',
                    !candidate.dateToPost && 'opacity-25 cursor-default',
                )}
                onClick={onPublishClick}
            >
                {!isLoading && 'Publish'}
                {isLoading && (
                    <Loader
                        className="w-4 h-4 text-blue-primary mx-4"
                        color="#06ACEE"
                    />
                )}
            </button>
            {isShowingDatePicker && (
                <TimeSelector
                    onTimeSelect={onTimeSelect}
                    onCancel={onCancelClick}
                    initialDate={candidate.dateToPost}
                />
            )}
            {isShowingPublishAlert && (
                <TwoButtonAlert
                    title="Publish Post"
                    content="Are you sure to publish this post?"
                    leftTitle="Cancel"
                    leftClassName="text-lg text-gray-5b"
                    rightTitle="Publish"
                    rightClassName="font-semibold text-white bg-blue-primary rounded-full h-7 px-4 flex items-center"
                    onLeftClick={onDismiss}
                    onRightClick={onPublish}
                />
            )}
            {isShowingPostCandidatePreview && (
                <Modal size="large" onClose={onPostPreviewDismiss}>
                    {post && (
                        <PostCard
                            post={post}
                            disableComment={true}
                            disableSocial={true}
                            bypassMemberOnly={true}
                        />
                    )}
                    {!post && (
                        <div className="w-full flex flex-row items-center justify-center p-10">
                            <Loader
                                className="w-24 h-24 text-blue-primary mx-4"
                                color="#06ACEE"
                            />
                        </div>
                    )}
                </Modal>
            )}
        </div>
    )
}
