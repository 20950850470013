import { queryClient, updateData } from 'utils/queryClient'
import dayjs, { Dayjs } from 'dayjs'
import { CandidateMapped } from 'Class/Management/api'

export const updateSelectedTime = (id: number, date: Dayjs | undefined) => {
    updateData<CandidateMapped>(['organizationCandidates'], (data) => ({
        postedCandidates: data.postedCandidates,
        pendingCandidates: data.pendingCandidates,
        remainingCandidates: data.remainingCandidates.map((c) =>
            c.id === id
                ? {
                      ...c,
                      dateToPost: date,
                  }
                : c,
        ),
    }))
}

export const cancelPostSchedule = (id: number) => {
    queryClient.invalidateQueries(['organizationCandidates'])
}

export const schedulePost = (candidateId: number, scheduleId: number) => {
    updateData<CandidateMapped>(['organizationCandidates'], (data) => ({
        postedCandidates: data.postedCandidates,
        pendingCandidates: data.pendingCandidates
            .concat(
                data.remainingCandidates
                    .filter((c) => c.id === candidateId)
                    .map((c) => ({
                        id: scheduleId,
                        title: c.title,
                        candidateId: c.id,
                        scheduledAt: c.dateToPost!,
                    })),
            )
            .sort((c1, c2) => c2.scheduledAt.unix() - c1.scheduledAt.unix()),
        remainingCandidates: data.remainingCandidates.filter(
            (c) => c.id !== candidateId,
        ),
    }))
}
