import React, { useEffect, useState } from 'react'
import api from 'api'
import { useMutation } from 'react-query'
import { containsUrl, getFirstUrl } from 'utils/meta'
import Spin from 'assets/images/icons/Spin'
import { Cross } from '@styled-icons/entypo/Cross'

type Props = {
    html: string
    setMeta(link: string, preview_title: string, preview_image: string): void
    preview_image: string
    preview_title: string
    link: string
    setNewBannerValue(arg: string): void

    setValue(value: string): void
}

const BannerDynamic = ({
    html,
    setMeta,
    setNewBannerValue,
    preview_image,
    preview_title,
    link,
    setValue,
}: Props) => {
    const [isBanner, setIsBanner] = useState(
        preview_image && preview_title && link ? true : false,
    )
    const { mutate, isLoading } = useMutation(api.post.getMetaData, {
        onSuccess(data) {
            const content =
                new DOMParser().parseFromString(html, 'text/html').body
                    .textContent ?? html
            const url = getFirstUrl(content).trim()
            setMeta(data.url.trim(), data.title, data.image)
            setIsBanner(true)
            setValue(content.replace(url, ' '))
            setNewBannerValue(content.replace(url, ' '))
        },
        onError() {
            if (isBanner) setIsBanner(false)
            setMeta('', '', '')
        },
    })
    useEffect(() => {
        if (containsUrl(html) && !isBanner) {
            const content = new DOMParser().parseFromString(html, 'text/html')
            const url = getFirstUrl(content.body.textContent ?? html).trim()
            if (url.startsWith('http')) {
                mutate({ url: url })
            } else {
                mutate({ url: `http://${url}` })
            }
        }
    }, [html])

    const clearBanner = () => {
        setIsBanner(false)
        setMeta('', '', '')
    }
    if (isLoading) {
        return (
            <div className="flex-center mb-5">
                <Spin className="w-7 h-7 text-blue-primary animate-spin" />
            </div>
        )
    }
    if (preview_image && preview_title && link) {
        return (
            <div className="flex justify-start bg-gray-f7 text-white p-4">
                <div>
                    <img
                        className="w-16 h-16 mr-4 object-cover rounded"
                        src={preview_image}
                    />
                </div>
                <div className="flex flex-col flex-1 justify-start overflow-hidden">
                    <div className="text-black">{preview_title}</div>
                    <div className="text-gray-87 text-xs">
                        <a target="_blank" href={link}>
                            {link}
                        </a>
                    </div>
                </div>

                <button onClick={clearBanner}>
                    <Cross size={19} className="text-gray-87" />
                </button>
            </div>
        )
    } else {
        return <div></div>
    }
}

export default BannerDynamic
